import React from "react";
import { Tooltip } from "reactstrap";

const ToolTipData = (props) => {
  const { data, target = "" } = props;
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  return (
    <Tooltip
      isOpen={tooltipOpen}
      target={target}
      toggle={() => {
        setTooltipOpen(!tooltipOpen);
      }}
    >
      {data}
    </Tooltip>
  );
};

export default ToolTipData;
