/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  Col,
  Row,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { addServerStart, fetchServerStart } from "store/actions";
// import { postMtServer } from "apis/mtServers";

function AddMTServer(props) {
  const [addModal, setAddModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    adminAccount: "",
    type: "",
    platform: "",
    linkStatus: true,
    orderPermission: false,
    password: "",
    apiPassword: "",
    remarks: "",
  });

  const dispatch = useDispatch();
  const [sizePerPage, _setSizePerPage] = useState(10);
  const { t } = useTranslation();

  const toggleAddModal = () => setAddModal(!addModal);

  const handleSubmit = async (event, values) => {
    const trimmedFormData = Object.keys(formData).reduce((acc, key) => {
      acc[key] =
        typeof formData[key] === "string"
          ? formData[key].trim()
          : formData[key];
      return acc;
    }, {});

    console.log("Submitted data V1:", trimmedFormData);
    console.log("Submitted data:", {
      ...trimmedFormData,
      ...values,
    });
    dispatch(addServerStart({ payload: trimmedFormData }));
    dispatch(
      fetchServerStart({
        page: 1,
        limit: sizePerPage,
      })
    );
    toggleAddModal();
  };

  const handleSelectChange = (value, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value
    }));
  };

  return (
    <>
      <Button color="primary" onClick={toggleAddModal}>
        <i className="bx bx-plus me-1"></i> {t("Add New MT4/MT5 Server")}
      </Button>

      <Modal isOpen={addModal} toggle={toggleAddModal} centered>
        <ModalHeader toggle={toggleAddModal}>{t("Add New Server")}</ModalHeader>

        <ModalBody>
          <AvForm onValidSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    name="name"
                    label={t("Name")}
                    placeholder={t("Enter Server Name")}
                    type="text"
                    validate={{ required: { value: true } }}
                    errorMessage={t("Server Name is required")}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label>{t("Server Platform")}</Label>
                  <Select
                    isClearable={true}
                    options={[
                      {
                        label: "MT4",
                        value: "MT4",
                      },
                      {
                        label: "MT5",
                        value: "MT5",
                      },
                    ]}
                    onChange={(option) =>
                      handleSelectChange(option.value, "platform")
                    }
                    validate={{ required: { value: true } }}
                    errorMessage={t("Platform is required")}
                    placeholder={t("Choose A Platform")}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label>{t("Platform Agent")}</Label>
                  <Select
                    isClearable={true}
                    options={[
                      {
                        label: "Manager",
                        value: "Manager",
                      },
                      {
                        label: "Web",
                        value: "Web",
                      },
                    ]}
                    onChange={(option) =>
                      handleSelectChange(option.value, "agent")
                    }
                    validate={{ required: { value: true } }}
                    errorMessage={t("Agent is required")}
                    placeholder={t("Choose an Agent")}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    name="address"
                    label={t("IP Address")}
                    placeholder={t("Enter IP Address")}
                    type="text"
                    validate={{ required: { value: true } }}
                    errorMessage={t("IP Address is required")}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        address: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    name="port"
                    label={t("Port")}
                    placeholder={t("Enter Port")}
                    type="number"
                    validate={{ required: { value: true } }}
                    errorMessage={t("Port is required")}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        port: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    name="remarks"
                    label={t("Remarks")}
                    placeholder={t("Enter Remarks")}
                    type="text"
                    validate={{ required: { value: true } }}
                    errorMessage={t("Remarks is required")}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        remarks: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label>{t("Server Type")}</Label>
                  <Select
                    isClearable={true}
                    options={[
                      {
                        label: "Live",
                        value: "LIVE",
                      },
                      {
                        label: "Demo",
                        value: "DEMO",
                      },
                    ]}
                    onChange={(option) =>
                      handleSelectChange(option.value, "type")
                    }
                    validate={{ required: { value: true } }}
                    errorMessage={t("Server Type is required")}
                    placeholder={t("Choose A Type")}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    name="adminAccount"
                    label={t("Admin Account")}
                    placeholder={t("Enter Admin Account")}
                    type="number"
                    validate={{ required: { value: true } }}
                    errorMessage={t("Admin Account is required")}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        adminAccount: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    name="password"
                    label={t("Password")}
                    placeholder={t("Enter Password")}
                    type="password"
                    validate={{ required: { value: true } }}
                    errorMessage={t("Password is required")}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField
                    name="apiPassword"
                    label={t("API Password")}
                    placeholder={t("Enter API Password")}
                    type="password"
                    validate={{ required: { value: true } }}
                    errorMessage={t("API Password is required")}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        apiPassword: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label>{t("Link Status")}</Label>
                  <Select
                    isClearable={true}
                    options={[
                      {
                        label: "Enabled",
                        value: true,
                      },
                      {
                        label: "Disabled",
                        value: false,
                      },
                    ]}
                    onChange={(option) =>
                      handleSelectChange(option.value, "linkStatus")
                    }
                    validate={{ required: { value: true } }}
                    errorMessage={t("Link Status is required")}
                    placeholder={t("Choose Link Status")}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label>{t("Order Permission")}</Label>
                  <Select
                    isClearable={true}
                    options={[
                      {
                        label: "Enabled",
                        value: true,
                      },
                      {
                        label: "Disabled",
                        value: false,
                      },
                    ]}
                    onChange={(option) =>
                      handleSelectChange(option.value, "orderPermission")
                    }
                    validate={{ required: { value: true } }}
                    placeholder={t("Choose Order Permission")}
                  />
                </div>
              </Col>
            </Row>
            <div className="text-center mt-3">
              <Button type="submit" color="primary">
                {t("Add Server")}
              </Button>
              <Button
                type="button"
                color="secondary"
                onClick={toggleAddModal}
                className="ms-2"
              >
                {t("Cancel")}
              </Button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddMTServer;
