

import qs from "qs";
import * as axiosHelper from "./api_helper";

export const getRegions = async({ payload }) => {
  const data = await axiosHelper.get(`/regions?${qs.stringify(payload)}`);
  return data.result;
};

export const addRegion = async({ payload }) => {
  const data = await axiosHelper.post("/regions", payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const editRegion = async({ payload }) => {
  const { id, values } = payload;
  const data = await axiosHelper.patch(`/regions/${id}`, values);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const deleteRegion = async({ payload }) => {
  const data = await axiosHelper.del(`/regions/${payload}`);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const changeStatusRegion = async({ payload }) => {
  const data = await axiosHelper.post(`/regions/${payload.id}/${payload.status}`);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};