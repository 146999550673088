import { useDispatch, connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addNewItem } from "store/dictionary/actions";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect";
import SearchableSymbolGroupsDropdown from "./SearchableComponents/SearchableSymbolGroupsDropdown";
import { fetchSymbols } from "store/rebateSettings/symbols/actions";
import { addGroupRequest, fetchGroups } from "store/rebateSettings/groups/actions";
import { platform } from "os";


function SymbolsAdd(props) {
  const [addModal, setAddModal] = useState(false);
  const [symboloptions, setSymboloptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [emptySymbolOptionsError, setEmptySymbolOptionsError] = useState(false);
  const [selectedSymbols, setSelectedSymbols] = useState([]);

  const emptySymbolOptionsMessage = "Please select at least one symbol";
  const dispatch = useDispatch();
  const toggleAddModal = () => {
    setIsLoading(false);
    setSelectedSymbols([]);
    setAddModal((preValue) => !preValue);
  };

  const { create } = props.dictionariesPermissions;

  useEffect(() => {
    if (!props.showAddSuccessMessage && addModal) {
      setAddModal(false);
    }
  }, [props.showAddSuccessMessage]);

  useEffect(() => {
    dispatch(
      fetchSymbols({
        limit: 100000,
        page: 1,
        platform: props?.type,
      })
    );
  }, [props.type, props.addSuccess]);

  useEffect(() => {
    if (props?.symbols) {
      const symbolOptions = props?.symbols?.filter((symbol) => !symbol.groupId)?.map((symbol) => {
        return {
          value: symbol?._id,
          label: symbol?.symbolName,
        };
      });
      setSymboloptions(symbolOptions);
    }
  }, [props.symbols]);

  useEffect(() => {
    if (!props.addSuccess && addModal) {
      //setAddModal(false);
      // dispatch(
      //   fetchGroups({
      //     limit: 10,
      //     page: 1,
      //     type: props.type,
      //   })
      // );
    }
  }, [props.addSuccess]);

  useEffect(() => {
    if (props.addSuccess && addModal) {
      setAddModal(false);
      dispatch(
        fetchGroups({
          limit: 10,
          page: 1,
          type: props.type,
        })
      );
    }
  }, [props.addSuccess]);
  return (
    <React.Fragment>
      <Link to="#" className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}>
        <i className="bx bx-plus me-1"></i>
        {props.t("Add New Group")}
      </Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add New Group")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              setIsLoading(true);
              e.preventDefault();
              if (selectedSymbols.length === 0) {
                setEmptySymbolOptionsError(true);
                setIsLoading(false);
                setTimeout(() => {
                  setEmptySymbolOptionsError(false);
                }, 2000);
                return;
              }
              v.groupName = v.groupName.trim();
              v.symbols = selectedSymbols;
              //console.log("add Symbols Values: ", v);
              dispatch(addGroupRequest({
                ...v,
                type: props.type
              }));
              setIsLoading(false);
            }}
          >
            <div className="mb-3">
              <AvField
                name="groupName"
                label={props.t("Group Name")}
                placeholder={props.t("Enter Group Name")}
                type="text"
                errorMessage={props.t("Enter valid Group Name")}
                validate={{ required: { value: true } }}
              />
            </div>

            <div className="my-3">
              <Label>Symbol Groups</Label>
              <SearchableSymbolGroupsDropdown
                name="symbolGroups"
                isRequired={true}
                symboloptions={symboloptions}
                isMulti={true}
                value={selectedSymbols}
                onChange={(selected) => {
                  console.log("Selected symbols:", selected);
                  setSelectedSymbols(selected);
                }}
                platform={props?.type}
                errorMessage={props.t("Please select at least one symbol group")}
              />
            </div>
            {emptySymbolOptionsError && (
              <div className="text-danger mb-2">
                {emptySymbolOptionsMessage}
              </div>
            )}
            <div className="text-center pt-3 p-2">
              <Button disabled={isLoading} type="submit" color="primary" className="">
                {isLoading ? "Loading..." : props.t("Add")}
              </Button>
            </div>
          </AvForm>
          {props.error && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {props.editError}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  id: state.groupReducer.id,
  error: state.groupReducer.error,
  showAddSuccessMessage: state.groupReducer.showAddSuccessMessage,
  dictionariesPermissions: state.Profile.dictionariesPermissions || {},
  disableAddButton: state.groupReducer.disableAddButton,
  addSuccess: state.groupReducer.addSuccess,
  symbols: state.symbolReducer.symbols || [],
});

export default connect(mapStateToProps, null)(withTranslation()(SymbolsAdd));