export const FETCH_IB_REQUESTS_START = "FETCH_IB_REQUESTS_START";
export const FETCH_IB_REQUESTS_SUCCESS = "FETCH_IB_REQUESTS_SUCCESS";
export const FETCH_IB_REQUESTS_ERROR = "FETCH_IB_REQUESTS_ERROR";

export const FETCH_OPEN_LINK_REQUESTS_START = "FETCH_OPEN_LINK_REQUESTS_START";
export const FETCH_OPEN_LINK_REQUESTS_SUCCESS = "FETCH_OPEN_LINK_REQUESTS_SUCCESS";
export const FETCH_OPEN_LINK_REQUESTS_ERROR = "FETCH_OPEN_LINK_REQUESTS_ERROR";

export const IB_REQUEST_APPROVE_START = "IB_REQUEST_APPROVE_START";
export const IB_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS =
  "IB_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS";
export const IB_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR =
  "IB_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR";

export const ADD_OPEN_PROMOTION_LINK_START = "ADD_OPEN_PROMOTION_LINK_START";
export const ADD_OPEN_PROMOTION_LINK_SUCCESS = "ADD_OPEN_PROMOTION_LINK_SUCCESS";
export const ADD_OPEN_PROMOTION_LINK_ERROR = "ADD_OPEN_PROMOTION_LINK_ERROR";

export const IB_REQUEST_REJECT_START = "IB_REQUEST_REJECT_START";
export const IB_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS =
  "IB_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS";
export const IB_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR =
  "IB_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR";

export const SWAP_REQUEST_APPROVE_START = "SWAP_REQUEST_APPROVE_START";
export const SWAP_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS = "SWAP_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS";
export const SWAP_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR = "SWAP_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR";  

export const SWAP_REQUEST_REJECT_START = "SWAP_REQUEST_REJECT_START";
export const SWAP_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS =
  "SWAP_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS";
export const SWAP_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR =
  "SWAP_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR";

//leverage requests
export const FETCH_LEVERAGE_REQUESTS_START = "FETCH_LEVERAGE_REQUESTS_START";
export const FETCH_LEVERAGE_REQUESTS_SUCCESS =
  "FETCH_LEVERAGE_REQUESTS_SUCCESS";
export const FETCH_LEVERAGE_REQUESTS_ERROR = "FETCH_LEVERAGE_REQUESTS_ERROR";

export const FETCH_SWAP_REQUESTS_START = "FETCH_SWAP_REQUESTS_START";
export const FETCH_SWAP_REQUESTS_SUCCESS = "FETCH_SWAP_REQUESTS_SUCCESS";
export const FETCH_SWAP_REQUESTS_ERROR = "FETCH_SWAP_REQUESTS_ERROR";

export const LEVERAGE_REQUEST_APPROVE_START = "LEVERAGE_REQUEST_APPROVE_START";
export const LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS =
  "LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS";
export const LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR =
  "LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR";

export const LEVERAGE_REQUEST_REJECT_START = "LEVERAGE_REQUEST_REJECT_START";
export const LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS =
  "LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS";
export const LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR =
  "LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR";

// Requests for new Account
export const FETCH_ACCOUNT_REQUESTS_START = "FETCH_ACCOUNT_REQUESTS_START";
export const FETCH_ACCOUNT_REQUESTS_SUCCESS = "FETCH_ACCOUNT_REQUESTS_SUCCESS";
export const FETCH_ACCOUNT_REQUESTS_ERROR = "FETCH_ACCOUNT_REQUESTS_ERROR";

export const ACCOUNT_REQUEST_APPROVE_START = "ACCOUNT_REQUEST_APPROVE_START";
export const ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS =
  "ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS";
export const ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR =
  "ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR";

export const ACCOUNT_REQUEST_REJECT_START = "ACCOUNT_REQUEST_REJECT_START";
export const ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS =
  "ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS";
export const ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR =
  "ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR";

// Requests for master Account
export const FETCH_MASTER_ACCOUNT_REQUESTS_START =
  "FETCH_MASTER_ACCOUNT_REQUESTS_START";
export const FETCH_MASTER_ACCOUNT_REQUESTS_SUCCESS =
  "FETCH_MASTER_ACCOUNT_REQUESTS_SUCCESS";
export const FETCH_MASTER_ACCOUNT_REQUESTS_ERROR =
  "FETCH_MASTER_ACCOUNT_REQUESTS_ERROR";

export const MASTER_ACCOUNT_REQUEST_APPROVE_START =
  "MASTER_ACCOUNT_REQUEST_APPROVE_START";
export const MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS =
  "MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS";
export const MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR =
  "MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR";

export const MASTER_ACCOUNT_REQUEST_REJECT_START =
  "MASTER_ACCOUNT_REQUEST_REJECT_START";
export const MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS =
  "MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS";
export const MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR =
  "MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR";

// Requests for investor Account
export const FETCH_INVESTOR_ACCOUNT_REQUESTS_START =
  "FETCH_INVESTOR_ACCOUNT_REQUESTS_START";
export const FETCH_INVESTOR_ACCOUNT_REQUESTS_SUCCESS =
  "FETCH_INVESTOR_ACCOUNT_REQUESTS_SUCCESS";
export const FETCH_INVESTOR_ACCOUNT_REQUESTS_ERROR =
  "FETCH_INVESTOR_ACCOUNT_REQUESTS_ERROR";

export const INVESTOR_ACCOUNT_REQUEST_APPROVE_START =
  "INVESTOR_ACCOUNT_REQUEST_APPROVE_START";
export const INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS =
  "INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS";
export const INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR =
  "INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR";

export const INVESTOR_ACCOUNT_REQUEST_REJECT_START =
  "INVESTOR_ACCOUNT_REQUEST_REJECT_START";
export const INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS =
  "INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS";
export const INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR =
  "INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR";

export const CLEAN_UP_REQUESTS = "CLEAN_UP_REQUESTS";
