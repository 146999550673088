import React, { useState, useEffect } from "react";
import { 
  useDispatch, connect, useSelector 
} from "react-redux";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { editUser } from "store/users/actions";
import Loader from "components/Common/Loader";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { fetchRegions } from "store/regions/actions";
import { fetchTeams } from "store/teams/actions";

function UsersEditModal(props) {
  const { open, user = {}, onClose, usersRoles } = props;
  const [selectedRegion, setSelectedRegion] = useState("");
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [duplicatedEmail, setDuplicatedEmail] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { _id, title } = user.roleId || "";
  const dispatch = useDispatch();
  const handleEditUser = (e, values) => {
    const submittedTeam = teams.find((team) => team._id === values.memberTeamId);
    if (!submittedTeam) {
      delete values.memberTeamId;
    }
    if (submittedTeam && submittedTeam?.regionId !== values?.regionId) {
      setErrorMessage("Please Reselect Team According to region");
    } else {
      dispatch(
        editUser({
          id: user._id,
          values,
        })
      );
      setErrorMessage("");

    }
  };
  const regions = useSelector((state) => state.regionReducer.docs);
  const teams = useSelector((state) => state.teamsReducer.docs);
  const toggleEditModal = () => {
    onClose();
    setDuplicatedEmail(false);
    setErrorMessage("");
  };

  const emailErrorStyle = duplicatedEmail
    ? "1px solid red"
    : "1px solid rgb(200, 200, 200)";

  const repeatedEmailCheck = (e) => {
    e.target?.value?.length > 0 &&
      setDuplicatedEmail(
        props.allUsersEmails?.includes(e.target.value?.trim())
      );
  };
  const loadRegions = (page, limit) => {
    dispatch(fetchRegions({
      page,
      limit,
    }));
  };
  useEffect(() => {
    if (props.editClearingCounter > 0 && open) {
      onClose();
      setDuplicatedEmail(false);
    }
  }, [props.editSuccess]);
  const loadTeams = (page, limit) => {
    dispatch(
      fetchTeams({
        page,
        limit
      })
    );
  };
  useEffect(()=>{
    loadTeams(1, 2000);
    loadRegions(1, 2000);
  }, []);

  useEffect(() => {
    if (teams) {
      setFilteredTeams(teams.filter((team) => selectedRegion ? String(team?.regionId) === String(selectedRegion) : String(team?.regionId) === String(user?.regionId?._id)));
    }
  }, [selectedRegion, teams, open]);

  return (
    <React.Fragment>
      {/* <Link to="#" className="btn btn-light" onClick={onClose}><i className="bx bx-plus me-1"></i> Add New</Link> */}
      <Modal isOpen={open} toggle={toggleEditModal} centered={true}>
        <ModalHeader toggle={toggleEditModal} tag="h4">
          Edit User
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              handleEditUser(e, v);
            }}
          >
            <div className="mb-3">
              <AvField
                name="firstName"
                label="Frist Name  "
                placeholder="Enter First Name"
                type="text"
                errorMessage="Enter Frist Name"
                value={user.firstName}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="lastName"
                label="Last Name  "
                placeholder="Enter Last Name"
                type="text"
                errorMessage="Enter Last Name"
                value={user.lastName}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="email"
                label="Email"
                placeholder="Enter Email"
                type="email"
                errorMessage="Enter Valid Email"
                onChange={repeatedEmailCheck}
                validate={{
                  required: { value: true },
                  email: { value: true },
                }}
                value={user.email}
                style={{
                  border: `${emailErrorStyle}`,
                }}
              />
              {duplicatedEmail && (
                <span className="text-danger">Account already exists</span>
              )}
            </div>
            <div className="mb-3">
              <label>Select Role </label>
              <AvField type="select" name="roleId" value={_id}>
                <option value={_id}>{title}</option>
                {usersRoles?.map((row) => {
                  // console.log("edit modal");
                  return (
                    <option key={row._id} value={row._id}>
                      {row.title}
                    </option>
                  );
                })}
              </AvField>
            </div>
          
            <AvFieldSelect
              name="regionId"
              label="Region"
              placeholder="Select Region"
              options={regions?.map((r) => ({
                value: r._id,
                label: r.title,
              }))}
              value={selectedRegion || user?.regionId?._id}
              onChange={(e) => setSelectedRegion(e)}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Region is required",
                },
              }}
            />

            <AvFieldSelect
              name="memberTeamId"
              label="Team"
              placeholder="Select team"
              options={filteredTeams?.map((team) => ({
                value: team?.id,
                label: team?.title,
              }))}
              value={selectedTeam || user?.memberTeamId?._id}
              onChange={(e) => setSelectedTeam(e)}
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Team is required",
              //   },
              // }}
            />
            <div className="text-center">
              {props.editLoading ? (
                <Loader />
              ) : (
                <Button
                  type="submit"
                  color="primary"
                  className=""
                  disabled={props?.editLoading}
                >
                  Edit
                </Button>
              )}
            </div>
          </AvForm>
          {props.editError && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {props.editError}
            </UncontrolledAlert>
          )}
          {errorMessage && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {errorMessage}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  editLoading: state.usersReducer.editLoading,
  addLoading: state.usersReducer.addLoading,
  editResult: state.usersReducer.editResult,
  editError: state.usersReducer.editError,
  editSuccess: state.usersReducer.editSuccess,
  editClearingCounter: state.usersReducer.editClearingCounter,
});
export default connect(mapStateToProps, null)(UsersEditModal);
