import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_ENCRYPT_DATA_SECRET_KEY;

export const encryptData = (data) => {
  data = JSON.stringify(data);
  const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
  return btoa(ciphertext);
};

// export const decryptData = (data) => {
//   const decodedCiphertext = atob(data);
//   const bytes = CryptoJS.AES.decrypt(decodedCiphertext, secretKey);
//   const finalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//   return finalData;
// };
export const decryptData = (data) => {
  if (!data || typeof data !== "string") {
    throw new Error("Invalid input: data must be a non-empty string");
  }

  try {
    const isValidBase64 = /^[A-Za-z0-9+/=]+$/.test(data);
    if (!isValidBase64) {
      throw new Error("Invalid Base64 string");
    }

    const decodedCiphertext = atob(data); // Decode Base64
    const bytes = CryptoJS.AES.decrypt(decodedCiphertext, secretKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

    if (!decryptedText) {
      throw new Error("Decryption failed: Result is empty");
    }

    return decryptedText;
  } catch (error) {
    console.error("Decryption error:", error.message);
    throw error;
  }
};
// Example usage
// const dataToEncrypt = { username: "JohnDoe", password: "12345" };
// const encryptedData = encryptData(dataToEncrypt);
// const decryptedData = decryptData(encryptedData);
// console.log("Encrypted Data:", encryptedData);