import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, ModalHeader, ModalBody, Button, Alert 
} from "reactstrap";
import { fetchConversionRates } from "store/actions";
import AvFieldSelect from "./AvFieldSelect";
import { CONFIRMATION_EMAILS } from "common/data/dropdowns";
import { fetchClientTransactions } from "store/transactions/action";

// utility functions 
const getCurrentTransactionType = (data) => {
  if (data?.tradingAccountId?._id){
    return "FOREX";
  }
  return "WALLET";
};

const getAmountAfterDeduction = (amount, fees) => {
  return amount - (fees || 0);
};

const getFinalRemittanceAmount = (
  transactionType,
  netAmount,
  conversionRate
) => {
  if (!netAmount) return 0;
  let finalAmount = netAmount;
  if (transactionType === "FOREX" && conversionRate) {
    finalAmount = (parseFloat(netAmount) * parseFloat(conversionRate)).toFixed(
      2
    );
  }
  return parseFloat(finalAmount); // Convert back to number after toFixed
};

const getTransactionCurrency = (fundingStep, data, toCurrency) => {
  const transactionType = getCurrentTransactionType(data);
  if (transactionType === "FOREX"){
    return fundingStep === "withdrawalRemittance" ? data?.convertedCurrency : toCurrency;
  }
  return data?.currency;
};


const getNumericValue = (amount) => {
  if (!amount) return 0;
  if (typeof amount === "object" && "$numberDecimal" in amount) {
    return Number(amount.$numberDecimal);
  }
  return Number(amount);
};

const getTransactionFees = (data) => {
  console.log("data", data);
  if (data?.tradingAccountId?._id){
    return data?.fee;
  }
  return data?.mFee?.cost;
};

function TransactionForexModal({
  show,
  toggle,
  onSubmit,
  showAmount,
  title,
  data,
  isAmountDisabled,
  submitButton,
  //step,
  ...props
}) {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [fees, setFees] = useState(0);
  const [toCurrency, setToCurrency] = useState("");
  const [confirmationEmail, setConfirmationEmail] = useState("");
  const [amountError, setAmountError] = useState("");
  const [currencyConvertedAmount, setCurrencyConvertedAmount] = useState(0);
  const [conversionRateValue, setConversionRateValue] = useState(1);
  const fundingStepData = props.fundingStep ?? props?.step;
  const { loading, conversionRates, pagination, clearingCounter } = useSelector(
    (state) => state.conversionRatesReducer
  );


  const transactionType = getCurrentTransactionType(data);
  
  console.log("data", data);
  const renderedValue = (transactionType, data) => {
    console.log("data", data);
    if (transactionType === "WALLET"){
      if (fundingStepData === "withdrawalRemittance"){
        return getNumericValue(data?.paid);
      }
      return convertedAmount;
    } else if (transactionType === "FOREX"){
      if (fundingStepData === "withdrawalRemittance"){
        return getNumericValue(data?.currencyConvertedAmount);
      }
      return currencyConvertedAmount;
    }
  };
 
  useEffect(() => {
    // Calculate net amount after fees
    const netAmount = getAmountAfterDeduction(amount, fees);

    // Update converted amount (amount after fees)
    setConvertedAmount(netAmount);

    // Calculate final amount with conversion if applicable
    const finalAmount = getFinalRemittanceAmount(
      transactionType,
      netAmount,
      conversionRateValue
    );

    setCurrencyConvertedAmount(finalAmount);
  }, [amount, fees, conversionRateValue, transactionType]);

  useEffect(() => {
    loadConversionRates(1, 100000);
  }, [clearingCounter]);

  const loadConversionRates = (page, limit) => {
    dispatch(
      fetchConversionRates({
        page,
        limit,
      })
    );
  };


  // check if amount is valid
  const validateAmount = (amount, fees) => {
    if (fees > amount) {
      setAmountError("Fees cannot be greater than the amount");
    } else {
      setAmountError("");
    }
  };

  useEffect(() => {
   
    const numericAmount = getNumericValue(data?.amount);
    setAmount(numericAmount);
    setConvertedAmount(numericAmount);  
    
  }, [data]);

  useEffect(() => {
    if (toCurrency && conversionRates?.length) {
      const conversionRate =
         conversionRates.find(
           (c) => c.baseCurrency === "USD" && c.targetCurrency === toCurrency
         )?.value || 1;
      setConversionRateValue(conversionRate);
    }
  }, [conversionRates, toCurrency]);

  useEffect(() => {
    const finalAmount = getFinalRemittanceAmount(
      getCurrentTransactionType(data),
      getAmountAfterDeduction(amount, fees),
      conversionRateValue
    );
    setCurrencyConvertedAmount(parseFloat(finalAmount));
    console.log("CALLED GET FINAL AMOUNT", conversionRateValue);
  }, [fees, amount, conversionRateValue]);

  useEffect(() => {
    validateAmount(amount, fees);
  }, [amount, fees]);
  
  //console.log("fundingStep", fundingStepData);
  return (
    <>
      <Modal isOpen={show} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {props.t(title)}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              data.amount = v.amount ? parseFloat(v.amount) : data.amount;
              data.fee =
                fees ||
                getNumericValue(
                  data?.amount?.$numberDecimal - data?.paid?.$numberDecimal
                );
              data.convertedCurrency = getTransactionCurrency(
                fundingStepData,
                data,
                toCurrency
              );
              data.convertedAmount = parseFloat(convertedAmount);
              data.paid = parseFloat(convertedAmount);
              data.note = v.note;
              data.toCurrency = toCurrency;
              data.currencyConvertedAmount = currencyConvertedAmount;
              // Save form data when in fundingDepartmentDeduction step
              if (fundingStepData === "fundingDepartmentDeduction") {
                data.previousStepData = {
                  deductedAmount: data.amount,
                  fee: data.fee,
                  convertedCurrency: data.convertedCurrency,
                  convertedAmount: data.convertedAmount,
                  paid: data.convertedAmount,
                };
              }

              if (fundingStepData === "fundingDepartmentInitial") {
                data.confirmationEmail = confirmationEmail;
              }
              console.log("data", data);
              onSubmit(data);
            }}
          >
            {showAmount && (
              <>
                <AvField
                  name="amount"
                  label={props.t("Initial Amount")}
                  type="number"
                  disabled
                  defaultValue={getNumericValue(data.amount)}
                />
                <AvField
                  name="amount"
                  label={props.t("Amount Without Fees")}
                  placeholder={props.t("Enter Amount")}
                  type="number"
                  errorMessage={props.t("Enter Valid Amount")}
                  onChange={(e) => {
                    setAmount(parseFloat(e.target.value));
                  }}
                  disabled={isAmountDisabled}
                  validate={{
                    required: { value: true },
                  }}
                  defaultValue={data?.amount?.$numberDecimal ?? data?.amount}
                />
                {fundingStepData === "withdrawalRemittance" && (
                  <AvField
                    name="fees"
                    label={props.t("Handing Fees")}
                    placeholder={props.t("Enter Fees")}
                    type="number"
                    errorMessage={props.t("Enter Valid Fees")}
                    // onChange={(e) => {
                    //   setFees(parseFloat(e.target.value));
                    //   //validateAmount(amount, fees);
                    // }}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (value >= 0) {
                        setFees(value);
                      } else {
                        // Optionally reset to 0 or show a message
                        setFees(0);
                        console.warn("Fees must be greater than or equal to 0");
                      }
                    }}
                    validate={{
                      required: { value: true },
                      min: {
                        value: 0,
                        errorMessage: "Fees Must Be Greater Or Equal 0",
                      },
                    }}
                    disabled={fundingStepData === "withdrawalRemittance"}
                    value={getNumericValue(data?.mFee?.cost) || getNumericValue(data?.fee) || "0"}
                    defaultValue={0}
                  />
                )}
                {fundingStepData !== "withdrawalRemittance" && (
                  <AvField
                    name="fees"
                    label={props.t("Handing Fees")}
                    placeholder={props.t("Enter Fees")}
                    type="number"
                    errorMessage={props.t("Enter Valid Fees")}
                    // onChange={(e) => {
                    //   setFees(parseFloat(e.target.value));
                    // //validateAmount(amount, fees);
                    // }}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (value >= 0) {
                        setFees(value);
                      } else {
                        // Optionally reset to 0 or show a message
                        setFees(0);
                        console.warn("Fees must be greater than or equal to 0");
                      }
                    }}
                    validate={{
                      required: { value: true },
                      min: {
                        value: 0,
                        errorMessage: "Fees Must Be Greater Or Equal 0",
                      },
                    }}
                    //disabled={fundingStepData === "withdrawalRemittance"}
                    value={fees}
                    defaultValue={0}
                  />
                )}
                {amountError && <Alert color="danger">{amountError}</Alert>}
                {fundingStepData !== "withdrawalRemittance" &&
                  transactionType === "FOREX" && (
                  <AvFieldSelect
                    name="toCurrency"
                    label="Exchange Rate"
                    placeholder="Select To Exchange Rate"
                    options={conversionRates
                      ?.filter(
                        (rateItem) =>
                          rateItem?.baseCurrency ===
                            (data?.content?.coin || "USD")
                      )
                      ?.map((rate) => ({
                        value: rate?.targetCurrency,
                        label: rate?.targetCurrency,
                      }))}
                    value={toCurrency}
                    onChange={(e) => setToCurrency(e)}
                    // validate={{
                    //   required: {
                    //     value: true,
                    //     errorMessage: "Exchange Rate is required"
                    //   }
                    // }}
                  />
                )}
                {fundingStepData === "withdrawalRemittance" &&
                  transactionType === "FOREX" && (
                  <AvField
                    name="toCurrency"
                    label="Exchange Rate"
                    type="text"
                    disabled
                    value={data?.convertedCurrency}
                    defaultValue={data?.convertedCurrency}
                  />
                )}
                <>
                  <Alert color="warning">
                    The Remittance Amount Is :{" "}
                    {renderedValue(transactionType, data)}{" "}
                    {getTransactionCurrency(fundingStepData, data, toCurrency)}
                  </Alert>
                </>
              </>
            )}
            <AvField
              name="note"
              label={props.t("Note")}
              placeholder={props.t("Enter Note")}
              type="text"
              errorMessage={props.t("Enter Valid Note")}
              validate={{
                required: { value: true },
              }}
              defaultValue={data.note}
            />
            {fundingStepData === "fundingDepartmentInitial" && (
              <AvFieldSelect
                name="confirmationEmail"
                label="Confirmation Email"
                placeholder="Select Confirmation Email"
                options={CONFIRMATION_EMAILS.map((email) => ({
                  value: email.value,
                  label: email.name,
                }))}
                value={confirmationEmail}
                onChange={(e) => setConfirmationEmail(e)}
              />
            )}
            <div className="text-center">
              <Button type="submit" color="primary" className="mx-2">
                {submitButton}
              </Button>
              <Button color="primary" className="mx-2" onClick={toggle}>
                Cancel
              </Button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

export default TransactionForexModal;
