import * as axiosHelper from "./api_helper";
import qs from "qs";

const FundingEndpoints = {
  fundingDepartmentInitial: "funding-initial-step",
  riskDepartmentReview: "risk-review-step",
  fundingDepartmentDeduction: "funding-deduction-step",
  withdrawalRemittance: "withdrawal-remittance-step",
};

export const getWithdrawals = async ({ payload })=>{
  const withdrawals = await axiosHelper.get(`/transactions/withdraw?${qs.stringify(payload)}`);
  return withdrawals;
};
export const getUserTransactions = async ({ payload })=>{
  const transactions = await axiosHelper.get(`/transactions/withdraw/user-transactions?${qs.stringify(payload)}`);
  return transactions;
};
export const addWithdrawal = async (values)=>{
  const endpoint = values?.walletType === "users" ? "/user-withdrawals" : "" ; 
  const result = await axiosHelper.post(`/transactions/withdraw${endpoint}`, values);

  if (result.isError){
    throw new Error(result.message || "Withdrawal has failed");
  }
  return result;
};
// export const approveWithdrawal = async (payload)=>{
//   const endpoint = payload?.walletType === "users" ? "-user-withdrawals" : "" ; 
//   const result = await axiosHelper.patch(`/transactions/withdraw/${payload.id}/approve${endpoint}`, { ...payload });
//   if (result.isError){
//     throw new Error(result.message);
//   }
  
//   return result;
// };
// export const rejectWithdrawal = async (payload)=>{
//   const endpoint = payload?.walletType === "users" ? "-user-withdrawals" : "" ; 
//   const result = await axiosHelper.patch(`/transactions/withdraw/${payload.id}/reject${endpoint}`, { ...payload });
//   if (result.isError){
//     throw new Error(result.message);
//   }
  
//   return result;
// };


export const approveWithdrawal = async (payload) => {
  const endpoint = payload?.walletType === "users" ? "-user-withdrawals" : "" ; 
  const result = await axiosHelper.patch(`/transactions/withdraw/${payload.id}/approve${endpoint}-${FundingEndpoints?.[payload?.fundingStep]}`, { ...payload });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const rejectWithdrawal = async (payload)=>{
  const endpoint = payload?.walletType === "users" ? "-user-withdrawals" : "" ; 
  const result = await axiosHelper.patch(`/transactions/withdraw/${payload.id}/reject${endpoint}-${FundingEndpoints?.[payload?.fundingStep]}`, { ...payload });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const onHoldWithdrawal = async (payload)=>{
  const endpoint = payload?.walletType === "users" ? "-user-withdrawals" : "" ; 
  const result = await axiosHelper.patch(`/transactions/withdraw/${payload.id}/on-hold${endpoint}-${FundingEndpoints?.[payload?.fundingStep]}`, { ...payload });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const returnWithdrawal = async (payload)=>{
  const endpoint = payload?.walletType === "users" ? "-user-withdrawals" : "" ; 
  const result = await axiosHelper.patch(`/transactions/withdraw/${payload.id}/return${endpoint}-withdrawal-remittance-step`, { ...payload });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const getClientWithdrawals = async ({ payload }) => {
  const { clientId, type, page, limit } = payload;
  const withdrawals = await axiosHelper.get(`/transactions/withdraw?customerId=${clientId}&type=${type}&page=${page}&limit=${limit}`);
  const data = {
    withdrawals: withdrawals
  };
  if (withdrawals.isError){
    throw new Error(data.isError);
  }

  return data;
};

export const updateUserWithdrawal = async (values)=>{
  const result = await axiosHelper.patch(`/transactions/withdraw/${values.id}/user-withdrawals`, { ...values });
  if (result.isError){
    throw new Error(result.message);
  }
  return result;
};