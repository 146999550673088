import React, { useEffect, useState } from "react";
import {
  useDispatch, useSelector
} from "react-redux";

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, Input, Label, Spinner,
} from "reactstrap";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import TableLoader from "components/Common/TableLoader";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import { MetaTags } from "react-meta-tags";
import formatDate from "helpers/formatDate";
import { fetchAccountTypes, updateAccountType } from "store/actions";
import AddAccountType from "./AddSwapType";
import { Link } from "react-router-dom";
import useModal from "hooks/useModal";
import EditAccountType from "./EditSwapType";
import SwapGroupsModal from "./swapGroupsModal";
import EditSwapType from "./EditSwapType";


function SwapTypes(props) {
  const [accountType, setAccountType] = useState();
  const [modalGroups, setModalGroups] = useState(null);
  const [swapType, setSwapType] = useState("");
  const [editModal, toggleEditModal] = useModal();
  const [viewGroups, toggleViewGroups] = useModal();
  const [defaultGroup, setDefaultGroup] = useState("");


  const columns = [
    {
      dataField: "createdAt",
      text: "Created Date",
      formatter: (val) => formatDate(val.createdAt)
    },
    {
      dataField: "title",
      text: "Title",
      formatter: (item) => (
        captilazeFirstLetter(item.title)
      )
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (item) => (
        captilazeFirstLetter(item.type)
      )
    },
    {
      dataField: "platform",
      text: "Platform",
      formatter: (item) => (
        captilazeFirstLetter(item.platform)
      )
    },
    {
      dataField: "defaultGroup",
      text: "Default Group",
      formatter: (item) => (item?.groups?.swapGroups?.[0] || "-")
    },
    {
      dataField: "swapGroups",
      text: "Swap Groups",
      formatter: (item) => (
        <Link to="#" className="mdi mdi-eye font-size-18 py-0"
          onClick={()=>{
            setSwapType("SWAP");
            setModalGroups(item.groups.swapGroups);
            setDefaultGroup(item?.defaultMTGroup?.mtGroupName);
            toggleViewGroups();
          }}
        >
        </Link>
      )
    },
    {
      dataField: "swapFreeGroups",
      text: "Swap Free Groups",
      formatter: (item) => (
        <Link to="#" className="mdi mdi-eye font-size-18 py-0"
          onClick={()=>{
            setSwapType("SWAP FREE");
            setModalGroups(item.groups.swapFreeGroups);
            setDefaultGroup("");
            toggleViewGroups();
          }}
        >
        </Link>
      )
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Actions",
      formatter: (item) => (
        <Link className="text-success" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => { setAccountType(item); toggleEditModal() }}
          ></i>
        </Link>
      )
    },

  ];
  const dispatch = useDispatch();
  const { accountTypes, accountTypesLoading, updating } = useSelector((state) => state.tradingAccountReducer);

  useEffect(() => {
    loadAccountTypes();
  }, []);

  const loadAccountTypes = () => {
    dispatch(fetchAccountTypes());
  };

  const updateVisibility = (crmOrCp, item) => {
    dispatch(updateAccountType(item._id, !item[crmOrCp] ? { [crmOrCp]: true } : { [crmOrCp]: false }));
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>
        SWAP/SWAPFREE Accounts
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>SWAP/SWAPFREE Accounts</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">SWAP/SWAPFREE Accounts ({accountTypes?.length ?? 0})</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-hover "
                      >
                        <Thead className="text-center table-light" >
                          <Tr>
                            {columns.map((column, index) =>
                              <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center" style={{ fontSize: "13px" }}>
                          {accountTypesLoading && <TableLoader />}
                          {accountTypes?.filter((type) => type.type === "LIVE")?.map((row, rowIndex) =>
                            <Tr key={rowIndex}>
                              {columns.map((column, index) =>
                                <Td key={`${rowIndex}-${index}`}>
                                  {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                </Td>
                              )}
                            </Tr>
                          )}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {<EditSwapType
        show={editModal}
        selectedAccountType={accountType}
        toggle={toggleEditModal}
      // bankAccountUpdateHandler={bankAccountUpdateHanlder}
      />}
      <SwapGroupsModal show={viewGroups} toggle={toggleViewGroups} groups={modalGroups} swapType={swapType} defaultGroup={defaultGroup} />


    </React.Fragment>
  );
}

export default SwapTypes;
