import React, { useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "../../../../components/Common/AsyncAvFieldSelect";
import * as rebateSettingsApis from "apis/rebateSettings";
import { debounce } from "lodash";


const SearchableMTGroupsDropdown = (props) => {

  const {
    label = "MT Groups",
    placeholder = "Search",
    isRequired = false,
    name = "mtGroups",
    isDisabled = false,
    defaultOptions = [],
    value = null,
    clientData = null,
    onChange = () => { },
    isMulti = true,
    closeMenuOnSelect = true
  } = props;

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      rebateSettingsApis.getMtGroups({
        payload: {
          searchText: inputValue,
          page: 1,
          limit: 10000,
        }
      }).then((data) => {
        return cb(data?.result?.docs.filter((group) =>
          defaultOptions.some((option) => option.label === group.mtGroupName)
        ).map((group) => (
          {
            label : group.mtGroupName,
            value : group._id
          }
        )));
      });
    }, 1000), []);

  return (
    <AsyncAvFieldSelect 
      name={name}
      options={defaultOptions}
      label={props.t(label)}
      errorMessage={props.t("MT Group is required")}
      defaultOptions={defaultOptions}
      value={value?.map((item) => ({
        label: item.label,
        value: item.value,
      }))}
      defaultValue={value?.map((item) => ({
        label: item,
        value: item,
      }))}    
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      loadOptions={debouncedChangeHandler}
      onChange={onChange}
      isMulti={isMulti}
      isDisabled={isDisabled}
      disabled={isDisabled}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  );
};


const mapStateToProps = (state) => ({
  agents: state.usersReducer.salesAgent,
  agentsLoading: state.usersReducer.loading,
});
export default connect(mapStateToProps, null)(withTranslation()(SearchableMTGroupsDropdown));