/**
 * Converts a given input to a numeric value.
 *
 * @param {number | string | object} amount - The input value to be converted to a number. 
 *   - If `amount` is a number or a string that can be converted to a number, it returns the numeric value.
 *   - If `amount` is an object containing a `$numberDecimal` property, it extracts and converts the value of `$numberDecimal` to a number.
 *   - If `amount` is `null` or `undefined`, it returns 0.
 * 
 * @returns {number} - The numeric representation of the input value.
 */
const getNumericValue = (amount) => {
  if (!amount) return 0;
  if (typeof amount === "object" && "$numberDecimal" in amount) {
    return Number(amount.$numberDecimal);
  }
  return Number(amount);
};

module.exports = {
  getNumericValue,
};
