/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import AddForexDepositModal from "./AddForexDepositModal";
import {
  approveFxDeposit,
  fetchForexDeposits,
  rejectFxDeposit,
  
} from "store/forexTransactions/deposits/actions";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import TableLoader from "components/Common/TableLoader";
import Notification from "components/Common/Notification";
import logo from "../../../../assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
//import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { Link, useLocation } from "react-router-dom";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import { fetchForexDepositsGatewaysStart } from "store/forexGateway/actions";
import CustomDropdown from "components/Common/CustomDropDown";
import Filter from "./DepositFilter";
import Badge from "components/Common/Badge";
import formatDate from "helpers/formatDate";
import ReceiptModal from "../ReceiptModal";
import FeatherIcon from "feather-icons-react";
import TransactionForexModal from "components/Common/TransactionForexModal";

function DepositForex(props) {
  // get query paramerters from url
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showNotication, setShowNotifaction] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const depositApproveModal = (deposit) => {
    setSelectedDeposit(deposit);
    setApproveModal(true);
  };

  // Local one based on state not documentQuerySelector
  const checkAllBoxes = () => {
    const newCheckedState = !allChecked;
    setAllChecked(newCheckedState);

    const newCheckedItems = {};
    props.forexDeposits.forEach((deposit, index) => {
      newCheckedItems[index] = newCheckedState;
    });
    setCheckedItems(newCheckedItems);
  };

  const depositRejectModal = (deposit) => {
    setSelectedDeposit(deposit);
    setRejectModal(true);
  };

  const approveDeposit = (deposit) => {
    dispatch(
      approveFxDeposit({
        id: deposit._id,
        customerId: deposit?.customerId?._id,
        amount: deposit?.amount,
        note: deposit?.note,
        fee: deposit?.fee,
        convertedAmount: deposit?.convertedAmount,
        currencyConvertedAmount: deposit?.currencyConvertedAmount,
        paid: deposit?.paid,
        toCurrency: deposit?.toCurrency
      })
    );
    setApproveModal(false);
  };

  const depositReject = (deposit) => {
    dispatch(
      rejectFxDeposit({
        id: deposit._id,
        customerId: deposit.customerId._id,
        note: deposit.note
      })
    );
    setRejectModal(false);
  };
  const columns = [
    // {
    //   dataField: "checkbox",
    //   text: (
    //     <input
    //       type="checkbox"
    //       id="check-all-deposits"
    //       checked={allChecked}
    //       onChange={() =>
    //         checkAllBoxes("check-all-deposits", ".deposit-checkbox")
    //       }
    //     />
    //   ),
    // },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => formatDate(val.createdAt),
    },
    {
      dataField: "recordId",
      text: props.t("Transaction Id"),
    },
    {
      dataField: "customerId",
      text: props.t("Client"),
      formatter: (val) => {
        //console.log("val CLIENT FOREX", val);
        return (
          <div>
            <Link
              to={{
                pathname: `/clients/${val?.customerId?._id}/profile`,
                state: { clientId: val.customerId },
              }}
            >
              <span className="no-italics" style={{ fontWeight: "bold" }}>
                {val.customerId
                  ? `${captilazeFirstLetter(
                      val.customerId.firstName
                    )} ${captilazeFirstLetter(val.customerId.lastName)}`
                  : ""}
              </span>
            </Link>
          </div>
        );
      },
    },
    {
      dataField: "processedBy",
      text: props.t("Processed By"),
      formatter: (val) => {
        return (
          <div>
            <span className="no-italics" style={{ fontWeight: "bold" }}>
              {val.processedBy
                ? `${captilazeFirstLetter(
                    val.processedBy.firstName
                  )} ${captilazeFirstLetter(val.processedBy.lastName)}`
                : val.gateway === "ASIA_BANKS"
                ? "By System"
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      dataField: "gateway",
      text: props.t("Gateway"),
      formatter: (val) =>
        val.gateway == "WIRE_TRANSFER"
          ? "Wire"
          : val.gateway?.split("_").join(" ")?.toLowerCase(),
    },
    {
      dataField: "tradingAccount",
      text: props.t("Trading Account"),
      formatter: (item) => item.tradingAccountId?.login,
    },
    {
      dataField: "currency",
      text: props.t("From Currency"),
    },
    // {
    //   dataField: "toCurrency",
    //   text: props.t("To Currency"),
    // },
    {
      dataField: "receipt",
      text: props.t("Bank Receipt"),
      formatter: (val) => {
        //console.log(val);
        if (val?.receipt) {
          return (
            <div className="text-center">
              <div
                className={val?.receipt ? "text-success" : "text-muted "}
                style={{
                  cursor: "pointer",
                }}
              >
                <i
                  className="mdi mdi-eye font-size-20"
                  id="edittooltip"
                  onClick={() => {
                    if (val?.gateway !== "STRIPE") {
                      setDetailsModal(true);
                      setSelectedContent({
                        type: "image",
                        content: val?.receipt,
                      });
                    } else {
                      window.open(val?.receipt, "_blank");
                    }
                  }}
                ></i>
              </div>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <div className={"text-muted "}>
                <i className="mdi mdi-eye font-size-20" id="edittooltip"></i>
              </div>
            </div>
          );
        }
      },
    },
    {
      dataField: "additionalDetails",
      text: props.t("Additional Details"),
      formatter: (val) => {
        if (val?.content || val?.pspOrders) {
          return (
            <div className="text-center">
              <div
                className={
                  (val?.content || val?.pspOrders) &&
                  ["ASIA_BANKS", "CRYPTO"].includes(val.gateway)
                    ? "text-success"
                    : "text-muted "
                }
                style={{
                  cursor: "pointer",
                }}
              >
                <i
                  className="mdi mdi-eye font-size-20"
                  id="edittooltip"
                  onClick={() => {
                    console.log("Additional Details clicked:", val);
                    if (val?.gateway === "WIRE_TRANSFER"){
                      setDetailsModal(true);
                      setSelectedContent({
                        type: "json",
                        content: {
                          currency: val?.currency,
                          amount: `${val?.amount} ${val?.currency}`,
                          fee: val?.fee,
                          paid: `${val?.paid} ${val?.currency}`,
                          convertedAmount: `${val?.currencyConvertedAmount} ${val?.toCurrency}`,
                          toCurrency: val?.toCurrency,
                          tradingAccount: val?.tradingAccountId?.login,
                          //type: "wire transfer for forex",
                        },
                      });
                    }
                    if (val.gateway === "ASIA_BANKS") {
                      setDetailsModal(true);
                      setSelectedContent({
                        type: "json",
                        Id: val?.pspOrders[0] ? val.pspOrders[0]._id : "",
                        // ...val?.pspOrderIds
                        // Id: val ? val.pspOrders[0].id : "00000",
                      });
                    } else if (val.gateway === "CRYPTO") {
                      setDetailsModal(true);
                      setSelectedContent({
                        type: "json",
                        content: val?.content,
                      });
                    }
                  }}
                ></i>
              </div>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <div className={"text-muted "}>
                <i className="mdi mdi-eye font-size-20" id="edittooltip"></i>
              </div>
            </div>
          );
        }
      },
    },
    {
      dataField: "reason",
      text: props.t("Note"),
      formatter: (val) =>
        val.reason ? (
          <div data-title={val.reason}>
            {val.reason.length > 20
              ? `${val.reason.slice(0, 20)}...`
              : val.reason}
          </div>
        ) : (
          <>{val?.note ?? " - "}</>
        ),
    },
    {
      dataField: "paid",
      text: props.t("paid"),
      formatter: (val) => val.paid.toFixed(2),
    },
    {
      dataField: "fee",
      text: props.t("Fee"),
      formatter: (val) => val.fee.toFixed(2),
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      formatter: (val) => val.amount.toFixed(2),
    },
    {
      dataField: "salesRep",
      text: props.t("Sales Rep"),
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (val) => <Badge status={val.status}></Badge>,
    },
    {
      dataField: "dropdown",
      isDummyField: true,
      editable: false,
      text: props.t("Actions"),
    },
  ];

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: query.get("status") || "",
    currency: "",
    paid: "",
    amount: "",
    fee: "",
    gateway: "",
    tradingAccount: "",
    agent: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };
  const loadForexDeposits = (page, limit) => {
    if (searchInput && searchInput.length > 3) {
      dispatch(
        fetchForexDeposits({
          limit,
          page,
          filteredValues,
          searchText: searchInput,
        })
      );
    } else {
      dispatch(
        fetchForexDeposits({
          limit,
          page,
          filteredValues,
        })
      );
    }
  };

  const loadForexGateways = () => {
    dispatch(fetchForexDepositsGatewaysStart());
  };

  const closeNotifaction = () => {
    setShowNotifaction(false);
  };

  useEffect(() => {
    loadForexDeposits(1, sizePerPage);
  }, [
    props.addLoading,
    sizePerPage,
    1,
    searchInput,
    selectedFilter,
    filteredValues,
    props.depositResponseMessage,
  ]);

  useEffect(() => {
    loadForexGateways();
  }, []);

  return (
    <React.Fragment>
      <Notification
        onClose={closeNotifaction}
        body={props.t("The deposit has been updated successfully")}
        show={showNotication}
        header={props.t("Deposit Update")}
        logo={logo}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="color-primary">
                  {props.t(`Deposits(${props.totalDocs})`)}
                  <FeatherIcon
                    icon="refresh-cw"
                    className="icon-lg ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      loadForexDeposits(1, sizePerPage);
                    }}
                  />
                </CardTitle>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                <div className="d-flex flex-row align-items-center">
                  <SearchBar
                    handleSearchInput={handleSearchInput}
                    placeholder={props.t("Search for deposits")}
                  />
                  <Filter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  />
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <AddForexDepositModal />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table  table-hover ">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) => (
                          <Th data-priority={index} key={index}>
                            <span className="color-primary">{column.text}</span>
                          </Th>
                        ))}
                      </Tr>
                    </Thead>

                    {props.totalDocs === 0 ? (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {props.fetchLoading && <TableLoader colSpan={4} />}
                        {!props.fetchLoading && (
                          <>
                            <Tr>
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                                st
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                      </Tbody>
                    ) : (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {props.fetchLoading && <TableLoader colSpan={4} />}
                        {!props.fetchLoading &&
                          props.forexDeposits.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td
                                  key={`${rowIndex}-${index}`}
                                  className="pt-4"
                                >
                                  {column.dataField === "checkbox" ? (
                                    <input
                                      className="deposit-checkbox"
                                      type="checkbox"
                                      checked={checkedItems[rowIndex] || false}
                                      onChange={() => {
                                        const newCheckedItems = {
                                          ...checkedItems,
                                        };
                                        newCheckedItems[rowIndex] =
                                          !newCheckedItems[rowIndex];
                                        setCheckedItems(newCheckedItems);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                  {column.dataField === "dropdown" && (
                                    <CustomDropdown
                                      permission={
                                        props.depositsPermissions.actions
                                      }
                                      status={row.status}
                                      approve={() => depositApproveModal(row)}
                                      reject={() => depositRejectModal(row)}
                                    />
                                  )}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                      </Tbody>
                    )}
                  </Table>
                  <CustomPagination
                    {...props}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadForexDeposits}
                    docs={props.forexDeposits}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {
        <ReceiptModal
          content={selectedContent}
          open={detailsModal}
          onClose={() => setDetailsModal(false)}
        />
      }
      {approveModal && (
        <TransactionForexModal
          show={approveModal}
          title="Approve Deposit"
          submitButton="Approve"
          showAmount={true}
          toggle={() => {
            setApproveModal(!approveModal);
          }}
          t={props.t}
          onSubmit={approveDeposit}
          data={selectedDeposit}
        ></TransactionForexModal>
      )}
      {rejectModal && (
        <TransactionForexModal
          show={rejectModal}
          title="Reject Deposit"
          submitButton="Reject"
          showAmount={false}
          toggle={() => {
            setRejectModal(!rejectModal);
          }}
          t={props.t}
          onSubmit={depositReject}
          data={selectedDeposit}
        ></TransactionForexModal>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  fetchLoading: state.forexDepositReducer.fetchLoading || false,
  addLoading: state.forexDepositReducer.addLoading || false,
  forexDeposits: state.forexDepositReducer.forexDeposits || [],
  page: state.forexDepositReducer.page || 1,
  totalDocs: state.forexDepositReducer.forexTotalDocs || 0,
  totalPages: state.forexDepositReducer.totalPages || 0,
  hasNextPage: state.forexDepositReducer.hasNextPage,
  hasPrevPage: state.forexDepositReducer.hasPrevPage,
  limit: state.forexDepositReducer.limit,
  nextPage: state.forexDepositReducer.nextPage,
  pagingCounter: state.forexDepositReducer.pagingCounter,
  prevPage: state.forexDepositReducer.prevPage,
  depositsPermissions: state.Profile.depositsPermissions || {},
  depositResponseMessage: state.forexDepositReducer.depositResponseMessage,
  tradingAccounts: state.tradingAccountReducer.tradingAccounts,
});
export default connect(mapStateToProps, null)(withTranslation()(DepositForex));
