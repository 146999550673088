import React, {
  useCallback, useEffect, useState
} from "react";
import { debounce } from "lodash";
import * as clientApi from "apis/client";
import * as usersApi from "apis/users";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "./AsyncAvFieldSelect";


const SearchableClientIbSelectTransfer = (props) => {

  const {
    placeholder = "Search",
    isRequired = false,
    name = "client",
    label = "Clients",
    type = null,
    level = null,
    defaultOptions = [],
    value = null,
    allAggrementsCustomers = [],
    onChange = () => { },
    disallowed = null,
    isDisabled = false,
    currentClientId = null
  } = props;

  const [selectOptions, setSelectOptions] = useState(defaultOptions);

  useEffect(() => {
    if (level === "0") {
      const payload = {
        page: 1,
        limit: 200,
      };
      usersApi.getUsers({ payload }).then((data) => {
        setSelectOptions(data?.docs
          .filter((client) => !allAggrementsCustomers?.includes(String(client._id)))
          .map((client) => ({
            value: client,
            label: `${client.firstName} ${client.lastName}`
          })));
      });

    } else {
      const payload = {
        page: 1,
        limit: 200,
        fxType: type,
        fxLevel: level,
      };
      if (type) payload.fxType = type;
      if (level) payload.fxLevel = Number(level);
      clientApi.getClients({ payload }).then((data) => {
        setSelectOptions(data?.result?.docs
          .filter((client) => client._id !== disallowed && client._id !== currentClientId)
          .filter((client) => !allAggrementsCustomers?.includes(String(client._id)))
          .map((client) => ({
            value: client,
            label: `${client.firstName} ${client.lastName}`
          })));
      });

    }
  }, [level]);

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      clientApi.getClients({
        payload: {
          searchText: inputValue,
          fxType: type,
          fxLevel: Number(level),
          page: 1,
          limit: 200,
        }
      }).then((data) => {
        return cb(data?.result?.docs.filter((client) => client._id !== disallowed && client._id !== currentClientId)
          .filter((client) => !allAggrementsCustomers?.includes(String(client._id)))
          .map((client) => (
            {
              label: `${client.firstName} ${client.lastName}`,
              value: `${client}`
            }
          )));
      });
    }, 1000), []);
  return (
    <AsyncAvFieldSelect
      name={name}
      options={selectOptions}
      label={props.t(label)}
      errorMessage={props.t("Agent is required")}
      loadOptions={debouncedChangeHandler}
      defaultOptions={selectOptions || defaultOptions}
      value={value || ""}
      defaultValue={value || ""}
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  );
};


const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, null)(withTranslation()(SearchableClientIbSelectTransfer));