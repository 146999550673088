import { useState, useEffect } from "react";
import user from "./../../../src/assets/images/brands/user.png";
import team from "./../../../src/assets/images/brands/team.png";
import regions from "./../../../src/assets/images/brands/world.png";
const Item = ({
  expanded = false,
  children,
  label,
  hasChildren,
  firstNest,
  subItem,
  member,
  region,
  regionTitle,
  managerMember,
  setSelectedTeam = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const toggleExpand =  () => {
    if (hasChildren){
      setIsExpanded((prev) => !prev);
    }
    const region = regionTitle ? document.getElementById(regionTitle) : null;
    if (region) {
      region.classList.add("set-color"); 
    }
    document.querySelectorAll("li").forEach((li) => {
      li.addEventListener("click", (event) => {
        document.querySelectorAll("li.set-color").forEach((el) => el.classList.remove("set-color"));
        let currentLi = event.target.closest("li"); 
        while (currentLi) {
          currentLi.classList.add("set-color"); 
          currentLi = currentLi.parentElement.closest("li"); 
        }
      });
    });
  };

  return (
    <>
      <div
        className="accordion-header"
        onClick={toggleExpand}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {hasChildren && (
          <button
            title="expand"
            type="button"
            className="btn-tree"
            style={{
              textAlign: "center",
              padding: "0px",
              fontSize: "10px",
              width: "16px",
              height: "16px",
              borderRadius: "1px",
            }}
          >
            {isExpanded ? <i className="mdi mdi-minus small-icon"></i> : <i className="mdi mdi-plus small-icon"></i>}
          </button>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setSelectedTeam(subItem)}
        >
          {!hasChildren && !firstNest && (
            <span style={{ fontWeight: 700 }}>---</span>
          )}
          <div
            className="accordion-title"
            style={{
              paddingLeft: !hasChildren && firstNest ? "16px" : "0px",
              fontWeight: managerMember ? "700" : "500" 
            }}
          >
            {member ? 
              <img style={{
                padding: " 0px 5px ",
              }} width={25} height={20} src={user} alt={`user-${label}`} />
              :
              region ? 
                <img style={{
                  padding: " 0px 5px ",
                }} width={25} height={15} src={regions} alt={`region-${label}`} />
                :
                <img style={{
                  padding: " 0px 5px ",
                }} width={30} height={20} src={team} alt={`team-${label}`} />
            }
          
            <span  style={{
              fontWeight: managerMember ? "700" : "" 
            }}>
              {label}
            </span>
          </div>
        </div>
      </div>
      {isExpanded && hasChildren && (
        <ul
          className="accordion-content padding-none"
          style={{
            listStyle: "none",
            padding: 0,
          }}
        >
          {children}
        </ul>
      )}
    </>
  );
};


export default Item;
