import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Modal, ModalHeader, ModalBody, Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Loader from "components/Common/Loader";
import { useEffect, useState } from "react";
import { changeAccess, fetchTradingAccounts } from "store/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";

const ChangeAccess = ({ show, toggle, selectedAccount, customerId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tradingAccounts = useSelector((state) => state.tradingAccountReducer.accounts.docs);
  const { submitting, error } = useSelector((state) => state.tradingAccountReducer);
  
  useEffect(() => {
    if (submitting === false && error === null && show === true) {
      toggle();
      dispatch(fetchTradingAccounts({
        customerId,
      }));
    }
  }, [submitting]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggle}
      >
        {t("Change Access")}
      </button>
      <Modal centered isOpen={show} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {t("Change Access")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={(e, v) => {
              v.isActivating = JSON.parse(v.isActivating);
              const [login, platform] = v.login.split(" ");
              v.login = parseInt(login);
              v.platform = platform;
              dispatch(changeAccess(v));
            }}
          >
            <AvFieldSelect
              name="login"
              label={t("Account Login")}
              value={selectedAccount?.login ?? (tradingAccounts && +tradingAccounts[0]?.login)}
              className="mt-1 mb-2"
              options={tradingAccounts?.map((account) => ({
                value: `${+account.login} ${account.platform}`,
                label: `${account.login} (${account.isActive ? "Active" : "Inactive"})`
              }))}
            />
            <AvFieldSelect
              name="isActivating"
              value={true}
              label={t("Action")}
              className="mt-1"
              options={[
                {
                  value: true,
                  label: t("Activate") 
                },
                {
                  value: false,
                  label: t("Deactivate") 
                }
              ]}
            />
            <div className="text-center mt-3 mb-1">
              {
                submitting
                  ? <Loader />
                  : <Button type="submit" className="border-0 color-bg-btn shadow">{t("Change")}</Button>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChangeAccess;