import { APPROVE_FOREX_DEPOSIT_SUCCESS } from "../deposits/actionTypes";
import {
  FETCH_SYMBOLS_REQUESTED,
  FETCH_SYMBOLS_SUCCESS,
  FETCH_SYMBOLS_FAIL,

  SYNC_SYMBOL_REQUESTED,
  SYNC_SYMBOL_SUCCESS,
  SYNC_SYMBOL_FAIL,
  
  ADD_INTERNAL_TRANSFER_REQUESTED,
  ADD_INTERNAL_TRANSFER_SUCCESS,
  ADD_INTERNAL_TRANSFER_FAIL,
  ADD_INTERNAL_TRANSFER_CLEAR,
  ADD_INTERNAL_TRANSFER_ERROR_CLEAR,
  APPROVE_INTERNAL_TRANSFER,
  APPROVE_INTERNAL_TRANSFER_SUCCESS,
  REJECT_INTERNAL_TRANSFER,
  REJECT_INTERNAL_TRANSFER_SUCCESS,
  CHANGE_SYMBOL_START,
  CHANGE_SYMBOL_END
} from "./actionTypes";

const initalState = {
  symbols: [],
  internalTransfers: [],
  fetchSymbolsLoading: false,
  addSymbolsLoading: false,
  disableAddButton: false,
  showAddSuccessMessage: false,
  error: "",
  deleting: false,
  modalClear: false,
  symbolResponseMessage: "",
  addSymbolClearingCounter: 0
};

const symbolReducer = (state = initalState, action) => {
  switch (action.type){
    // fetch internal transfers
    case FETCH_SYMBOLS_REQUESTED:
      state = {
        ...state,
        fetchSymbolsLoading: true
      };
      break;
    case FETCH_SYMBOLS_SUCCESS:
      state = {
        ...state,
        symbols: [...action.payload.result.docs],
        symbolsTotalDocs: action.payload.result.totalDocs,
        hasNextPage: action.payload.result.hasNextPage,
        hasPrevPage: action.payload.result.hasPrevPage,
        limit: action.payload.result.limit,
        nextPage: action.payload.result.nextPage,
        page: action.payload.result.page,
        pagingCounter: action.payload.result.pagingCounter,
        prevPage: action.payload.result.prevPage,
        totalPages: action.payload.result.totalPages,
        fetchSymbolsLoading: false,  
        totalDocs: action.payload.result.totalDocs
      };
      break;
    case FETCH_SYMBOLS_FAIL:
      state = {
        ...state,
        fetchSymbolsLoading: false,
        symbolsError: action.payload
      };
      break;

    // Sync Symbols
    case SYNC_SYMBOL_REQUESTED:
      state = {
        ...state,
        addSymbolsLoading: true,
        disableAddButton: true,
      };
      break;
    case SYNC_SYMBOL_SUCCESS:
      state = {
        ...state,
        newSymbol: action.payload.result,
        showAddSuccessMessage: true,
        addSymbolSuccess: true,
        addSymbolFail: false,
        disableAddButton: false,
      };
      break;
    case SYNC_SYMBOL_FAIL:
      state = {
        ...state,
        addSymbolSuccess: false,
        disableAddButton: false,
        addSymbolFail: true,
        addSymbolsLoading: false,
        addSymbolFailDetails: action.payload.error
      };
      break;

    case CHANGE_SYMBOL_START:
      return {
        ...state,
        deleting: true,
      };
    case CHANGE_SYMBOL_END:
      return {
        ...state,
        deleting: false,
        deletingClearCounter: state.deletingClearCounter + 1,
      };
    // add internal transfer
    case ADD_INTERNAL_TRANSFER_REQUESTED:
      state = {
        ...state,
        addSymbolsLoading: true
      };
      break;
    case ADD_INTERNAL_TRANSFER_SUCCESS:
      state = {
        ...state,
        newSymbol: action.payload.result,
        addSymbolSuccess: true,
        addSymbolFail: false
      };
      break;
    case ADD_INTERNAL_TRANSFER_FAIL:
      state = {
        ...state,
        addSymbolSuccess: false,
        addSymbolFail: true,
        addSymbolsLoading: false,
        addSymbolFailDetails: action.payload.error
      };
      break;
    case ADD_INTERNAL_TRANSFER_CLEAR:
      state = {
        ...state,
        addSymbolsLoading: false,
        addSymbolClearingCounter: state.addSymbolClearingCounter + 1,
        addSymbolFail: false,
        addSymbolSuccess: false,
        disableAddButton: false,
        modalClear: true
      };
      break;
    case ADD_INTERNAL_TRANSFER_ERROR_CLEAR:
      state = {
        ...state,
        addSymbolsLoading: false,
        addSymbolFail: false,
        disableAddButton: false,
        addSymbolFailDetails: null
      };
      break;
    case APPROVE_INTERNAL_TRANSFER: 
      state = {
        ...state,
      };
      break;
    case APPROVE_INTERNAL_TRANSFER_SUCCESS: 
      state = {
        ...state,
        internalTransfers: state.internalTransfers.length > 0 && state.internalTransfers.map(d => {
          if (d._id === action.payload){
            d.status = "APPROVED";
          }
          return d;
        }),
      };
      break;
    case REJECT_INTERNAL_TRANSFER: 
      state = {
        ...state,
      };
      break;
    case REJECT_INTERNAL_TRANSFER_SUCCESS: 
      state = {
        ...state,
        internalTransfers: state.internalTransfers.length > 0 && state.internalTransfers.map(d => {
          if (d._id === action.payload){
            d.status = "REJECTED";
          }
          return d;
        }),
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};

export default symbolReducer;