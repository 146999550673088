import React, { useEffect } from "react";
import { 
  Row, 
  Col, 
  Button 
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { updateAgreement } from "store/actions";
import { useDispatch } from "react-redux";

import * as ibAgreementsApi from "apis/ib-agreements";

function MT5Rebate({
  ibAggrementValues,
  notAddedRebate,
  aggrementValues,
  allRebateRules,
  productsValues,
  clientProductsValues,
  setClientProductsValues,
  clientDetails,
  toggle
}) {
  const dispatch = useDispatch();

  const handleChangeAmount =
    (maxAmount, product) => (value, ctx, input, cb) => {
      delete input.validations?.min;
      delete input.validations?.max;

      const enteredAmount = parseFloat(value);
      const maxAllowed = maxAmount || Infinity;
      const minAllowed = ctx?.minAmount || 0;

      if (!value || isNaN(enteredAmount)) {
        cb("Amount is required and must be a number");
      } else if (enteredAmount < minAllowed) {
        cb(`Amount must be at least ${minAllowed}`);
      } else if (enteredAmount > maxAllowed) {
        cb(`Amount must be less than or equal to ${maxAllowed}`);
      } else {
        cb(true);
      }
    };

  function handleChangeProductValue(value, product, accountTypeId) {
  
    setClientProductsValues((prev) => {
      // Merge the existing state with the new value
      const updatedState = {
        ...prev,
        [product]: {
          value: parseFloat(value) || 0,
          accountTypeId: accountTypeId
        },
      };
  
  
      // Generate the grouped payload
      const groupedPayload = Object.keys(updatedState).reduce((acc, key) => {
        const { value: productValue, accountTypeId: accId } = updatedState[key];
        if (!acc[accId]) acc[accId] = {};
        acc[accId][key] = productValue;
        return acc;
      }, {});
  
      const requestPayload = Object.entries(groupedPayload).map(([accId, products]) => ({
        accountTypeId: accId,
        products,
      }));
  
      return updatedState;
    });
  }

  const handleUpdateStructure = (values) => {
    console.log(clientProductsValues);
    dispatch(updateAgreement({
      agrementId: clientDetails?.fx?.agrementId,
      customerId: clientDetails?._id,
      updatedRebates: JSON.stringify(clientProductsValues)
    }));
    toggle();
    
  };

  useEffect(()=>{
    const payload = {
      agrementId: clientDetails?.fx?.agrementId,
      customerId: clientDetails?._id,
    };
    console.log("payload", payload);
    ibAgreementsApi.fetchIbAgreementValues(payload).then((data) => {
      console.log("fetchIbAgreementValues", data?.result?.aggrementValues);
    });
  }, []);
    
  return (
    <React.Fragment>
      <AvForm
        className='p-4'
        onValidSubmit={handleUpdateStructure}
      >
        <Row>
          <Col sm="12">
            <div className="row my-3">
              {[
                // Agreement values for MT5 platform
                ...aggrementValues
                  ?.filter((agreement) => agreement?.accountTypeId?.platform === "MT5")
                  .flatMap((value) =>
                    Object?.entries(value?.products)?.map((product) => (
                      <div
                        className="col-md-6 mt-3 d-flex gap-2 align-items-center"
                        key={product[0]}
                      >
                        <div>
                          <AvField
                            name={`amountVal${value?.group}:${product?.[0]}`}
                            label={`${value?.group}:${product?.[0]}`}
                            placeholder={"Enter value"}
                            type="number"
                            defaultValue={
                              clientProductsValues?.[product?.[0]]?.value || 0
                            }
                            onChange={(e) =>
                              handleChangeProductValue(
                                e.target.value,
                                product?.[0],
                                value?.accountTypeId?._id
                              )
                            }
                            validate={{
                              custom: handleChangeAmount(
                                productsValues?.[`${product?.[0]}`]?.[
                                  productsValues?.[`${product?.[0]}`]?.length - 1
                                ],
                                product?.[0]
                              ),
                            }}
                          />
                          <div className="d-flex gap-2">
                            <p className="mb-0">($)</p>
                            <p className="mb-0 text-success">
                              {productsValues?.[product[0]]?.map((rebate, index) =>
                                index !==
                                productsValues[product[0]]?.length - 1
                                  ? `${rebate} | `
                                  : ""
                              )}
                              {Number(
                                productsValues?.[product[0]]?.at(-1) || 0
                              ) -
                                Number(
                                  clientProductsValues?.[product[0]]?.value || 0
                                )}{" "}
                              | {clientProductsValues?.[product[0]]?.value || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ),
                // All rebate rules for MT5 platform
                ...allRebateRules
                  .filter((rule) => rule.type === "MT5")
                  .map(
                    (rule) =>
                      rule.name && (
                        <div className="col-md-6 mt-3" key={rule?._id}>
                          <div>
                            <AvField
                              name={`rebateRuleValue${rule?._id}`}
                              label={`${rule?.name}: ${
                                rule?.symbolGroupId?.groupName || ""
                              }`}
                              placeholder={"Enter value"}
                              type="number"
                              defaultValue={0}
                              onChange={(e) =>
                                handleChangeProductValue(
                                  e.target.value,
                                  rule?.symbolGroupId?.groupName,
                                  rule?.accountTypeId._id
                                )
                              }
                            />
                          </div>
                          <div className="d-flex gap-2">
                            <p className="mb-0">($)</p>
                            <p className="mb-0">0 | 0</p>
                          </div>
                        </div>
                      )
                  ),
              ]}
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary" className="">
          {"Edit"}
        </Button>
      </AvForm>
    </React.Fragment>
  );
}

export default withTranslation()(MT5Rebate);