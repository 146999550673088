export const FETCH_IB_AGREEMENTS_START = "FETCH_IB_AGREEMENTS_START";
export const FETCH_IB_AGREEMENTS_SUCCESS = "FETCH_IB_AGREEMENTS_SUCCESS";

export const FETCH_IB_AGREEMENT_START = "FETCH_IB_AGREEMENT_START";
export const FETCH_IB_AGREEMENT_SUCCESS = "FETCH_IB_AGREEMENT_SUCCESS";

export const UPDATE_IB_AGREEMENT_START = "UPDATE_IB_AGREEMENT_START";
export const UPDATE_IB_AGREEMENT_SUCCESS = "UPDATE_IB_AGREEMENT_SUCCESS";

export const FETCH_IB_PRODUCTS_START = "FETCH_IB_PRODUCTS_START";
export const FETCH_IB_PRODUCTS_SUCCESS = "FETCH_IB_PRODUCTS_SUCCESS";

export const ADD_MASTER_IB_AGREEMENT_START = "ADD_MASTER_IB_AGREEMENT_START";
export const ADD_MASTER_IB_AGREEMENT_SUCCESS = "ADD_MASTER_IB_AGREEMENT_SUCCESS";

export const ADD_SHARED_IB_AGREEMENT_START = "ADD_SHARED_IB_AGREEMENT_START";
export const ADD_SHARED_IB_AGREEMENT_SUCCESS = "ADD_SHARED_IB_AGREEMENT_SUCCESS";

export const UPDATE_MASTER_IB_AGREEMENT_START = "UPDATE_MASTER_IB_AGREEMENT_START";
export const UPDATE_MASTER_IB_AGREEMENT_SUCCESS = "UPDATE_MASTER_IB_AGREEMENT_SUCCESS";

export const UPDATE_SHARED_IB_AGREEMENT_START = "UPDATE_SHARED_IB_AGREEMENT_START";
export const UPDATE_SHARED_IB_AGREEMENT_SUCCESS = "UPDATE_SHARED_IB_AGREEMENT_SUCCESS";

export const DELETE_IB_AGREEMENT_START = "DELETE_IB_AGREEMENT_START";
export const DELETE_IB_AGREEMENT_SUCCESS = "DELETE_IB_AGREEMENT_SUCCESS";

export const API_ERROR = "API_ERRORR";
