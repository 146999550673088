import { useDispatch, connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, {
  useState, useEffect, useRef 
} from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect";
import AvFieldSelect from "components/Common/AvFieldSelect";
import {
  addRebateRulesRequest,
  fetchRebateRules,
} from "store/rebateSettings/rebateRules/actions";
import {
  fetchGroups,
  fetchMtGroups,
} from "store/rebateSettings/groups/actions";
import { fetchAccountTypes } from "store/actions";
import SearchableRebateRulesDropdown from "./SearchableComponents/SearchableRebateRulesDropdown";
import ReactSelect from "react-select";

function SymbolsAdd(props) {
  const [addModal, setAddModal] = useState(false);
  const [symboloptions, setSymboloptions] = useState([]);
  const [accountTypesOptions, setAccountTypesOptions] = useState([]);
  const [mtgroupssOptions, setMtgroupssOptions] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [accountTypeData, setAccountTypeData] = useState(null);
  const [selectedMtGroups, setSelectedMtGroups] = useState([]);
  const [selectedPromotionValues, setSelectedPromotionValues] = useState([]);
  const [mtErrMsg, setMtErrMsg] = useState("");
  const [tierErrMsg, setTierErrMsg] = useState("");
  const [promoErrMsg, setPromoErrMsg] = useState("");
  const [ruleName, setRuleName] = useState("");
  const formRef = useRef(null);

  useEffect(() => {
    console.log(
      "tier1RebateRules",
      props?.tier1RebateRules,
      selectedPromotionValues
    );
  }, [props?.tier1RebateRules, selectedPromotionValues]);

  const dispatch = useDispatch();
  const platform = props?.type;

  const toggleAddModal = () => {
    setAddModal((preValue) => !preValue);
  };

  const { create } = props.dictionariesPermissions;

  const fullFormReset = () => {
    setSelectedAccountType(null);
    setSelectedMtGroups([]);
    setSelectedPromotionValues([]);
    setRuleName("");
    setAccountTypeData(null);
    setMtgroupssOptions([]);
    setSymboloptions([]);
    setAccountTypesOptions([]);
    setTierErrMsg("");
    setPromoErrMsg("");
    setMtErrMsg("");
  };

  const formReset = () => {
    setSelectedAccountType(null);
    setAccountTypeData(null);
    setMtgroupssOptions([]);
    setSelectedMtGroups([]);
    //setSelectedPromotionValues([]);
  };

  useEffect(() => {
    if (props.addSuccess || props.showAddSuccessMessage) {
      fullFormReset();
      setAddModal(false);
      if (props.type){
        dispatch(fetchRebateRules({
          limit: 10,
          page: 1,
          type: props.type,
        }));
      } else {
        dispatch(fetchRebateRules({
          limit: 10,
          page: 1,
        }));
      }
    }
  }, [props.showAddSuccessMessage, props.addSuccess]);

  useEffect(() => {
    dispatch(
      fetchGroups({
        limit: 100000,
        page: 1,
        type: props?.type,
      })
    );
    dispatch(
      fetchAccountTypes({
        limit: 100000,
        page: 1,
        type: "LIVE",
      })
    );
    dispatch(
      fetchMtGroups({
        limit: 100000,
        page: 1,
        // type: props?.type,
      })
    );
  }, [props.type]);

  useEffect(() => {
    if (props?.groups) {
      const symbolOptions = props?.groups?.map((group) => {
        return {
          value: group?._id,
          label: group?.groupName,
        };
      });
      setSymboloptions(symbolOptions);
    }
  }, [props.groups]);

  useEffect(() => {
    if (props?.accountTypes) {
      const accountTypesOptions = props?.accountTypes
        ?.filter((type) => type.enabled && type.platform === props.type)
        ?.map((accountType) => {
          return {
            value: accountType,
            label: accountType?.title,
          };
        });
      setAccountTypesOptions(accountTypesOptions);
    }
  }, [props.accountTypes]);


  // useEffect(() => {
  //   if (props?.mtGroups) {
  //     console.log("MT Groups: ", props?.mtGroups);
  //     console.log("Selected Account Type: ", selectedAccountType);
  //     const mtgroupssOptions = props?.mtGroups
  //       .filter((group) => {
  //         // Basic validation
  //         if (!group || group.status !== "Enabled" || group.accountGroupType !== selectedAccountType?.title) {
  //           return false;
  //         }

  //         // Get platform from account type
  //         const platform = selectedAccountType?.toLowerCase();

  //         // If no platform specified, don't filter by platform
  //         if (!platform) {
  //           console.log("No platform specified");
  //           return true;
  //         }

  //         // Check if group's platform matches account type's platform
  //         const groupPlatform = group.platform?.toLowerCase();

  //         // For MT4 accounts
  //         if (platform === "mt4") {
  //           return groupPlatform === "mt4";
  //         }

  //         // For MT5 accounts
  //         if (platform === "mt5") {
  //           return groupPlatform === "mt5";
  //         }

  //         return false;
  //       })
  //       .map((mtgroup) => ({
  //         value: mtgroup?._id,
  //         label: mtgroup?.mtGroupName,
  //         platform: mtgroup?.platform,
  //       }));

  //     console.log("Filtered MT Groups:", {
  //       accountType: selectedAccountType?.title,
  //       platform: selectedAccountType?.platform,
  //       totalGroups: props.mtGroups.length,
  //       filteredGroups: mtgroupssOptions.length,
  //       groups: mtgroupssOptions
  //     });

  //     setMtgroupssOptions(mtgroupssOptions);
  //   }
  // }, [props.mtGroups, selectedAccountType]);

  // useEffect(() => {
  //   if (!props.addSuccess && addModal) {
  //     //setAddModal(false);
  //     dispatch(
  //       fetchRebateRules({
  //         limit: 10,
  //         page: 1,
  //         type: props.type,
  //       })
  //     );
  //   }
  // }, [props.addSuccess]);

  useEffect(() => {
    if (props.addSuccess) {
      fullFormReset();
    }
  }, [props.addSuccess]);

  useEffect(() => {
    if (props.addRebateRulesFail) {
    }
  }, [props.addRebateRulesFail]);

  const validate = () => {
    if (selectedMtGroups.length === 0) {
      setMtErrMsg("At least one MT Group is required");
    }
    if (props?.tier1RebateRules.length === 0) {
      console.log("asd");
      setTierErrMsg("At least one Rebate Rule Value is required");
    }
    if (selectedPromotionValues.length === 0) {
      setPromoErrMsg("At least one Rebate Rule Value is required");
    }
  };

  return (
    <React.Fragment>
      <Link
        to="#"
        className={`btn btn-primary ${!create ? "d-none" : ""}`}
        onClick={toggleAddModal}
      >
        <i className="bx bx-plus me-1"></i>
        {props.t("Trading Volume Rebate Settings-Add")}
      </Link>
      <Modal
        isOpen={addModal}
        toggle={toggleAddModal}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Trading Volume Rebate Settings-Add")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              console.log("Form Values:", v);
              e.preventDefault();

              // handle tier1RebateRules
              const tier1RebateRulesData = Array.isArray(
                props?.tier1RebateRules
              )
                ? props.tier1RebateRules
                  .filter((item) => item && typeof item === "object")
                  .map((item) => item.value)
                  .filter(Boolean)
                : [];

              // handle promotionOptionalRebateValue
              const promotionOptionalRebateValueData = Array.isArray(
                selectedPromotionValues
              )
                ? selectedPromotionValues
                  .filter((item) => item && typeof item === "object")
                  .map((item) => parseFloat(item.value))
                  .filter((value) => !isNaN(value))
                : [];

              // handle mtGroupId
              const mtGroupIds = Array.isArray(selectedMtGroups)
                ? selectedMtGroups
                  .filter((item) => item && typeof item === "object")
                  .map((item) => item.value || item._id)
                  .filter(Boolean)
                : [];

              if (mtErrMsg || tierErrMsg || promoErrMsg) {
                return;
              }

              delete v.name;

              console.log("Form Account Type: ", v.accountTypeId);

              const formData = {
                ...v,
                tier1RebateRules: tier1RebateRulesData,
                promotionOptionalRebateValue: promotionOptionalRebateValueData,
                mtGroupId: mtGroupIds,
                accountTypeId: selectedAccountType,
                type: props?.type,
                name: ruleName,
              };

              console.log("Submitting form data:", formData);
              dispatch(addRebateRulesRequest(formData));

              // reset all components and form
              //setRuleName("");
              //formReset();
              // if (formRef.current) {
              //   formRef.current.reset();
              // }
            }}
          >
            <div className="mb-3">
              <AvField
                name="name"
                label={
                  <>
                    {props.t("Rule Name")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                placeholder={props.t("Enter Rule Name")}
                type="text"
                errorMessage={props.t("Enter valid Rule Name")}
                validate={{ required: { value: true } }}
                onChange={(e) => {
                  setRuleName(e.target.value);
                }}
                value={ruleName}
              />
            </div>

            {/* <div className="my-3">
              <AvFieldSelect
                name="rulesApply"
                type="text"
                errorMessage={props.t(
                  "Statement Method is required"
                )}
                label={props.t("Rules Apply")}
                options={[]}
              />
            </div> */}
            <div className="my-3">
              <AvFieldSelect
                name="symbolGroupId"
                type="text"
                errorMessage={props.t("ٌSymbol Group is required")}
                label={
                  <>
                    {props.t("Symbol Group")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                options={symboloptions}
                validate={{ required: { value: true } }}
                // symboloptions={symboloptions}
              />
            </div>
            <div className="my-3">
              <Label>
                Account Type <span style={{ color: "red" }}> * </span>
              </Label>
              <ReactSelect
                name="accountTypeId"
                type="text"
                errorMessage={props.t("ٌAccount Type is required")}
                validate={{ required: { value: true } }}
                label={
                  <>
                    {props.t("Account Type")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                options={accountTypesOptions.map((option) => ({
                  value: option.value._id, // Just store the ID as value
                  label: option.label,
                  mtGroups: option.value.mtGroups, // Preserve mtGroups in the option
                }))}
                value={
                  accountTypesOptions.find(
                    (opt) => opt.value._id === selectedAccountType
                  ) || null
                }
                onChange={(e) => {
                  // Update the MT groups options first
                  setMtgroupssOptions(e?.mtGroups || []);
                  // Update the selected account type with the full value
                  setSelectedAccountType(e?.value);
                  // Update the account type data
                  setAccountTypeData(
                    accountTypesOptions.find(
                      (opt) => opt.value._id === e?.value
                    )?.value || null
                  );
                  // Reset MT groups selection when account type changes
                  setSelectedMtGroups([]);
                  // Clear any existing error messages
                  setMtErrMsg("");
                }}
              />
            </div>
            <div className="my-3">
              <SearchableRebateRulesDropdown
                name="mtGroupId"
                type="text"
                errorMessage={props.t("MT Group is required")}
                validate={{
                  required: { value: true },
                  pattern: {
                    value: /\[.*\]|\{.*\}/,
                    errorMessage: props.t(
                      "Please select at least one MT Group"
                    ),
                  },
                }}
                label={props.t("MT Group")}
                defaultOptions={mtgroupssOptions}
                isMulti={true}
                isRequired={true}
                value={selectedMtGroups}
                onChange={(selected) => {
                  setSelectedMtGroups(selected);
                  setMtErrMsg("");
                }}
                closeMenuOnSelect={false}
              />
            </div>
            {mtErrMsg && <div className="text-danger mb-2">{mtErrMsg}</div>}
            {/* <div className="my-3">
              <AvFieldSelect
                name="spreadRebateGroup"
                type="text"
                errorMessage={props.t(
                  "ٌSpread Rebate Group is required"
                )}
                label={props.t("Spread Rebate Group")}
                options={[]}
              />
            </div> */}
            <div className="my-3">
              <AvFieldSelect
                name="statementMethod"
                type="text"
                errorMessage={props.t("Statement Method is required")}
                validate={{ required: { value: true } }}
                label={
                  <>
                    {props.t("Statement Method")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                options={[
                  {
                    value: "$",
                    label: "$",
                  },
                ]}
              />
            </div>
            <div className="mt-3">
              <AvFieldSelect
                name="statementUnit"
                type="text"
                errorMessage={props.t("Statement Unit is required")}
                validate={{ required: { value: true } }}
                label={
                  <>
                    {props.t("Statement Unit")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                options={[
                  {
                    value: "perLot",
                    label: "Per Lot",
                  },
                ]}
              />
            </div>
            <div className="mt-3">
              <Label>
                Tier 1 IB Rebate Rules <span style={{ color: "red" }}> * </span>
              </Label>
              <CustomCreatableSelect
                isMulti
                isClearable
                disableDeleteButton={false}
                disableCreateButton={false}
                setTier1RebateRules={props?.setTier1RebateRules}
                tier1RebateRules={props?.tier1RebateRules}
                placeholder="Please enter Tier 1 IB Rebate Rules"
                setTierErrMsg={setTierErrMsg}
              />
            </div>
            {tierErrMsg && <div className="text-danger mb-2">{tierErrMsg}</div>}
            <div className="my-3">
              <SearchableRebateRulesDropdown
                name="promotionOptionalRebateValue"
                type="text"
                value={selectedPromotionValues}
                errorMessage={props.t(
                  "Promotion Optional Rebate Value is required"
                )}
                validate={{ required: { value: true } }}
                label={props.t("Promotion Optional Rebate Value")}
                defaultOptions={props?.tier1RebateRules}
                isRequired={true}
                isMulti={true}
                //value={selectedPromotionValues}
                onChange={(selected) => {
                  setSelectedPromotionValues(selected);
                  setPromoErrMsg("");
                }}
              />
            </div>
            {promoErrMsg && (
              <div className="text-danger mb-2">{promoErrMsg}</div>
            )}
            <div className="my-3">
              <AvFieldSelect
                name="status"
                type="text"
                errorMessage={props.t("Status is required")}
                validate={{ required: { value: true } }}
                label={
                  <>
                    {props.t("Status")} <span style={{ color: "red" }}>*</span>
                  </>
                }
                options={[
                  {
                    value: "true",
                    label: "Enabled",
                  },
                  {
                    value: "false",
                    label: "Disabled",
                  },
                ]}
              />
            </div>
            <div className="text-center pt-3 p-2">
              <Button
                disabled={props.disableAddButton}
                type="submit"
                color="primary"
                onClick={validate}
              >
                {props.disableAddButton ? "Loading..." : props.t("Add")}
              </Button>
            </div>
          </AvForm>
          {props.error && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {props.editError}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  id: state.RebateRulesReducer.id,
  error: state.RebateRulesReducer.error,
  showAddSuccessMessage: state.RebateRulesReducer.showAddSuccessMessage,
  dictionariesPermissions: state.Profile.dictionariesPermissions || {},
  disableAddButton: state.RebateRulesReducer.disableAddButton,
  groups: state.groupReducer.groups,
  mtGroups: state.groupReducer.mtGroups?.groups,
  addSuccess: state.RebateRulesReducer.addSuccess,
  accountTypes: state.tradingAccountReducer.accountTypes,
  addRebateRulesFail: state.RebateRulesReducer.addRebateRulesFail,
});

export default connect(mapStateToProps, null)(withTranslation()(SymbolsAdd));
