import * as teamsApi from "../../apis/teams";

// const optionsPerPage = 3;

const loadTeamsOptions = async (id, search, page) => {
  //   await sleep(1000);

  let output = [];

  let filteredOptions;
  let data;
  if (!search) {
    data = await teamsApi
      .getSubTeams({
        payload: {
          id,
          page: page,
          limit: 15,
        },
      })
      .then((results) => {
        //do any results transformations
        return results;
      }).catch(() => {
        return [];
      });
    data.docs?.map(function (item) {
      output.push({
        value: item,
        label: item.title,
      });

    });
    filteredOptions = output;
  } else {
    output = [];
    data = {};
    data = await teamsApi
      .getSubTeams({
        payload: {
          id,
          page: page,
          limit: 15,
          searchText: search,
        },
      })
      .then((results) => {
        //do any results transformations
        return results;
      }).catch(() => {
        return [];
      });
    data.docs?.map(function (item) {
      output.push({
        value: item,
        label: item.title,
      });
    });

    filteredOptions = output;
  }

  return {
    options: filteredOptions,
    hasMore: data.hasNextPage,
  };
};

export default loadTeamsOptions;
