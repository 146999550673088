/* eslint-disable indent */
import React, {
 useState, useEffect, useRef 
} from "react";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CustomDropdown from "components/Common/CustomDropDown";
import TableLoader from "components/Common/TableLoader";
import Notification from "components/Common/Notification";
import logo from "assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { Link } from "react-router-dom";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import Badge from "components/Common/Badge";
import Filter from "./Filter";
import formatDate from "helpers/formatDate";
import {
  approveWalletTransfer,
  fetchWalletTransfer,
  rejectWalletTransfer,
} from "store/wallet/transfer/actions";
import FeatherIcon from "feather-icons-react";
// import InternalTransferModal from "./InternalTransferModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SymbolsAdd from "./PromotionLinksAdd";
import DeleteModal from "components/Common/DeleteModal";
import SymbolsEdit from "./PromotionLinkEdit";
import SwitchStatus from "../symbols/switchStatus";
import RebateRulesModal from "./BindingIB";
import useModal from "hooks/useModal";
import DeleteStatus from "../symbolsGroups/deleteStatus";
import PromotionLinksAdd from "./PromotionLinksAdd";
import PromotionLinkEdit from "./PromotionLinkEdit";
import BindingIB from "./BindingIB";
import { fetchPromoStart } from "store/actions";
import moment from "moment";
import DeleteStatusPromo from "./DeleteStatusPromo";

function MT5Groups(props) {
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const [showNotification, setShowNotifaction] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [viewRules, toggleViewRules] = useModal();
  // const [promoLinks, setPromoLinks] = useState(() => useSelector((state) => state?.Promo?.promos?.docs));
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  // const { actions } = useSelector((state) => state.Profile.depositsPermissions);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState();
  const [ibBinding, setIbBinding] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const initFilteredValues = {
    accountType: "",
    status: "",
    mtServer: "",
  };
  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const permissions = userData?.roleId?.permissions;
  const { promotionLinks } = permissions;
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);

  const [isLoading, setIsLoading] = useState(
    useSelector((state) => state?.Promo?.loading) || false
  );
  const promoLinks = useSelector((state) => state?.Promo?.promos?.docs);
  const promolinksPagination = useSelector((state) => state?.Promo?.promos);

  const debounceTimer = useRef(null);

  const loadPromotionLinks = async (page, limit, options) => {
    if (options) {
      dispatch(
        fetchPromoStart({
          page,
          limit,
          filteredValues: filteredValues,
          ...options,
        })
      );
    } else {
      await dispatch(
        fetchPromoStart({
          page,
          limit,
          filteredValues: filteredValues,
        })
      );
    }
  };

  useEffect(() => {
    if (searchInput) {
      loadPromotionLinks(1, 10, { title: searchInput });
    } else {
      loadPromotionLinks(1, 10);
    }
  }, [isLoading, searchInput, filteredValues]);

  const columns = [
    // {
    //   dataField: "checkbox",
    //   text: (
    //     <input
    //       type="checkbox"
    //       id="check-all-deposits"
    //       onChange={() =>
    //         checkAllBoxes("check-all-deposits", ".deposit-checkbox")
    //       }
    //     />
    //   ),
    // },
    {
      dataField: "linkTitle",
      text: props.t("Link Title"),
      formatter: (val) => val?.title,
    },
    {
      dataField: "mtServer",
      text: props.t("MT Server"),
      formatter: (val) => val?.mtServer?.name,
    },
    {
      dataField: "accountType",
      text: props.t("Account Type"),
      formatter: (val) => val?.accountType?.title,
    },
    {
      dataField: "mtGroups",
      text: props.t("MT Groups"),
      formatter: (val) =>
        val?.mtGroups?.map((group) => group?.mtGroupName).join("-"),
    },
    {
      dataField: "defaultMtGroup",
      text: props.t("Default MT Group"),
      formatter: (val) => val?.defaultMTGroup?.mtGroupName,
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (val) => val?.status,
    },
    {
      dataField: "promoCode",
      text: props.t("Promotion Code"),
      formatter: (val) => val?.promoCode,
    },
    {
      dataField: "createdAt",
      text: props.t("Created Time"),
      formatter: (val) =>
        moment(val?.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
    },
    (promotionLinks?.edit || promotionLinks?.delete ) &&
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Operation",
      formatter: (promo) => (
        <div className="text-center">
          {promotionLinks?.edit && (
            <Link className={"text-success"} to="#">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() => {
                  setSelectedPromo(promo);
                  setEditModal(true);
                }}
              ></i>
            </Link>

          )}
          {!promo.default && promotionLinks?.edit && (
            <Link className={"text-success"} to="#">
              <i
                className="mdi mdi-near-me font-size-18"
                id="edittooltip"
                onClick={() => {
                  setIbBinding(true);
                  setSelectedPromo(promo);
                }}
              ></i>
            </Link>
          )}
          {promotionLinks?.delete && (
            <Link className={"text-danger"} to="#">
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
                onClick={() => {
                  setSelectedPromo(promo);
                  setDeleteStatus(true);
                }}
              ></i>
            </Link>
          )}
        </div>
      ),
    },
  ];

  const handlePageSizeChange = (newSize) => {
    setSizePerPage(newSize);
    setCurrentPage(1); // Reset to first page when changing page size
    loadPromotionLinks(1, newSize);
  };
  const handleApproveRequest = (request) =>
    dispatch(approveWalletTransfer(request._id));

  const handleRejectRequest = (request) =>
    dispatch(rejectWalletTransfer(request._id));

  const handleSearchInput = (e) => {
    const value = e.target.value;
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => setSearchInput(value), 500);
  };

  const closeNotification = () => {
    setShowNotifaction(false);
  };

  return (
    <>
      <Notification
        onClose={closeNotification}
        body={props.t("The internal transfer has been updated successfully")}
        show={showNotification}
        header={props.t("Wallet Transfer Requests")}
        logo={logo}
      />
      <Row>
        <Col className="col-12">
          <Card>
            {promotionLinks?.view ? (
              <>
                <CardHeader className="d-flex flex-column gap-3 ">
                  <div className="d-flex justify-content-between align-items-center">
                    <CardTitle className="color-primary">
                      {props.t(`Promo Links(${promoLinks?.length || ""})`)}
                      <FeatherIcon
                        icon="refresh-cw"
                        className="icon-lg ms-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          loadPromotionLinks(1, sizePerPage);
                        }}
                      />
                    </CardTitle>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                    <div className="d-flex flex-row align-items-center">
                      <SearchBar
                        handleSearchInput={handleSearchInput}
                        placeholder={props.t("Enter Link Title")}
                      />
                      <Filter
                        filterChangeHandler={filterChangeHandler}
                        filteredValues={filteredValues}
                      />
                    </div>
                    {promotionLinks?.add && (
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <PromotionLinksAdd
                          dispatch={dispatch}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                        />
                      </div>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light">
                          <Tr>
                            {columns.map((column, index) => (
                              <Th data-priority={index} key={index}>
                                <span className="color-primary">
                                  {column.text}
                                </span>
                              </Th>
                            ))}
                          </Tr>
                        </Thead>

                        {promoLinks?.length === 0 ? (
                          <Tbody>
                            {isLoading && <TableLoader colSpan={4} />}
                            {!isLoading && (
                              <>
                                <Tr>
                                  <Td
                                    colSpan={"100%"}
                                    className="fw-bolder text-center"
                                    st
                                  >
                                    <h3 className="fw-bolder text-center">
                                      No records
                                    </h3>
                                  </Td>
                                </Tr>
                              </>
                            )}
                          </Tbody>
                        ) : (
                          <Tbody
                            style={{
                              fontSize: "12px",
                              textAlign: "center",
                              textTransform: "capitalize",
                            }}
                          >
                            {isLoading && <TableLoader colSpan={4} />}
                            {!isLoading &&
                              promoLinks?.map((row, rowIndex) => (
                                <Tr key={rowIndex}>
                                  {columns.map((column, index) => (
                                    <Td
                                      key={`${rowIndex}-${index}`}
                                      className="pt-4"
                                    >
                                      {column.dataField === "checkbox" ? (
                                        <input
                                          className="deposit-checkbox"
                                          type="checkbox"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {column.formatter
                                        ? column.formatter(row, rowIndex)
                                        : row[column.dataField]}
                                      {column.dataField === "dropdown" && (
                                        <CustomDropdown
                                          // permission={actions}
                                          status={row?.status}
                                          approve={() =>
                                            handleApproveRequest(row)
                                          }
                                          reject={() =>
                                            handleRejectRequest(row)
                                          }
                                        />
                                      )}
                                    </Td>
                                  ))}
                                </Tr>
                              ))}
                          </Tbody>
                        )}
                      </Table>
                      <CustomPagination
                        {...promolinksPagination}
                        setSizePerPage={handlePageSizeChange}
                        sizePerPage={sizePerPage}
                        onChange={loadPromotionLinks}
                        docs={promoLinks}
                      />
                    </div>
                  </div>
                </CardBody>
              </>
            ) : (
              <h1 className="text-center">
                You Do Not Have The Required Permissions
              </h1>
            )}
          </Card>
        </Col>
      </Row>
      {editModal && (
        <PromotionLinkEdit
          show={editModal}
          setEditModal={setEditModal}
          selectedPromo={selectedPromo}
          dispatch={dispatch}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {ibBinding && (
        <BindingIB
          show={ibBinding}
          setIbBinding={setIbBinding}
          selectedPromo={selectedPromo}
        />
      )}
      {deleteStatus && (
        <DeleteStatusPromo
          show={deleteStatus}
          setSwitchStatus={setDeleteStatus}
          promoId={selectedPromo?._id}
          dispatch={dispatch}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          text="promotion link"
        />
      )}
      {/* <RebateRulesModal show={viewRules} toggle={toggleViewRules} /> */}
    </>
  );
}
export default withTranslation()(MT5Groups);
