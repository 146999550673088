/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Button } from "reactstrap";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CustomDropdown from "components/Common/CustomDropDown";
import TableLoader from "components/Common/TableLoader";
import Notification from "components/Common/Notification";
import logo from "assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { Link } from "react-router-dom";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import Badge from "components/Common/Badge";
import Filter from "./Filter";
import formatDate from "helpers/formatDate";
import {
  approveWalletTransfer,
  fetchWalletTransfer,
  rejectWalletTransfer,
} from "store/wallet/transfer/actions";
import FeatherIcon from "feather-icons-react";
// import InternalTransferModal from "./InternalTransferModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SymbolsAdd from "./SymbolsAdd";
import DeleteModal from "components/Common/DeleteModal";
import SymbolsEdit from "./NumberSegment";
import DeleteStatus from "./deleteStatus";
import Accordion from "react-bootstrap/Accordion";
import useModal from "hooks/useModal";
import NumberSegment from "./NumberSegment";
import { fetchAccountMtGroups, fetchMtGroups } from "store/rebateSettings/groups/actions";
import SwitchStatus from "./switchStatus";
import AddAccountGroupType from "pages/AccountGroupTypes/AddAccountGroupType";
import EditAccountGroupType from "pages/AccountGroupTypes/EditAccountGroupType";
import { fetchAccountRequests } from "store/requests/actions";
import { fetchAccountTypes } from "store/actions";
import { platform } from "os";
import EditingAccountGroupType from "pages/AccountGroupTypes/EditingAccountGroupType";
import AdjustMTGroups from "./AdjustMTGroups";
import DefaultMTGroup from "./DefaultMTGroups";


function MTGroups(props) {
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const [showNotification, setShowNotifaction] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [selectedGroup, setSelectedGroup] = useState();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [ibBinding, setIbBinding] = useState(false);
  const [accountSegment, setAccountSegment] = useState(false);
  const [accountType, setAccountType] = useState();
  const [editModal, toggleEditModal] = useModal();
  const [editingModal, toggleEditingModal] = useModal();
  const [switchStatus, setSwitchStatus] = useState(false);
  const [defaultMTGroup, setDefaultMTGroup] = useState(false);
  const [accountTypeId, setAccountTypeId] = useState();
  const [switchGroupStatus, setSwitchGroupStatus] = useState(false);
  const { actions } = useSelector((state) => state.Profile.depositsPermissions);
  const [deleteStatus, setDeleteStatus] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);
  const { accountTypes, deleting, editedSuccess } = useSelector((state) => state.tradingAccountReducer);
  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const permissions = userData?.roleId?.permissions;
  const { mtGroups } = permissions;

  const columns = [
    // {
    //   dataField: "checkbox",
    //   text: (
    //     <input
    //       type="checkbox"
    //       id="check-all-deposits"
    //       onChange={() =>
    //         checkAllBoxes("check-all-deposits", ".deposit-checkbox")
    //       }
    //     />
    //   ),
    // },
    {
      dataField: "mtGroupName",
      text: props.t("MT Group Name"),
    },
    {
      dataField: "mtGroupType",
      text: props.t("MT Group Type"),
    },
    {
      dataField: "accountGroupType",
      text: props.t("Account Group Type"),
    },
    {
      dataField: "default",
      text: props.t("Default"),
      formatter: (val) => val.isDefault ? 
      <i className="mdi mdi-check-circle font-size-20 text-success" id="approve-icon"></i> : 
      <i className="mdi mdi-close-thick font-size-20 text-danger" id="reject-icon"></i>
    },
    {
      dataField: "status",
      text: props.t("Enabled Status"),
    },
    mtGroups?.edit &&
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (type) => (
        <div className="text-center">
          {/* <Link className={"text-success"} to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => { setAccountType(type); toggleEditingModal() }}
            ></i>
          </Link> */}
          <Link className={`${type?.status === "Enabled" ? "text-success" : "text-danger"}`} to="#">
            <i
              className={`mdi ${type?.status === "Enabled" ? "mdi-lock-open-variant" : "mdi-lock"} font-size-18`}
              id="edittooltip"
              onClick={() => {
                setSwitchGroupStatus(true);
                setSelectedGroup(type);
              }}
            ></i>
          </Link>
          {type.isAddedToAccountType && (
            <Link className={"text-success"} to="#">
              <i
                className="mdi mdi-settings font-size-18"
                id="edittooltip"
                onClick={() => {
                  setDefaultMTGroup(true);
                  setSelectedGroup(type);
                  setAccountTypeId(accountTypes.find((acc) => acc.title === type.accountGroupType));
                }}
              ></i>
            </Link>
          )}
        </div>
      ),
    },
  ];

  const handleApproveRequest = (request) =>
    dispatch(approveWalletTransfer(request._id));

  const handleRejectRequest = (request) =>
    dispatch(rejectWalletTransfer(request._id));

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: query.get("status") || "",
    currency: "",
    amount: "",
    fee: "",
    tradingAccountFrom: "",
    tradingAccountTo: "",
    agent: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };
  const mtGroupsOptions = useSelector((state) => state.groupReducer.mtGroups?.accountGroups?.groups);
  const mtGroupsPagination = useSelector((state) => state.groupReducer.mtGroups?.accountGroups?.pagination);
  const loading = useSelector((state) => state.groupReducer.mtGroups?.accountGroups?.fetchGroupsLoading);

  const loadGroups = (page, limit) => {
    if (searchInput && searchInput.length > 3) {
      dispatch(
        fetchAccountMtGroups({
          limit: Number(limit),
          page: Number(page),
          mtGroupName: searchInput,
          accountGroupType: selectedGroup && selectedGroup.title,
          accountTypePlatform: props.type
        })
      );
    } else {
      dispatch(
        fetchAccountMtGroups({
          limit: Number(limit),
          page: Number(page),
          accountGroupType: selectedGroup && selectedGroup.title,
          accountTypePlatform: props.type
        })
      );
    }
  };

  useEffect(() => {
    loadGroups(1, sizePerPage);
  }, [selectedGroup, searchInput, props.type, sizePerPage]);

  const handlePageSizeChange = (newSize) => {
    setSizePerPage(newSize);
    setCurrentPage(1); // Reset to first page when changing page size
    loadGroups(1, newSize);
  };

  useEffect(() => {
    if (accountTypes && selectedGroup) {
      setSelectedGroup(accountTypes?.find((type) => type?._id === selectedGroup?._id));
    }
  }, [accountTypes, props.type]);

  useEffect(() => {
    if (props?.type) {
      setSelectedGroup();
    }
  }, [props.type]);

  useEffect(()=>{
    dispatch(
      fetchAccountTypes({
        limit: 100000,
        page: 1,
        platform: props.type,
      })
    );
  }, [props.type, deleting, editedSuccess]);

  const closeNotification = () => {
    setShowNotifaction(false);
  };

  return (
    <>
      <Notification
        onClose={closeNotification}
        body={props.t("The internal transfer has been updated successfully")}
        show={showNotification}
        header={props.t("Wallet Transfer Requests")}
        logo={logo}
      />
      {mtGroups?.view ? (
        <Row>
          <Col className="col-3">
            <Card className="h-100">
              <CardHeader className="d-flex flex-column gap-3 ">
                <div className="d-flex justify-content-between align-items-center">
                  <CardTitle className="color-primary d-flex justify-content-between align-items-center w-100">
                    <div>{props.t("Account Group Type")}</div>
                    <AddAccountGroupType type={props?.type} />
                  </CardTitle>
                </div>
              </CardHeader>
              <CardBody>
                <Accordion>
                  <div>
                    <Accordion.Item>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="w-100">
                          <Accordion.Header onClick={() => setSelectedGroup()}>
                            {"Account Type"}
                          </Accordion.Header>
                        </div>
                      </div>
                      <Accordion.Body>
                        <table className="w-100">
                          <tbody>
                            {accountTypes?.map((type) => {
                              return (
                                <tr key={type._id}>
                                  <td>
                                    <div
                                      className={`d-flex justify-content-between align-items-center mb-2 p-3 cursor-pointer ${
                                        selectedGroup?._id === type?._id
                                          ? "btn-primary"
                                          : "bg-light"
                                      } rounded`}
                                      onClick={() => setSelectedGroup(type)}
                                    >
                                      <div>
                                        <p className="mb-0 ms-3 ">
                                          {type.title}
                                        </p>
                                      </div>
                                      <div className="text-center">
                                        {mtGroups?.edit && (
                                          <>
                                            <Link
                                              className={"text-success"}
                                              to="#"
                                            >
                                              <i
                                                className="mdi mdi-pencil font-size-18"
                                                id="edittooltip"
                                                onClick={() => {
                                                  setAccountType(type);
                                                  toggleEditModal();
                                                }}
                                              ></i>
                                            </Link>
                                            <Link
                                              className={`${
                                                type?.enabled
                                                  ? "text-success"
                                                  : "text-danger"
                                              }`}
                                              to="#"
                                            >
                                              <i
                                                className={`mdi ${
                                                  type?.enabled
                                                    ? "mdi-lock-open-variant"
                                                    : "mdi-lock"
                                                } font-size-18`}
                                                id="edittooltip"
                                                onClick={() => {
                                                  setSwitchStatus(true);
                                                  setAccountType(type);
                                                }}
                                              ></i>
                                            </Link>
                                            <Link
                                              className={"text-success"}
                                              to="#"
                                            >
                                              <i
                                                className="mdi mdi-settings font-size-18"
                                                id="edittooltip"
                                                onClick={() => {
                                                  setAccountSegment(true);
                                                }}
                                              ></i>
                                            </Link>
                                          </>
                                        )}
                                        {mtGroups?.delete && (
                                          <Link
                                            className={"text-danger"}
                                            to="#"
                                          >
                                            <i
                                              className="mdi mdi-delete font-size-18"
                                              id="deletetooltip"
                                              onClick={() => {
                                                setDeleteStatus(true);
                                              }}
                                            ></i>
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </Accordion>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-9">
            <Card className="h-100">
              <CardHeader className="d-flex flex-column gap-3 ">
                <div className="d-flex justify-content-between align-items-center">
                  <CardTitle className="color-primary">
                    {props.t(
                      `${props.type} Groups(${mtGroupsPagination?.totalDocs})`
                    )}
                    <FeatherIcon
                      icon="refresh-cw"
                      className="icon-lg ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        loadGroups(1, sizePerPage);
                      }}
                    />
                  </CardTitle>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                  <div className="d-flex flex-row align-items-center">
                    <SearchBar
                      handleSearchInput={handleSearchInput}
                      placeholder={props.t("MT Group Name")}
                    />
                    {/* <Filter
                      filterChangeHandler={filterChangeHandler}
                      filteredValues={filteredValues}
                    /> */}
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-between gap-3">
                    {mtGroups?.sync && <SymbolsAdd platform={props.type} />}
                    <Button
                      className="btn btn-primary"
                      disabled={!selectedGroup}
                      onClick={() => setIbBinding(true)}
                    >
                      Adjust The MT group account type
                    </Button>
                    <Button
                      className="btn btn-primary"
                      disabled={!selectedGroup}
                      onClick={() => setAccountSegment(true)}
                    >
                      Rebate Number Segment Settings
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table  table-hover "
                    >
                      <Thead className="text-center table-light">
                        <Tr>
                          {columns.map((column, index) => (
                            <Th data-priority={index} key={index}>
                              <span className="color-primary">
                                {column.text}
                              </span>
                            </Th>
                          ))}
                        </Tr>
                      </Thead>

                      {mtGroupsOptions?.length === 0 ? (
                        <Tbody>
                          {loading && <TableLoader colSpan={4} />}
                          {!loading && (
                            <>
                              <Tr>
                                <Td
                                  colSpan={"100%"}
                                  className="fw-bolder text-center"
                                  st
                                >
                                  <h3 className="fw-bolder text-center">
                                    No records
                                  </h3>
                                </Td>
                              </Tr>
                            </>
                          )}
                        </Tbody>
                      ) : (
                        <Tbody
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          {loading && <TableLoader colSpan={4} />}
                          {!loading &&
                            mtGroupsOptions?.map((row, rowIndex) => (
                              <Tr key={rowIndex}>
                                {columns.map((column, index) => (
                                  <Td
                                    key={`${rowIndex}-${index}`}
                                    className="pt-4"
                                  >
                                    {column.dataField === "checkbox" ? (
                                      <input
                                        className="deposit-checkbox"
                                        type="checkbox"
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                    {column.dataField === "dropdown" && (
                                      <CustomDropdown
                                        permission={actions}
                                        status={row?.status}
                                        approve={() =>
                                          handleApproveRequest(row)
                                        }
                                        reject={() => handleRejectRequest(row)}
                                      />
                                    )}
                                  </Td>
                                ))}
                              </Tr>
                            ))}
                        </Tbody>
                      )}
                    </Table>
                    <CustomPagination
                      {...mtGroupsPagination}
                      setSizePerPage={handlePageSizeChange}
                      sizePerPage={sizePerPage}
                      onChange={loadGroups}
                      docs={mtGroupsOptions}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <h1 className="text-center">
          You Do Not Have The Required Permissions
        </h1>
      )}

      {deleteStatus && (
        <DeleteStatus
          show={deleteStatus}
          selectedGroup={selectedGroup}
          setSwitchStatus={setDeleteStatus}
          text="account type"
          delteSuccess={deleting}
          type={props?.type}
        />
      )}
      {switchStatus && (
        <SwitchStatus
          show={switchStatus}
          selectedGroup={selectedGroup}
          setSwitchStatus={setSwitchStatus}
          text="account type"
          type={props?.type}
          accountType={accountType}
        />
      )}
      {defaultMTGroup && (
        <DefaultMTGroup
          show={defaultMTGroup}
          selectedGroup={selectedGroup}
          setDefaultMTGroup={setDefaultMTGroup}
          text="account type"
          type={props?.type}
          accountTypeId={accountTypeId}
        />
      )}
      {switchGroupStatus && (
        <SwitchStatus
          show={switchGroupStatus}
          selectedMTGroup={selectedGroup}
          setSwitchStatus={setSwitchGroupStatus}
          text="mt group"
          type={props?.type}
        />
      )}
      {ibBinding && (
        <AdjustMTGroups
          show={ibBinding}
          setIbBinding={setIbBinding}
          selectedGroup={selectedGroup}
          platform={props.type}
        />
      )}
      {accountSegment && (
        <NumberSegment
          show={accountSegment}
          setAccountSegment={setAccountSegment}
          selectedGroup={selectedGroup}
          type={props?.type}
        />
      )}
      {
        <EditAccountGroupType
          isOpen={editModal}
          selectedAccountType={accountType}
          selectedGroup={selectedGroup}
          onClose={toggleEditModal}
          t={props.t}
          //allServers={servers}
          // bankAccountUpdateHandler={bankAccountUpdateHanlder}
        />
      }
      {
        <EditingAccountGroupType
          isOpen={editingModal}
          selectedAccountType={accountType}
          selectedGroup={selectedGroup}
          onClose={toggleEditingModal}
          t={props.t}
          //allServers={servers}
          // bankAccountUpdateHandler={bankAccountUpdateHanlder}
        />
      }
    </>
  );
}
export default withTranslation()(MTGroups);
