import React from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Col,
  Modal, ModalBody, ModalHeader, Row,
} from "reactstrap";

const SwapGroupsModal = ({ show, toggle, groups, swapType, defaultGroup }) => {
  const { t } = useTranslation();
  // const normalizedDefaultGroup = defaultGroup?.replace(/\\/g, "/");
  // const filteredGroups = groups?.filter(
  //   (group) => group.replace(/\\/g, "/") !== normalizedDefaultGroup
  // );

  return (
    <Modal isOpen={show} toggle={toggle} centered={true} scrollable={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Groups")}
      </ModalHeader>
      <ModalBody>
        <Row className="mb-3">
          <Col>
            <Table className="table table-hover text-center">
              <Thead>
                <Tr>
                  <Th>{t(swapType)}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {/* {defaultGroup && (
                  <Tr>
                    <Td>{`${defaultGroup} (Default)`}</Td>
                  </Tr>
                )} */}
                {/* {filteredGroups?.length > 0 && (
                  filteredGroups?.map((group, index) => (
                    <Tr key={index}>
                      <Td>{`${group}${swapType === "SWAP FREE" && index === 0 ? " (Default)" : ""}`}</Td>

                    </Tr>
                  ))
                )}
                {filteredGroups?.length === 0 && swapType === "SWAP FREE" && (
                  <h5 className="fw-bolder text-center">No groups</h5>
                )} */}
                {groups?.length > 0 ? (
                  groups?.map((group, index) => (
                    <Tr key={index}>
                      <Td>{`${group}${index === 0 ? " (Default)" : ""}`}</Td>

                    </Tr>
                  ))
                ) : (
                  <h5 className="fw-bolder text-center">No groups</h5>
                ) }
              </Tbody>
            </Table>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default SwapGroupsModal;
