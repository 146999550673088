import React, { 
  useEffect, useRef, useState 
} from "react";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { 
  Card, CardBody,
} from "reactstrap";
import { fetchTeams } from "store/teams/actions";
import Item from "./Item";
import { fetchTeamsHierarchy } from "store/users/actions";
import { fetchClientsStart } from "store/client/actions";
import { fetchLeadsStart } from "store/leads/actions";

function Broker({ isLead, toggleColumn, loadCustomers }) {
  const dropdownRef = useRef(null);
  const teamTree = useSelector((state) => state.usersReducer?.teamTree);
  console.log("teamTree", teamTree);
  const [expanded, setExpanded] = useState({});
  const toggleNode = (nodeId) => {
    setExpanded((prevState) => ({
      ...prevState,
      [nodeId]: !prevState[nodeId],
    }));
  };

  const [selectedTeam, setSelectedTeam] = useState();
  const [memberIds, setMemberIds] = useState([]);
  const [managerId, setManagerId] = useState();
  const [filterAgents, setFilterAgents] = useState();
  // const memberIds = selectedTeam?.members?.map(member => member._id) || [];
  // const managerId = selectedTeam?.managerId;
  // const filterAgents = [...memberIds, managerId].map((id) => id).join(",");
  const [filter, setFilter] = useState(null);

  const [sizePerPage, setSizePerPage] = useState(10);
  const [SearchInputValue, setSearchInputValue] = useState("");
  const [currentPage, setcurrentPagePage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    loadTeams(currentPage, sizePerPage);
  }, [
    sizePerPage,
  ]);

  useEffect(() => {
    setMemberIds(selectedTeam?.members?.map(member => member._id) || []);
    setManagerId(selectedTeam?.managerId);
  }, [selectedTeam]);

  useEffect(() => {
    setFilterAgents([...memberIds, managerId].map((id) => id).join(","));
  }, [memberIds, managerId]);

  useEffect(() => {
    dispatch(fetchTeamsHierarchy());
  }, []);

  useEffect(() => {
    setFilter(selectedTeam?._id);
    // if (selectedTeam?.role === "Manager") {
    //   setFilter(selectedTeam._id);
    // } else {
    //   console.log("dsaaaaaaaaa1111111111111111111", filterAgents?._id, managerId);
    //   setFilter(filterAgents?._id);
    // }
  }, [selectedTeam]);
  
  useEffect(() => {
    const filterValues = {
      "agent": filter
    };
    loadCustomers(1, sizePerPage, filterValues);
    // if (isLead) {
    //   dispatch(fetchLeadsStart({
    //     "filteredValues[agent]": filter
    //   }));
    // } else {
    //   dispatch(fetchClientsStart({
    //     "filteredValues[agent]": filter
    //   }));
    // }
  }, [filter]);

  const loadTeams = (page, limit) => {
    setcurrentPagePage(page);
    if (SearchInputValue !== "") {
      dispatch(
        fetchTeams({
          page,
          limit,
          searchText: SearchInputValue,
        })
      );
    } else {
      dispatch(
        fetchTeams({
          page,
          limit,
        })
      );
    }
  };

  useEffect(() => {
    // Automatically trigger the dropdown to open on initial render
    if (dropdownRef.current) {
      const dropdownContainer =
        dropdownRef.current.querySelector(".dropdown-trigger");
      if (dropdownContainer) {
        dropdownContainer.click();
      }
    }
  }, []);
  const [searchQuery, setSearchQuery] = useState("");

  const filterRegions = (regions, query, closeExpand = false) => {
    if (!query && !closeExpand) {
      return regions.map((region) => ({
        ...region,
        isExpanded: false,
        teams: region?.teams?.map((team) => ({
          ...team,
          isExpanded: false,
          subTeams: team?.subTeams
            ? team?.subTeams?.map((subTeam) => ({
              ...subTeam,
              isExpanded: false,
            }))
            : [],
          members: team?.members
            ? team?.members?.map((member) => ({
              ...member,
              isExpanded: false,
            }))
            : [],
        })),
      }));
    }
    return regions
      ?.map((region) => {
        const filteredTeams = region?.teams ? filterTeams(region?.teams, query) : [];
 
        const isMatch = region?.region.toLowerCase().includes(query?.toLowerCase());
        const hasMatchingChildren = filteredTeams.length > 0;
        const isExpanded = closeExpand ? false : (isMatch || hasMatchingChildren);
 
        if (isMatch || hasMatchingChildren) {
          return {
            ...region,
            isExpanded,
            teams: isMatch ? region?.teams : filteredTeams,
          };
        }
 
        return null;
      })
      .filter(Boolean);
  };

  const filterTeams = (teams, query) => {
    return teams
      ?.map((team) => {
        const isMatch = team?.title?.toLowerCase().includes(query?.toLowerCase());
        const filteredSubTeams = team?.subTeams ? filterTeams(team?.subTeams, query) : [];
        const filteredMembers = team?.members
          ? team.members.filter((member) => {
            const fullName = `${member?.firstName} ${member?.lastName}`.toLowerCase();
            return fullName.includes(query.toLowerCase());
          })
          : [];
  

        const filteredManager = team?.managerId
          ? `${team.managerId.firstName} ${team.managerId.lastName}`.toLowerCase().includes(query.toLowerCase())
            ? { ...team.managerId }
            : null
          : null;
  
        const hasMatchingChildren = filteredSubTeams.length || filteredMembers.length || filteredManager;
        const isExpanded = isMatch || hasMatchingChildren;
  
        if (isMatch || hasMatchingChildren) {
          return {
            ...team,
            isExpanded,
            subTeams: filteredSubTeams,
            members: filteredMembers,
            managerId: filteredManager,  
          };
        }
        return null;
      })
      .filter(Boolean);
  };
  
  
  const loadAllCustomers = () => {
    loadCustomers(1, sizePerPage);
    document.querySelectorAll("li.set-color").forEach((el) => el.classList.remove("set-color"));
    filterRegions(teamTree, searchQuery, true);
  };

  const filteredRegions = filterRegions(teamTree, searchQuery);
  const renderSubMenu = (subMenu, region, parentId) => {
    const uniqueSubMenu = subMenu.filter((item, index, self) =>
      self.findIndex((i) => i?.title === item?.title) === index
    );
  
    return (
      <ul style={{
        listStyle: "none",
        padding: "2px 5px" 
      }}>
        {uniqueSubMenu.map((subItem, index) => {
          const { managerId, members = [] } = subItem;
  
          const managerMember = managerId
            ? {
              _id: managerId._id,
              firstName: managerId.firstName || "",
              lastName: managerId.lastName || "",
              email: managerId.email || "",
              role: "Manager",
              isManager: true, 
            }
            : null;
  
         
          subItem.subTeams = subItem?.subTeams?.filter(subTeam => subTeam);
          subItem.members = members.filter(member => member);
          subItem.subIbs = subItem?.subIbs?.filter(subIb => subIb);
          
          const hasChildren =
            !!subItem?.subTeams?.length || !!members.length || !!subItem?.subIbs?.length || managerMember;
  
          return (
            (subItem?.parentTeamId === parentId || !subItem?.parentTeamId) &&
              <li key={index} style={{ cursor: "pointer" }}>
                <Item
                  label={subItem?.title}
                  subItem={subItem}
                  hasChildren={hasChildren}
                  expanded={subItem?.isExpanded}
                  setSelectedTeam={setSelectedTeam}
                  regionTitle={region}
                >
          
                  {managerMember && (
                    <ul style={{
                      listStyle: "none",
                      padding: 0 
                    }}>
                      <li style={{ cursor: "pointer" }}>
                        <Item
                          label={`Manager: ${managerMember.firstName} ${managerMember.lastName}`}
                          subItem={managerMember}
                          member={true}
                          managerMember={true}
                          hasChildren={false}
                          setSelectedTeam={setSelectedTeam}
                          regionTitle={region}
                        />
                      </li>
                    </ul>
                  )}
                  <ul className="padding-none" style={{ listStyle: "none" }}>
                    {members?.filter((memberItem)=> String(managerMember?._id) !== String(memberItem?._id)).map((member, memberIndex) => (
                      <li key={member._id || memberIndex} style={{ cursor: "pointer" }}>
                        <Item
                          label={`${member.firstName} ${member.lastName}`}
                          subItem={member}
                          member={true}
                          hasChildren={false}
                          setSelectedTeam={setSelectedTeam}
                          regionTitle={region}
                        />
                      </li>
                    ))}
                  </ul>
    
                  {subItem?.subTeams && renderSubMenu(subItem?.subTeams, region, subItem?._id)}
                  {/* {subItem?.subIbs && renderSubMenu(subItem?.subIbs)} */}
                </Item>
              </li>
          );
        })}
      </ul>
    );
  };
  

  const renderRegions = (regions) => {
    return (
      <ul
        className="padding-none" 
        style={{
          listStyle: "none",
          padding: "20px 20px",
        }}
      >
        {regions.map((region, index) => {
          return (
            <li key={index} id={region.region}  style={{ cursor: !!region?.teams?.length ? "pointer" : "default" }}>
              <Item
                region={true}
                label={region.region}
                subItem={region}
                hasChildren={!!region?.teams?.length}
                expanded={region.isExpanded}
                firstNest={true}
                regionTitle={region.region}
              >
                {region.teams && renderSubMenu(region.teams, region.region)}
              </Item>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <React.Fragment>
      <Card className="h-100">
        <CardBody className="text-end">
          <i onClick={toggleColumn} className="mdi mdi-arrow-left cursor-pointer font-size-20"></i>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowX: "auto",
              fontSize: "12px",
            }}
          >
            <div className="app-search d-flex align-items-center">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search"
                />
                <button className="btn btn-primary" type="button">
                  <i className="bx bx-search-alt align-middle"></i>
                </button>
              </div>
              <FeatherIcon
                icon="refresh-cw"
                className="icon-lg ms-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  loadAllCustomers();
                }}
              />
            </div>
            {renderRegions(filteredRegions)}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
export default Broker;
