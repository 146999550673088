import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import CustomSelect from "./CustomSelect";
import { fetchAgreement, fetchPromoStart } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { fetchRebateRules } from "store/rebateSettings/rebateRules/actions";
import SearchableRebateRulesDropdown from "pages/RebateSettings/rebateRules/SearchableComponents/SearchableRebateRulesDropdown";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Col, Row } from "reactstrap";
import { set } from "store";

export default function PromoLinkDropDownOpenLink({
  setPromoLinkData,
  filteredPromo = [],
  selectedClientDetails = {},
  isOpenLink = true,
}) {

  const dispatch = useDispatch();
  const [selectedPromoLink, setSelectedPromoLink] = useState([]);
  const promoLinks = useSelector((state) => state?.Promo?.promos?.docs);
  const rebateRules = useSelector(
    (state) => state.RebateRulesReducer.rebateRules
  );
  const [selectedRebates, setSelectedRebates] = useState({});
  const [options, setOptions] = useState([]);
  const [filteredRebateRules, setFilteredRebateRules] = useState([]);
  const clientsDetails = useSelector(
    (state) => state.clientReducer?.clientDetails
  );
  const [clientPromoLink, setClientPromoLink] = useState(null);
  const [removeTempPromo, setRemoveTempPromo] = useState(false);
  const [rebateDefaultOptions, setRebateDefaultOptions] = useState([]);
  const [alreadyAddedRebates, setAlreadyAddedRebates] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState("");
  const { products, agreements, agreement, deleting, clearingCounter } =
    useSelector((state) => state.ibAgreements);

  useEffect(() => {
    if (clientsDetails?.promoLink?.length > 0 && Object.keys(selectedClientDetails)?.length > 0) {
      setClientPromoLink(clientsDetails?.promoLink?.[0]);
    } else {
      setClientPromoLink(null);
    }
  }, [clientsDetails]);

  // function getAlreadyAddedRebates(selectedClientDetails) {
  //   const alreadyAddedRebatesData = [];
  //   Object.entries(selectedClientDetails?.rebateRef || {})?.forEach((re) => {
  //     re = JSON.parse(re?.[1]);
  //     re?.forEach((rr) => {
  //       if (
  //         !alreadyAddedRebatesData?.find(
  //           (r) => String(r?.rebateId) === String(rr?._id)
  //         )
  //       ) {
  //         alreadyAddedRebatesData?.push({
  //           rebateId: rr?.rebateId,
  //           rebateValue: rr?.rebateValue,
  //         });
  //       }
  //     });
  //   });
  //   return alreadyAddedRebatesData;
  // }

  function getAlreadyAddedRebates(selectedClientDetails) {
    const alreadyAddedRebatesData = [];
    Object.entries(selectedClientDetails?.rebates || {})?.forEach(([promotionLink, rebate]) => {
      Object.entries(rebate)?.forEach(([rebateId, rebateItem]) => {
        if (!alreadyAddedRebatesData?.find((r) => String(r?.rebateId) === String(rebateId))) {
          alreadyAddedRebatesData?.push({
            rebateId,
            rebateValue: rebateItem?.value,
          });
        }
      });
    });
    return alreadyAddedRebatesData;
  }


  function filterRebates() {
    let modifiedRebates = [...rebateRules];
    let filteredRebateRulesData = modifiedRebates?.filter((item) => {
      let isExisted = false;
      item?.mtGroupId?.forEach((mtGroup) => {
        selectedPromoLink?.forEach((promoLink) => {
          if (
            promoLink?.mtGroups?.find(
              (group) => String(group?._id) === String(mtGroup?._id)
            )) {
            isExisted = true;
          }
        });
      });
      // return item.accountTypeId?._id === selectedPromoLink.accountType?._id && item.status === true;
      return isExisted && item.status === true;
    });
    if (!selectedClientDetails?.parentId) {
      let alreadyAddedRebates = selectedClientDetails
        ? getAlreadyAddedRebates(selectedClientDetails)
        : {};

      filteredRebateRulesData = filteredRebateRulesData?.map((rule) => {
        const prevRule = alreadyAddedRebates?.find(
          (r) => String(r?.rebateId) === String(rule?._id)
        );
        if (prevRule) {
          return {
            ...rule,
            isAlreadyAdded: true,
            rebateValue: prevRule?.rebateValue,
          };
        }
        return rule;
      });
    } else {
      // here we need to handle agreement for multiple Promo Shit
      const agrementParentMemberValues = agreement?.members?.find(
        (member) =>
          String(member?.customerId) ===
          String(selectedClientDetails?.parentId?._id)
      )?.values;
      filteredRebateRulesData = filteredRebateRulesData?.map((rule) => {
        const prevAgrememntValue = agrementParentMemberValues?.find(
          (value) =>
            String(value?.accountTypeId?._id) ===
          String(rule?.accountTypeId?._id)
        );
        
        console.log("agrementParentMemberValues", agrementParentMemberValues, prevAgrememntValue);
        if (prevAgrememntValue) {
          // const matchedProduct =
          //   prevAgrememntValue?.products?.[rule?.symbolGroupId?.groupName?.toLowerCase()]
          //     ?.rebate;
          const matchedRebate = prevAgrememntValue?.rebates?.[`${rule?._id}`];
          // const promotionOptionalRebateValue = Array.from(
          //   { length: matchedProduct },
          //   (_, i) => String(i)
          // )?.reverse();
          // if (matchedProduct) {
          //   return {
          //     ...rule,
          //     isParentHasIt: true,
          //     promotionOptionalRebateValue,
          //     rebateValue: matchedProduct?.rebate,
          //   };
          // } else {
          //   return {
          //     ...rule,
          //     isParentHasIt: false,
          //     promotionOptionalRebateValue: ["0"],
          //     rebateValue: 0,
          //   };
          // }
          // const promotionOptionalRebateValue = matchedProduct
          //   ? Array.from(
          //     { length: matchedProduct + 1 },
          //     (_, i) => String(i)
          //   )?.reverse()
          //   : ["0"];

          // return {
          //   ...rule,
          //   isParentHasIt: !!matchedProduct,
          //   promotionOptionalRebateValue,
          //   rebateValue: matchedProduct?.rebate || 0,
          // };
          const promotionOptionalRebateValue = matchedRebate?.value
            ? Array.from(
              { length: matchedRebate?.value + 1 },
              (_, i) => String(i)
            )?.reverse()
            : ["0"];

          return {
            ...rule,
            isParentHasIt: !!matchedRebate,
            promotionOptionalRebateValue,
            rebateValue: matchedRebate?.value || 0,
          };
        }
        return {
          ...rule,
          isParentHasIt: false,
          promotionOptionalRebateValue: ["0"],
          rebateValue: 0,
        };
      });
    }
    console.log("filteredRebateRulesData", filteredRebateRulesData);
    setFilteredRebateRules(filteredRebateRulesData);
  }

  useEffect(() => {
    if (selectedClientDetails) {
      // setSelectedPromoLink(null);
      setSelectedRebates([]);
      setRemoveTempPromo(true);
      setTimeout(() => {
        setRemoveTempPromo(false);
      }, 0);
    }
  }, [selectedClientDetails?._id]);

  useEffect(() => {
    if (selectedClientDetails?.parentId) {
      dispatch(
        fetchAgreement({
          agrementId: selectedClientDetails?.parentId?.fx?.agrementId,
        })
      );
    }
  }, [selectedClientDetails]);

  useEffect(() => {
    filterRebates();
  }, [selectedPromoLink, agreement]);

  useEffect(() => {
    dispatch(
      fetchPromoStart({
        page: 1,
        limit: 100000,
      })
    );
    dispatch(fetchRebateRules({
      limit: 100000000,
      page: 1,
    }));
  }, []);

  useEffect(() => {    
    if (Object.keys(selectedClientDetails)?.length > 0 || !isOpenLink) {
      const optionData = promoLinks
        ?.filter(
          (promo) =>
            !filteredPromo?.map((c) => String(c))?.includes(String(promo?._id))
        )
        ?.filter((link) => !clientPromoLink || link._id === clientPromoLink?._id)
        ?.map((link) => ({
          label: link.title,
          value: link,
        }));
      setOptions(optionData);
    }
    
  }, [promoLinks, selectedClientDetails]);

  useEffect(() => {
    // setClientPromoLink(null);
  }, []);
  
  const { t } = useTranslation();

  const handleChange = (e) => {
    const selectedPromoLinks = e?.map((item) => item?.value);
    setSelectedPromoLink(selectedPromoLinks);
    
    const modifiedRebates = { ...selectedRebates };
    const deletedRebates = { ...selectedRebates };
    selectedPromoLinks?.forEach((promo) => {
      // modifiedRebates[`${promo?._id}`] = [
      //   ...modifiedRebates?.[`${promo?._id}`]
      // ];
      delete deletedRebates[`${promo?._id}`];
    });
    Object.keys(deletedRebates)?.forEach((rebate) => {
      delete modifiedRebates[`${rebate}`];
    });
    setSelectedRebates(modifiedRebates);
  };

  const handleRebateChange = (e, rebate, promoLink) => {
    const { _id, promotionOptionalRebateValue } = rebate;
    // const promotionOptionalRebateValueData = e.map((item) => parseInt(item.value));
    const selectedValue = parseFloat(e?.value) || 0;
    const rebateItem = {
      rebateId: _id,
      rebateValue: selectedValue,
      rebateName: rebate?.name,
      minRebateValue: Math.min(...(rebate?.tier1RebateRules || 0)),
      maxRebateValue: Math.max(...(rebate?.tier1RebateRules || 0)),
    };
    let modifiedRebates = { ...selectedRebates };
    console.log("modifiedRebates", modifiedRebates, promoLink);
    modifiedRebates[`${promoLink}`] = modifiedRebates?.[`${promoLink}`]?.filter((rebate) => String(rebate?.rebateId) !== String(_id)) || [];
    if (!modifiedRebates[`${promoLink}`]) {
      modifiedRebates[`${promoLink}`] = [];
    }
    modifiedRebates[`${promoLink}`] = [
      ...modifiedRebates[`${promoLink}`],
      rebateItem,
    ];
    // modifiedRebates?.push();
    setSelectedRebates(modifiedRebates);
    // let modifiedRebates = [...selectedRebates];
    // modifiedRebates = modifiedRebates.filter((rebate) => String(rebate?.rebateId) !== String(_id));
    // modifiedRebates?.push({
    //   rebateId: _id,
    //   rebateValue: selectedValue,
    //   rebateName: rebate?.name,
    //   minRebateValue: Math.min(...(rebate?.tier1RebateRules || 0)),
    //   maxRebateValue: Math.max(...(rebate?.tier1RebateRules || 0)),
    // });
    // setSelectedRebates(modifiedRebates);
    // setSelectedRebates((prevState) => {
    //   return [
    //     ...prevState,
    //     {
    //       rebateId: _id,
    //       rebateValue: selectedValue,
    //       rebateName: rebate?.name,
    //       minRebateValue: Math.min(...(rebate?.tier1RebateRules || 0)),
    //       maxRebateValue: Math.max(...(rebate?.tier1RebateRules || 0)),
    //     },
    //   ];
    // });
  };

  // useEffect(() => {
  //   setPromoLinkData({
  //     promotionLink: selectedPromoLink?._id,
  //     rebateRef: JSON.stringify(selectedRebates),
  //   });
  // }, [selectedPromoLink, selectedRebates]);

  useEffect(() => {
    // const modifiedRebates = { ...selectedRebates };
    // modifiedRebates[`${selectedPromoLink}`] = {
    //   ...modifiedRebates[`${selectedPromoLink}`],
    //   selectedRebates
    // };
    console.log("selectedRebatesdasdsdsa", selectedPromoLink, selectedRebates);
    setPromoLinkData({
      promotionLinks: selectedPromoLink?.map(promo => promo?._id) || [],
      rebateRef: selectedRebates,
    });
  }, [selectedPromoLink, selectedRebates]);

  const renderRebateField = (rebate, promoLink) => {
    if (rebate?.isAlreadyAdded) {
      return (
        <AvForm
          onValidSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <AvField
            name="promotionOptionalRebateValue"
            type="text"
            label={rebate.name}
            disabled={true}
            errorMessage="Rebate is required"
            value={rebate && rebate?.rebateValue}
            validate={{
              required: { value: true },
            }}
          />
        </AvForm>
      );
    } else {
      return (
        <SearchableRebateRulesDropdown
          name="promotionOptionalRebateValue"
          type="text"
          errorMessage="Rebate is required"
          validate={{ required: { value: true } }}
          label={rebate?.name}
          isPromote={true}
          defaultOptions={
            rebate?.promotionOptionalRebateValue?.length > 0 && !(rebate?.promotionOptionalRebateValue.length === 1 && rebate?.promotionOptionalRebateValue[0] === "0")
              ? [
                ...rebate.promotionOptionalRebateValue.map((option) => ({
                  label: option,
                  value: option,
                })),
                ...[0.90, 0.80, 0.70, 0.60, 0.50, 0.40, 0.30, 0.20, 0.10, 0.00].map((value) => ({
                  label: value.toFixed(2),
                  value: value,
                })),
              ]
              : [
                {
                  label: "0",
                  value: 0,
                },
              ]
          }
          isMulti={false}
          onChange={(e) => handleRebateChange(e, rebate, promoLink)}
        /> 
        
      );
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="2">
          <label
            htmlFor="choices-single-default"
            className="form-label font-size-14 mt-2"
            style={{ margin: 0 }}
          >
            {t("Promotion Link")}
          </label>
          <span style={{ color: "red" }}> * </span>
        </Col>
        <Col md="4">
          {!removeTempPromo && (
            <div
              style={{
                minWidth: "200px",
              }}
            >
              <CustomSelect
                classNamePrefix="select2-selection"
                placeholder="Select Promotion Links"
                options={options}
                onChange={handleChange}
                isMulti={true}
                id="choices-single-default"
              />
            </div>
          )}
        </Col>
        {
          isOpenLink && 
            <Col md="6">
              <h5 className="mt-1">
                The rebate can not be setup more than the upper IB
              </h5>
            </Col>
        }
      </Row>
      {/* {filteredRebateRules.map((rebate, index) => {
        const isEven = index % 2 === 0;
        return isEven ? (
          <Row key={`row-${index}`} className="my-3">
            <Col md="6">{renderRebateField(rebate)}</Col>
            {filteredRebateRules[index + 1] && (
              <Col md="6">
                {renderRebateField(filteredRebateRules[index + 1])}
              </Col>
            )}
          </Row>
        ) : null;
      })} */}
      {selectedPromoLink?.length > 0 && (
        <div className="mt-3">
          {selectedPromoLink?.map((promo, promoIndex) => {
            const promoRebateRules = filteredRebateRules.filter(rule => {
              let hasMatchingGroup = false;
              rule?.mtGroupId?.forEach((mtGroup) => {
                if (promo?.mtGroups?.find(
                  (group) => String(group?._id) === String(mtGroup?._id)
                )) {
                  hasMatchingGroup = true;
                }
              });
              return hasMatchingGroup;
            });

            return (
              <div key={promoIndex} className="mb-4">
                <h5 className="font-size-14">{promo.title}</h5>
                {promoRebateRules?.map((rebate, index) => {
                  const isEven = index % 2 === 0;
                  return isEven ? (
                    <Row key={`${promoIndex}-row-${index}`} className="my-3">
                      <Col md="6">{renderRebateField(rebate, promo?._id)}</Col>
                      {promoRebateRules?.[index + 1] && (
                        <Col md="6">
                          {renderRebateField(promoRebateRules?.[index + 1], promo?._id)}
                        </Col>
                      )}
                    </Row>
                  ) : null;
                })}
                {promoRebateRules.length === 0 && (
                  <p className="text-muted">No rebate rules available for this promotion link</p>
                )}
              </div>
            );
          })}
        </div>
      )}
      {/* {selectedPromoLink?.length > 0 && (
        <div className="mt-3">
          {selectedPromoLink.map((promo, index) => (
            <div key={index} className="mb-2">
              {filteredRebateRules.filter(rule => {
                let hasMatchingGroup = false;
                rule?.mtGroupId?.forEach((mtGroup) => {
                  if (promo?.mtGroups?.find(
                    (group) => String(group?._id) === String(mtGroup?._id)
                  )) {
                    hasMatchingGroup = true;
                  }
                });
                return hasMatchingGroup;
              }).length === 0 && (
                <>
                  <h5 className="font-size-14">{promo.title}</h5>
                  <p className="text-muted">No rebate rules available for this promotion link</p>
                </>
              )}
            </div>
          ))}
        </div>
      )} */}
    </React.Fragment>
  );
}
