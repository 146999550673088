
import {
  FETCH_REGIONS_START,
  FETCH_REGIONS_SUCCESS,
  FETCH_REGIONS_ERROR,
  ADD_REGIONS_START,
  ADD_REGIONS_SUCCESS,
  ADD_REGIONS_ERROR,
  ADD_REGION_CLEAR,
  EDIT_REGIONS_START,
  EDIT_REGIONS_DONE,
  EDIT_REGION_CLEAR,
  DELETE_REGIONS_START,
  DELETE_REGIONS_DONE,
  CHANGE_STATUS_REGIONS_START,
  CHANGE_STATUS_REGIONS_END
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  roles: [],
  clearingCounter: 0,
  editClearingCounter: 0,
  deleteClearingCounter: 0,
  // totalDocs: 0,
  // docs: [],
  // page: 1
};
const regionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGIONS_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_REGIONS_SUCCESS:
      state = {
        ...state,
        docs: [...action.payload.docs],
        totalDocs: action.payload.totalDocs,
        hasNextPage: action.payload.hasNextPage,
        hasPrevPage: action.payload.hasPrevPage,
        limit: action.payload.limit,
        nextPage: action.payload.nextPage,
        page: action.payload.page,
        pagingCounter: action.payload.pagingCounter,
        prevPage: action.payload.prevPage,
        totalPages: action.payload.totalPages,
        loading: false,
      };
      break;
    case FETCH_REGIONS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      };
      break;

    case ADD_REGIONS_START:
      state = {
        ...state,
        addLoading: true,
      };
      break;
    case ADD_REGIONS_SUCCESS:
      state = {
        ...state,
        addResult: action.payload,
        docs: [action?.payload, ...state?.docs],
        addSuccess: true,
        addError: false,
        addLoading: false,
      };
      break;
    case ADD_REGIONS_ERROR:
      state = {
        ...state,
        addLoading: false,
        addErrorDetails: action.payload,
        addSuccess: false,
        addError: true,
      };
      break;
    case ADD_REGION_CLEAR:
      state = {
        ...state,
        addErrorDetails: "",
        addSuccess: false,
        addError: false,
        addResult: null,
        clearingCounter: state.clearingCounter + 1
      };
      break;

    case EDIT_REGIONS_START:
      state = {
        ...state,
        editLoading: true,
      };
      break;
    case EDIT_REGIONS_DONE:
      // eslint-disable-next-line no-case-declarations
      const { id, ...payload } = action.payload;
      state = {
        ...state,
        docs: state?.docs?.map(obj => {
          if (obj._id === id) {
            return {
              ...obj,
              title: payload.result.title,
              permissions: payload.result.permissions,
            };
          } else {
            return obj;
          }
        }),
        editLoading: false,
        editResult: action.payload.result,
        editError: action.payload.error,
      };
      break;
    case EDIT_REGION_CLEAR:
      state = {
        ...state,
        editResult: null,
        editError: null,
        editClearingCounter: state.editClearingCounter + 1
      };
      break;
    case DELETE_REGIONS_START:
      state = {
        ...state,
        deleteLoading: true,
      };
      break;
    case DELETE_REGIONS_DONE:
      state = {
        ...state,
        docs: state?.docs?.filter(obj => obj._id !== action.payload.id),
        deleteLoading: false,
        deleteResult: action.payload.result,
        deleteError: action.payload.error,
        deleteClearingCounter: state.deleteClearingCounter + 1,
      };
      break;
    case CHANGE_STATUS_REGIONS_START:
      state = {
        ...state,
        changeStatusLoading: true,
        changeStatusLoadingIndex: action.payload.index,
      };
      break;
    case CHANGE_STATUS_REGIONS_END:
      state = {
        ...state,
        docs: state?.docs?.map((obj, index) => {
          if (index === action.payload.index && !action.payload.error) {
            obj.isActive = !obj.isActive;
          }
          return obj;
        }),
        changeStatusLoading: false,
      };
      break;
    default:
      state = { ...state };

  }
  return state;
};
export default regionReducer;