import {
  call, put, takeEvery
} from "redux-saga/effects";
// Login Redux States
import {
  FETCH_REGIONS_START,
  ADD_REGIONS_START,
  EDIT_REGIONS_START,
  DELETE_REGIONS_START,
  CHANGE_STATUS_REGIONS_START
} from "./actionTypes";
import {
  addRegionError, 
  changeStatusDone,
  editRegionDone,
  editRegionClear,
  addRegionSuccess,
  addRegionClear,
  deleteRegionDone,
  fetchRegionsError,
  fetchRegionsSuccess,


} from "./actions";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";

//Include Both Helper File with needed methods
import * as regionsApi from "../../apis/regions";


function * fetchRegions(params){
 
  try {
    const data = yield call(regionsApi.getRegions, params);    
    yield put(fetchRegionsSuccess(data));
  }
  catch (error){
    yield put(fetchRegionsError(error));
  } 

  
}

function * addRegion(params){
  try {
    console.log("ADd regions");
    const data = yield call(regionsApi.addRegion, params);
    
    
    const { result } = data;
    yield put(addRegionSuccess(result));
    yield put(showSuccessNotification("Region added successfully"));
    // yield delay(2000);
    yield put(addRegionClear());
  }
  catch (error){
    yield put(addRegionError(error));
    // yield delay(2000);
    // yield put(addRoleClear());
  } 

  
}

function * editRegion(params){
  try {
    yield call(regionsApi.editRegion, params);
    
    
    // const { result } = data;
    yield put(editRegionDone({
      result: params.payload.values,
      id: params.payload.id  
    }));
    yield put(showSuccessNotification("Region updated successfully"));

    // yield delay(2000);
    yield put(editRegionClear());
  }
  catch (error){
    yield put(editRegionDone({ error: error.message }));
    // yield delay(2000);
    // yield put(editRoleClear());
  } 

  
}

function * deleteRegion(params) {
  try {
    const data = yield call(regionsApi.deleteRegion, params);
    const { result } = data;
    yield put(deleteRegionDone({
      result,
      id: params.payload 
    }));
    yield put(showSuccessNotification("Region deleted successfully"));

  }
  catch (error){
    yield put(deleteRegionDone({ error: error.message }));
    yield put(showErrorNotification(error.message));

  } 
}

function * changeStatusRegion(params) {
  try {
    const data = yield call(regionsApi.changeStatusRegion, params);
    const { result } = data;
    yield put(changeStatusDone({
      result,
      id: params.payload.id,
      index: params.payload.index,

    }));
    yield put(showSuccessNotification("Region Status Changed successfully"));

  }
  catch (error){
    yield put(changeStatusDone({
      error: error.message,
      index: params.payload.index,
    }));
    yield put(showErrorNotification(error.message));

  } 

  
}

function* authSaga() {
  yield takeEvery(FETCH_REGIONS_START, fetchRegions);
  yield takeEvery(ADD_REGIONS_START, addRegion);
  yield takeEvery(EDIT_REGIONS_START, editRegion);
  yield takeEvery(DELETE_REGIONS_START, deleteRegion);
  yield takeEvery(CHANGE_STATUS_REGIONS_START, changeStatusRegion);
}

export default authSaga;
