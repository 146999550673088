import {
  FETCH_REGIONS_START,
  FETCH_REGIONS_SUCCESS,
  FETCH_REGIONS_ERROR,
  ADD_REGIONS_START,
  ADD_REGIONS_SUCCESS,
  ADD_REGIONS_ERROR,
  ADD_REGION_CLEAR,

  EDIT_REGIONS_START,
  EDIT_REGIONS_DONE,
  EDIT_REGION_CLEAR,

  DELETE_REGIONS_START,
  DELETE_REGIONS_DONE,

  CHANGE_STATUS_REGIONS_START,
  CHANGE_STATUS_REGIONS_END
} from "./actionTypes";

export const fetchRegions = (params = {})=>{
  return {
    type:FETCH_REGIONS_START,
    payload: params
  };
};
export const fetchRegionsSuccess = (data)=>{
  return {
    type:FETCH_REGIONS_SUCCESS,
    payload: data
  };
};
export const fetchRegionsError = (error)=>{
  return {
    type:FETCH_REGIONS_ERROR,
    payload:{ error }
  };
};


export const addRegion = (params = {})=>{
  return {
    type:ADD_REGIONS_START,
    payload: params
  };
};
export const addRegionSuccess = (data)=>{
  return {
    type:ADD_REGIONS_SUCCESS,
    payload: data
  };
};
export const addRegionError = (error)=>{
  return {
    type:ADD_REGIONS_ERROR,
    payload: error && error.message || "",
  };
};
export const addRegionClear = (data)=>{
  return {
    type:ADD_REGION_CLEAR,
    payload: data
  };
};

export const editRegion = (params = {})=>{
  return {
    type:EDIT_REGIONS_START,
    payload: params
  };
};
export const editRegionDone = (data)=>{
  return {
    type:EDIT_REGIONS_DONE,
    payload: data
  };
};
export const editRegionClear = (data)=>{
  return {
    type:EDIT_REGION_CLEAR,
    payload: data
  };
};

export const deleteRegions = (params = {})=>{
  return {
    type:DELETE_REGIONS_START,
    payload: params
  };
};
export const deleteRegionDone = (data)=>{
  return {
    type:DELETE_REGIONS_DONE,
    payload: data
  };
};
export const changeStatus = (id, status, index)=>{
  return {
    type: CHANGE_STATUS_REGIONS_START,
    payload: {
      id, 
      status,
      index,
    },
  };
};

export const changeStatusDone = (params = {})=>{
  return {
    type:CHANGE_STATUS_REGIONS_END,
    payload: params
  };
};