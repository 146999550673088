import {
  call,
  put,
  takeEvery,
  delay
} from "redux-saga/effects";
import { 
  addWithdrawal, 
  getWithdrawals, 
  approveWithdrawal, 
  rejectWithdrawal,
  getClientWithdrawals,
  getUserTransactions,
  onHoldWithdrawal,
  returnWithdrawal,
  updateUserWithdrawal
} from "apis/withdrawal";
import { 
  MAKE_WITHDRAWAL_START, 
  FETCH_WITHDRAWALS_START, 
  WITHDRAW_APPROVE, 
  WITHDRAW_REJECT,
  FETCH_CLIENT_WITHDRAWALS_REQUESTED,
  FETCH_USER_TRANSACTION_START,
  WITHDRAW_ON_HOLD,
  WITHDRAW_RETURN,
  USER_WITHDRAWAL_UPDATE
} from "./actionTypes";
import { 
  makeWithdrawalSuccess, 
  fetchWithdrawalsSuccess, 
  withdrawalError, 
  modalClear, 
  withdrawStatusChangeSuccess,
  withdrawStateChangeFail,

  fetchClientWithdrawalsSuccess,
  fetchClientWithdrawalsFail, 
  withdrawalErrorClear,
  fetchUserTransactionSuccess
} from "./action";
import { showSuccessNotification, showErrorNotification } from "store/notifications/actions";

function *fetchWithdrawals(params){
  try {
    const data = yield call(getWithdrawals, params);
    yield put(fetchWithdrawalsSuccess(data));

  } catch {
    yield put(withdrawalError("Error happened while fetching withdrawals"));
  }
  
}

function * fetchUserTransaction(params){
  try {
    const data = yield call(getUserTransactions, params);
    yield put(fetchUserTransactionSuccess(data));

  } catch {
    yield put(withdrawalError("Error happened while fetching User Transactions"));
  }
  
}
function * makeWithdrawal({ payload:{ withdrawal } }){
  try {
    const { revokedCommissionId, ...withdrawalWithoutRevokedId } = withdrawal;

    const data = yield call(addWithdrawal, withdrawalWithoutRevokedId);
    const { result } = data;

    if (withdrawal.gateway === "REFUND") {
      yield call(updateUserWithdrawal, {
        id: revokedCommissionId,
        revoked: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    yield put(makeWithdrawalSuccess(result));
    yield put(modalClear());
    yield put(showSuccessNotification("Withdrawal has been added successfuly"));
  } catch (error){
    yield put(showErrorNotification(error.message));
    yield put(withdrawalError(error.message));
    yield delay(5000);
    yield put(withdrawalErrorClear());
  }
}
function * WithdrawReject ({ payload }){
  try {
    const data = yield call(rejectWithdrawal, payload);
    const { result } = data;
    yield put(withdrawStatusChangeSuccess(result));
    yield put(showSuccessNotification("Withdrawal has been rejected successfuly"));
  } catch (error){
    yield put(withdrawStateChangeFail(error.message));
    yield put(showErrorNotification(error.message));
  }
}
function * withdrawApprove({ payload }){
  try {
    const data = yield call(approveWithdrawal, payload);
    const { result } = data;
    yield put(withdrawStatusChangeSuccess(result));
    yield put(showSuccessNotification("Withdrawal has been approved successfuly"));
  } catch (error){
    yield put(withdrawStateChangeFail(error.message));
    yield put(showErrorNotification(error.message));
  }
}
function * withdrawalOnHold({ payload }){
  try {
    const data = yield call(onHoldWithdrawal, payload);
    const { result } = data;
    yield put(withdrawStatusChangeSuccess(result));
    yield put(showSuccessNotification("Withdrawal has been On Hold successfuly"));
  } catch (error){
    yield put(withdrawStateChangeFail(error.message));
    yield put(showErrorNotification(error.message));
  }
}

function * withdrawalReturn({ payload }){
  try {
    const data = yield call(returnWithdrawal, payload);
    const { result } = data;
    yield put(withdrawStatusChangeSuccess(result));
    yield put(showSuccessNotification("Withdrawal has been Return successfuly"));
  } catch (error){
    yield put(withdrawStateChangeFail(error.message));
    yield put(showErrorNotification(error.message));
  }
}

function * fetchClientWithdrawals(params){
  try {
    const data = yield call(getClientWithdrawals, params);
    yield put(fetchClientWithdrawalsSuccess(data));
  } catch (error){ 
    yield put(fetchClientWithdrawalsFail({ error: error.message }));
  }
}

function * withdrawalUpdate({ payload }){
  try {
    yield call(updateUserWithdrawal, payload);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error){
    console.log(error);
  }
}

function * withdrawalSaga(){
  yield takeEvery(FETCH_WITHDRAWALS_START, fetchWithdrawals);
  yield takeEvery(FETCH_USER_TRANSACTION_START, fetchUserTransaction);
  yield takeEvery(MAKE_WITHDRAWAL_START, makeWithdrawal);
  yield takeEvery(WITHDRAW_APPROVE, withdrawApprove);
  yield takeEvery( WITHDRAW_REJECT, WithdrawReject);
  yield takeEvery( USER_WITHDRAWAL_UPDATE, withdrawalUpdate);
  yield takeEvery( WITHDRAW_ON_HOLD, withdrawalOnHold);
  yield takeEvery( WITHDRAW_RETURN, withdrawalReturn);
  yield takeEvery( FETCH_CLIENT_WITHDRAWALS_REQUESTED, fetchClientWithdrawals);
}
export default withdrawalSaga;