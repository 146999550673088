// IB_LEVELS is a mapping of IB levels to their corresponding values in the database
const IB_LEVELS = {
  "DIRECT CLIENT": 0,
  "MIB":1,
  "SIB1": 2,
  "SIB2": 3,
  "SIB3": 4,
  "SIB4": 5,
  "SIB5": 6,
  "SIB6": 7,
  "SIB7": 8,
  "SIB8": 9,
  "SIB9": 10,
  "SIB10": 11,
};

// IB_LEVELS_OPTIONS are options for the IB level filter
const IB_LEVELS_OPTIONS = Object.keys(IB_LEVELS).map((key) => ({
  label: key,
  value: IB_LEVELS[key],
}));

export { IB_LEVELS, IB_LEVELS_OPTIONS };
