import React, { useEffect, useState } from "react";
import { 
  Row, 
  Col, 
  Button, 
  Label
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { updateAgreement } from "store/actions";
import { useDispatch } from "react-redux";

import * as ibAgreementsApi from "apis/ib-agreements";
import CustomSelect from "components/Common/CustomSelect";
import SearchableClientIbSelectTransfer from "components/Common/SearchableClientIbSelectTransfer";
import SearchableAgentDropdown from "components/Common/SearchableAgentDropdown";
import generateArrayFromNum from "common/utils/generateArrayFromNum";
import SearchableRebateRulesDropdown from "pages/RebateSettings/rebateRules/SearchableComponents/SearchableRebateRulesDropdown";
import SearchableMTGroupsEditDropdown from "pages/RebateSettings/rebateRules/SearchableComponents/SearchableMTGroupsEditDropdown";
import { transferIbStart } from "store/client/actions";
function MTRebate({
  ibAggrementValuesLevel,
  ibAggrementValues,
  rebates,
  noOfLevels,
  notAddedRebate,
  agentType,
  selectedTier,
  selectedClientId,
  allProducts,
  handleChangeRebateValue,
  handleChangeAggrementValue,
  handleChangeAccountTypeProductsWithType,
  handleChangeNotAddedRebates,
  handleChangeProduct,
  accountTypeProductsWithType,
  // accountTypeProducts,
  type,
  aggrementValues,
  allRebateRules,
  productsValues,
  clientProductsValues,
  setClientProductsValues,
  clientDetails,
  toggle,
  t,
}) {
  const [isUpdate, setIsUpdate] = useState(true);
  const dispatch = useDispatch();
  const handleChangeAmount =
    (maxAmount, product) => (value, ctx, input, cb) => {
      delete input.validations?.min;
      delete input.validations?.max;
      const enteredAmount = parseFloat(value);
      const maxAllowed = maxAmount;
      const minAllowed = ctx?.minAmount || 0;
      console.log("isAddedddd", value, !value || isNaN(enteredAmount), enteredAmount < minAllowed, enteredAmount > maxAllowed);
      if (!value || isNaN(enteredAmount)) {
        // console.log("Amount is required and must be a number");
        cb("Amount is required and must be a number");
      } else if (enteredAmount < minAllowed) {
        // console.log(`Amount must be at least ${minAllowed}`);
        cb(`Amount must be at least ${minAllowed}`);
      } else if (enteredAmount > maxAllowed) {
        // console.log(`Amount must be less than or equal to ${maxAllowed}`);
        cb(`Amount must be less than or equal to ${maxAllowed}`);
      } else {
        // console.log("truuuuuuuuueeeeeeeeee");
        cb(true);
      }
    };

  const handleUpdateStructure = (values) => {
    if (agentType?.value === "IB" && !clientDetails?.parentId?._id) {
      console.log("aaaaaaa11111111", selectedClientId);
      dispatch(updateAgreement({
        agrementId: clientDetails?.fx?.agrementId,
        customerId: clientDetails?._id,
        selectedClient: selectedClientId?.value || selectedClientId?._id || selectedClientId,
        ibAggrementValues,
        accountTypeProductsWithType,
        notAddedRebate,
        updatedRebates: rebates,
      }));
    } else if (agentType?.value === "SIB" && String(clientDetails?.parentId?._id) === String(selectedClientId?.value || selectedClientId?._id)) {
      console.log("aaaaaa2222222222", selectedClientId);
      dispatch(updateAgreement({
        agrementId: clientDetails?.fx?.agrementId,
        customerId: clientDetails?._id,
        selectedClient: selectedClientId?.value || selectedClientId?._id || selectedClientId,
        ibAggrementValues,
        accountTypeProductsWithType,
        notAddedRebate,
        updatedRebates: rebates,
      }));
    } else {
      let obj = {
        newParentId: selectedClientId?.value || selectedClientId?._id || selectedClientId,
        clientId: clientDetails?._id,
        newLevel: selectedTier?.value || selectedTier,
        accountTypeProductsWithType,
        notAddedRebate,
        updatedRebates: rebates,
      };
      console.log("aaaaaaaaaa4444444444444", obj, selectedTier);
      dispatch(transferIbStart(obj));
    }
    // let obj = {
    //   newParentId: selectedClientId?.value || selectedClientId?._id || selectedClientId,
    //   clientId: clientDetails?._id,
    //   newLevel: selectedTier?.value || selectedTier,
    //   accountTypeProductsWithType,
    //   notAddedRebate,
    // };
    // dispatch(transferIbStart(obj));
    toggle();
    
  };

  useEffect(() => {
    if (agentType?.value === "IB" && !clientDetails?.parentId?._id) {
      setIsUpdate(true);
    } else if (agentType?.value === "SIB" && String(clientDetails?.parentId?._id) === String(selectedClientId?.value || selectedClientId?._id)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, [agentType, selectedClientId]);

  useEffect(() => {
    console.log("notAddedRebate", notAddedRebate);
  }, [notAddedRebate]);

  return (
    <React.Fragment>
      <AvForm
        className='p-4'
        onValidSubmit={handleUpdateStructure}
      >
        <Row>
          <Col sm="12">
            {
              selectedClientId &&
              <div className="row my-3">
                {
                  Object.entries(rebates || {})?.map(([rebateId, rebate]) => (
                    // Object.entries(products?.[1])?.map((ibAgg) => (
                    <div
                      className="col-md-6 mt-3 d-flex gap-2 align-items-center"
                      key={rebateId}
                    >
                      <div className="w-100">
                        {
                          agentType?.value === "IB" ? 
                            <SearchableMTGroupsEditDropdown
                              name={`amountVal${rebateId}`}
                              type="text"
                              // errorMessage={"ٌSwap Groups is required"}
                              // validate={{ required: { value: true } }}
                              label={`${rebate?.name}`}
                              // defaultOptions={generateArrayFromNum(rebate?.rebateValue + 1)?.map((valLevel, valIndex)=>{
                              //   return {
                              //     label: rebate?.rebateValue - valIndex,
                              //     value: rebate?.rebateValue - valIndex,
                              //   };
                              // })}
                              defaultOptions={rebate?.promotionOptionalRebateValue?.length > 0 
                                ? rebate.promotionOptionalRebateValue.map((option) => ({
                                  label: option,
                                  value: option,
                                }))
                                : [{ 
                                  label: "0",
                                  value: 0 
                                }]}
                              isMulti={false}
                              defaultValue={[Number(rebate?.rebate)]}
                              value={[rebate?.rebate]}
                              isPromote={true}
                              // onChange={(e) => handleChangeAggrementValue(e?.value, rebate?.id, type, products?.[0])}
                              onChange={(e) => handleChangeRebateValue(e?.value, rebate?.id, type)}
                              // validate={{
                              //   custom: handleChangeAmount(Number(rebate?.parentRebate) || 0, Number(rebate?.rebate) || 0),
                              // }}
                            />
                            :
                            <AvField
                              name={`amountVal${rebateId}`}
                              label={`${rebate?.name}`}
                              placeholder={"Enter value"}
                              type="number"
                              defaultValue={Number(rebate?.rebate) || "0"}
                              value={rebate?.rebate}
                              // onChange={(e) => handleChangeAggrementValue(e.target.value, rebate?.id, type, products?.[0])}
                              onChange={(e) => handleChangeRebateValue(e.target.value, rebate?.id, type)}
                              validate={{
                                custom: handleChangeAmount(Number(rebate?.parentRebate) || 0, Number(rebate?.childRebate) || 0),
                              }}
                            />
                        }
                        {
                          agentType?.value === "SIB" &&
                          <div className="d-flex gap-2">
                            <p className="mb-0">($)</p>
                            <p className="mb-0 text-success">
                              {
                                [...(rebate?.parentAllStructreRebates || [])]?.reverse()?.map((valLevel, valIndex) => (
                                  <>
                                    { valLevel }
                                    { Number(valIndex) <  Number(noOfLevels - 1) ? "|" : "|" }
                                  </>
                                ))
                              }
                              <span className={`${Number(rebate?.parentRebate) - Number(rebate?.rebate || 0) < 0 ? "text-danger" : "text-success"}`}>
                                {(Number(rebate?.parentRebate) - Number(rebate?.rebate || 0)).toFixed(2)}
                              </span>
                              {/* <span className={`${(Number(rebate?.parentRebate) - Number(rebate?.rebate || 0) < 0 && Number(rebate?.rebate) - Number(rebate?.childRebate || 0) > 0) ? "text-danger" : "text-success"} fw-bold fs-4`}>
                                {Number(rebate?.rebate || 0)}
                              </span>  */}
                              {/* | */}
                              {/* <span className={`${Number(rebate?.rebate) - Number(rebate?.childRebate || 0) < 0 ? "text-danger" : "text-success"}`}>
                                {rebate?.childRebate ? Number(rebate?.childRebate) : ""}
                              </span> */}
                              {/* {
                                [...(rebate?.childAllStructreRebates || [])]?.reverse()?.map((valLevel, valIndex) => (
                                  <>
                                    { valLevel }
                                    { Number(valIndex) <  Number(noOfLevels - 1) ? "|" : "|" }
                                  </>
                                ))
                              } */}
                            </p>
                          </div>
                        }
                      </div>
                    </div>
                    // ))
                  ))
                }
                {
                  Object.entries(notAddedRebate)?.map(([rebateId, rebate]) => (
                    <div
                      className="col-md-6 mt-3 d-flex gap-2 align-items-center"
                      key={rebateId}
                    >
                      <div className="w-100">
                        {
                          agentType?.value === "IB" ? 
                            <SearchableRebateRulesDropdown
                              name="promotionOptionalRebateValue"
                              type="text"
                              // errorMessage="Rebate is required"
                              // validate={{ required: { value: true } }}
                              label={`${rebate?.name}`}
                              isPromote={true}
                              defaultOptions={rebate?.promotionOptionalRebateValue?.length > 0 
                                ? rebate.promotionOptionalRebateValue.map((option) => ({
                                  label: option,
                                  value: option,
                                }))
                                : [{ 
                                  label: "0",
                                  value: 0 
                                }]}
                              isMulti={false}
                              onChange={(e) => handleChangeNotAddedRebates(e?.value, rebateId, type)}
                              validate={{
                                // custom: rebate?.isAdded ? handleChangeAmount(Number(isUpdate ? rebate?.totalParentRebate : rebate?.parentRebate) || 0, Number(rebate?.rebate) || 0) : () => {},
                                custom: rebate?.isAdded ? handleChangeAmount(Number(rebate?.parentRebate) || 0, Number(rebate?.rebate) || 0) : () => {},
                              }}
                              // onChange={(e) => { handleRebateChange(e, rebate) }}
                            />
                            :
                            <AvField
                              name={`amountVal${rebateId}`}
                              label={`${rebate?.name}`}
                              placeholder={"Enter value"}
                              type="number"
                              value={rebate?.rebate}
                              onChange={(e) => handleChangeNotAddedRebates(e.target.value, rebateId, type)}
                              validate={{
                                // custom: rebate?.isAdded ? handleChangeAmount(Number(isUpdate ? rebate?.totalParentRebate : rebate?.parentRebate) || 0, Number(rebate?.rebate) || 0) : (value, ctx, input, cb) => {cb(true)},
                                custom: rebate?.isAdded ? handleChangeAmount(Number(rebate?.parentRebate) || 0, Number(rebate?.rebate) || 0) : (value, ctx, input, cb) => {cb(true)},
                              }}
                            />
                        }
                        {
                          agentType?.value === "SIB" &&
                            <div className="d-flex gap-2">
                              <p className="mb-0">($)</p>
                              <span className="mb-0 text-secondary">
                                {/* {
                                  generateArrayFromNum(noOfLevels - 1)?.map((valLevel, valIndex) => (
                                    <>
                                      { 0 }
                                      { Number(valIndex) <  Number(noOfLevels - 2) ? "|" : "" }
                                    </>
                                  ))
                                } */}
                                {
                                  [...(rebate?.parentAllStructreRebates || [])]?.map((valLevel, valIndex) => (
                                    <>
                                      { valIndex < rebate?.parentAllStructreRebates?.length - 1 && valLevel } 
                                      { Number(valIndex) <  Number(noOfLevels - 2) ? "|" : "" }
                                    </>
                                  ))
                                }
                                <span className={`${ rebate?.isAdded ? ( Number(rebate?.parentRebate) - Number(rebate?.rebate || 0) < 0 ? "text-danger" : "text-success") : "text-secondary"}`}>
                                  {(Number(rebate?.parentRebate) - Number(rebate?.rebate || 0)).toFixed(2)}
                                </span>
                              </span>
                            </div>
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
            }
          </Col>
        </Row>
        <Button type="submit" color="primary" className="">
          {"Edit"}
        </Button>
      </AvForm>
    </React.Fragment>
  );
}

export default withTranslation()(MTRebate);