import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector
} from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Button,
} from "reactstrap";
import {
  checkingTransferIbStart,
  fetchClientsStart,
} from "store/client/actions";
import { AvForm } from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import { 
  fetchAgreement, 
  fetchAgreements, 
  updateAgreement 
} from "store/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { fetchRebateRules } from "store/rebateSettings/rebateRules/actions";
import MT5Rebate from "./MT5Rebate";
import MT4Rebate from "./MT4Rebate";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import * as ibAgreementsApi from "apis/ib-agreements";
import MTRebate from "./MTRebate";
import CustomSelect from "components/Common/CustomSelect";
import SearchableClientIbSelectTransfer from "components/Common/SearchableClientIbSelectTransfer";
import SearchableAgentDropdown from "components/Common/SearchableAgentDropdown";


function ShowRebate({ clientId, isLead, t, toggleModal, linkClientModal }) {
  const {
    clientDetails,
  } = useSelector(state => state.clientReducer);
  const pathParams = useParams();
  const customerId = pathParams.clientId;
  const allRebateRules = useSelector((state) => state.RebateRulesReducer.rebateRules || []);

  // const [selectedTier, setSelectedTier] = useState();
  const tiers = [
    {
      label: "MIB",
      value: "0",
      isMIB: true,
    },
    {
      label: "SUIB1",
      value: 1,
    },
    {
      label: "SUIB2",
      value: 2,
    },
    {
      label: "SUIB3",
      value: 3,
    },
    {
      label: "SUIB4",
      value: 4,
    },
    {
      label: "SUIB5",
      value: 5,
    },
    {
      label: "SUIB6",
      value: 6,
    },
    {
      label: "SUIB7",
      value: 7,
    },
    {
      label: "SUIB8",
      value: 8,
    },
    {
      label: "SUIB9",
      value: 9,
    },
    {
      label: "SUIB10",
      value: 10,
    },
  ];
  const agentTypes = [
    // {
    //   label:
    //     clientDetails.parentId?.level !== 0
    //       ? `${clientDetails?.parentId?.firstName} ${clientDetails?.parentId?.lastName}`
    //       : "Master",
    //   value: clientDetails?.parentId?._id || "",
    // },
    {
      label: "IB",
      value: "IB",
    },
    {
      label: "SIB",
      value: "SIB",
    },
  ];
  
  const dispatch = useDispatch();
  const { transfering, checkTransferingPossibleStatus, checkTransfering } = useSelector((state) => state.clientReducer);
  // const { agreements } = useSelector((state) => state.ibAgreements);

  const [toggleHideClient, setToggleHideClient] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedTier, setSelectedTier] = useState({
    label: "MIB",
    value: "0",
  });
  const [agentType, setAgentType] = useState({
    label: "IB",
    value: "IB",
  });

  const [selectedAgreement, setSelectedAgreement] = useState("");
  // const [linkClientModal, setLinkClientModal] = useState(false);
  const { products, agreements, agreement, deleting, clearingCounter } = useSelector((state) => state.ibAgreements);
  const [platforms, setPlatForms] = useState([]);
  const [ibAggrementValues, setIbAggrementValues] = useState({});
  const [ibAggrementValuesLevel, setIbAggrementValuesLevel] = useState({});
  const [accountTypeProductsWithType, setAccountTypeProductsWithType] = useState({});
  const [rebates, setRebates] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [accountTypeProducts, setAccountTypeProducts] = useState([]);
  const [noOfLevels, setNoOfLevels] = useState(0);
  const [notAddedRebate, setNotAddedRebate] = useState([]);
  const [isCalledBefore, setIsCalledBefore] = useState(false);
  const [allAggrementsCustomers, setAllAggrementsCustomers] = useState([]);

  const [aggrementValues, setAggrementValues] = useState([]);
  const [productsValues, setProductsValues] = useState({});
  const [clientAggrement, setClientAggrement] = useState({});
  const [clientAggrementValues, setClientAggrementValues] = useState([]);
  const [clientProductsValues, setClientProductsValues] = useState({});
  const [selectedPlatform, setSelectedPlatform] = useState("");

  // useEffect(() => {
  //   const platformsSet = new Set();
  //   clientDetails?.promoLink?.forEach((promo) => {
  //     platformsSet.add(promo?.accountType?.platform?.toLowerCase());
  //   });
  //   setProductsValues({ ...products });
  //   setPlatForms([...platformsSet]);
  //   if (platforms?.length === 2 ) {
  //     setActiveTab("1");
  //   } else {
  //     setActiveTab(platforms?.includes("mt5") ? "1" : "2");
  //   }
  // }, [clientDetails]);

  useEffect(() => {
    const platformsSet = new Set();
    const promoLinks = [
      ...(clientDetails?.promoLink || []),
      ...(selectedClientId?.promoLinkDetails || []),
    ];
    console.log("setProductsValues", selectedClientId?.promoLinkDetails);
    promoLinks?.forEach((promo) => {
      platformsSet.add(promo?.accountType?.platform?.toLowerCase());
    });
    // setProductsValues({ ...products });
    setPlatForms([...platformsSet]);
    if (platforms?.length === 2 ) {
      setActiveTab("1");
    } else {
      setActiveTab(platforms?.includes("mt5") ? "1" : "2");
    }
  }, [clientDetails, selectedClientId]);

  // useEffect(() => {
  //   if (agreement) {
  //     const platformsSet = new Set();
  //     const values = [];
  //     const products = {};
  //     console.log("agreementagreementagreement", agreement);
  //     agreement?.members?.forEach((member) => {
  //       member?.values?.forEach((value) => {
  //         Object.entries(value?.products)?.forEach((product) => {
  //           products[`${product?.[0]}`] = [
  //             ...(products[`${product?.[0]}`] || []),
  //             product?.[1]?.rebate,
  //           ];
  //         });
  //         values?.push(value);
  //         console.log("valuevaluevaluevaluevalue", value);
  //         platformsSet.add(value?.accountTypeId?.platform?.toLowerCase());
  //       });
  //     });
  //     console.log("dasdsdsadsaasddas", products, aggrementValues);
  //     setAggrementValues([...values]);
  //     setPlatForms([...platformsSet]);
  //     setProductsValues({ ...products });
  //   }
  // }, [agreement]);

  // useEffect(() => {
  //   if (clientAggrement) {
  //     const platformsSet = new Set();
  //     const values = [];
  //     const products = {};
  //     // clientAggrement?.members?.forEach((member) => {
  //     clientAggrement?.members?.[clientAggrement?.members?.length - 1]?.values?.forEach((value) => {
  //       Object.entries(value?.products)?.forEach((product) => {
  //         products[`${product?.[0]}`] = {
  //           value: product?.[1]?.rebate || 0,
  //           accountTypeId: value?.accountTypeId
  //         };
  //       });
  //       values?.push(value);
  //       platformsSet.add(value?.accountTypeId?.platform?.toLowerCase());
  //     });
  //     setClientAggrementValues([...values]);
  //     // setPlatForms([...platformsSet]);
  //     console.log("products", products);
  //     setClientProductsValues({ ...products });
  //   }
  // }, [agreement]);

  // useEffect(() => {
  //   if (clientAggrementValues && selectedTier !== "0") {
  //     const values = [...aggrementValues];
  //     clientAggrementValues?.forEach((value) => {
  //       values?.push(value);
  //     });
  //     setAggrementValues([...values]);
  //   }
  // }, [clientAggrementValues]);

  useEffect(() => {
    linkClientModal && dispatch(fetchClientsStart({ limit: 100 }));
  }, [linkClientModal]);

  // useEffect(() => {
  //   if (clientDetails && agreements) {
  //     setClientAggrement(agreements?.find(agg=> agg?._id === clientDetails?.fx?.agrementId ));
  //   }
  // }, [agreements, clientDetails]);


  // useEffect(() => {
  //   console.log("selectedClientId", selectedClientId);
  //   if (selectedTier && selectedClientId?._id) {
  //     checkPossibleTransferFun();
  //   }
  // }, [selectedTier, selectedClientId]);

  // useEffect(() => {
  //   if (selectedTier) {
  //     // setSelectedClientId(null);
  //   }
  // }, [selectedTier]);

  // useEffect(() => {
  //   console.log("agentType", agentType, clientDetails?.parentId);
  //   if (agentType) {
  //     // setSelectedTier(null);
  //     // setSelectedClientId(null);
  //   }
  // }, [agentType]);

  // useEffect(() => {
  //   console.log("selectedTier", selectedTier, agentType, clientDetails?.parentId);
  //   // if (agentType) {
  //   //   setSelectedTier(null);
  //   //   setSelectedClientId(null);
  //   // }
  // }, [selectedTier]);
  
  useEffect(() => {
    const agentTypeValue = clientDetails?.parentId?._id ? {
      label: "SIB",
      value: "SIB"
    } :
      {
        label: "IB",
        value: "IB"
      };
    setAgentType(agentTypeValue);
    
    const selectedTierValue = clientDetails?.fx?.ibLevel === 1 ? {
      label: "MIB",
      value: "0",
    } :
      {
        label: `SUIB${clientDetails?.fx?.ibLevel - 1}`,
        value: Number(clientDetails?.fx?.ibLevel - 1),
      };
    setSelectedTier(selectedTierValue);
      
    if (clientDetails?.parentId?._id) {
      setSelectedClientId({
        label: `${clientDetails?.parentId.firstName} ${clientDetails?.parentId.lastName}`,
        value: clientDetails?.parentId?._id,
      });
    } else {
      setSelectedClientId({
        label: `${clientDetails?.agent?.firstName} ${clientDetails?.agent?.lastName}`,
        value: clientDetails?.agent?._id,
      });
    }
    // getAggremementValues(clientDetails);
    // if (selectedTierValue?.value === "0") {
    // } else {
    //   getAggremementValues(clientDetails?.parentId);
    // }
  }, [clientDetails]);

  function getAggremementValues(client, newParentId) {
    console.log("newParentId", newParentId, client, clientDetails);
    // if (newParentId || !clientDetails?.parentId?._id) {
    const payload = {
      agrementId: client?.fx?.agrementId,
      customerId: client?._id,
      newParentId: newParentId?._id || newParentId?.value || newParentId,
    };
    
    ibAgreementsApi.fetchIbAgreementValues(payload).then((data) => {
      setRebates(data?.result?.aggrementValues?.rebates);
      setAllAggrementsCustomers(data?.result?.allAggrementsCustomers);
      setIbAggrementValues(data?.result?.aggrementValues?.products);
      setIbAggrementValuesLevel(data?.result?.aggrementValues?.productsWithLevel);
      setAccountTypeProductsWithType(data?.result?.aggrementValues?.accountTypeProductsWithType);
      setAccountTypeProducts(data?.result?.aggrementValues?.accountTypeProducts);
      setAllProducts(data?.result?.aggrementValues?.allProducts);
      setNotAddedRebate(data?.result?.notAddedRebate);
      setNoOfLevels(data?.result?.aggrementValues?.noOfLevels);
    }).finally(() => {
      setIsCalledBefore(true);
    });
    // }
  }
  
  useEffect(()=>{
    console.log("selectedClientId", selectedClientId);
    if (!isCalledBefore) {
      console.log("11111");
      getAggremementValues(clientDetails);
    }
    if (selectedTier?.value === "0" && isCalledBefore) {
      getAggremementValues(clientDetails);
    }
    if (selectedTier?.value > 0 && isCalledBefore) {
      getAggremementValues(clientDetails, selectedClientId);
    }
  }, [selectedClientId]);

  useEffect(() => {
    if (transfering && linkClientModal) {
      toggleModal();
    }
  }, [transfering]);


  // useEffect(() => {
  //   dispatch(
  //     fetchRebateRules({
  //       limit: 10000,
  //     }));
  // }, []);

  // function checkPossibleTransferFun() {
  //   let obj = {
  //     newParentId: selectedClientId?._id || selectedClientId,
  //     clientId,
  //     newLevel: selectedTier,
  //   };
  //   dispatch(checkingTransferIbStart(obj));
  // }

  const { profileMetaInfo = {} } = useSelector((state) => state.Profile);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const tab = query.get("tab");
  const tabToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      query.set("tab", tab);
    }
  };

  // const handleUpdateStructure = (values) => {
  //   // console.log(clientProductsValues);
  //   dispatch(updateAgreement({
  //     agrementId: clientDetails?.fx?.agrementId,
  //     customerId: clientDetails?._id,
  //     updatedRebates: JSON.stringify(clientProductsValues)
  //   }));
  //   toggle();
    
  // };

  function handleChangeProduct(value, id) {
    const modifiedPorducts = { ...allProducts };
    modifiedPorducts[`${id}`] = {
      ...modifiedPorducts[`${id}`],
      rebate: value,
    };
    setAllProducts(modifiedPorducts);
  }

  function handleChangeRebateValue(value, id, type) {
    const modififedRebates = { ...rebates };
    modififedRebates[`${type}`][`${id}`] = {
      ...modififedRebates[`${type}`][`${id}`],
      rebate: value,
    };
    setRebates(modififedRebates);
  }

  function handleChangeAggrementValue(value, id, type) {
    const modififedIbAggrementValues = { ...ibAggrementValues };
    modififedIbAggrementValues[`${type}`][`${id}`] = {
      ...modififedIbAggrementValues[`${type}`][`${id}`],
      rebate: value,
    };
    setAllProducts(modififedIbAggrementValues);
  }

  function handleChangeAccountTypeProductsWithType(value, id, type, accountTypeId) {
    const modifiedAccountTypeProductsWithType = { ...accountTypeProductsWithType };
    modifiedAccountTypeProductsWithType[`${type}`][`${accountTypeId}`][`${id}`] = {
      ...modifiedAccountTypeProductsWithType[`${type}`][`${accountTypeId}`][`${id}`],
      rebate: value,
    };
    setAccountTypeProductsWithType(modifiedAccountTypeProductsWithType);
  }

  function handleChangeNotAddedRebates(value, id, type) {
    const modififedNotAddedRebate = { ...notAddedRebate };
    modififedNotAddedRebate[`${type}`][`${id}`] = {
      ...modififedNotAddedRebate[`${type}`][`${id}`],
      rebate: value,
      isAdded: true,
    };
    setNotAddedRebate(modififedNotAddedRebate);
  }

  const loadTabs = ({
    transactions: { depositDetails: {  forex } = {} } = {},
  }) => [
    {
      tabId: "1",
      navLinkName: "MT5",
      component: <MTRebate
        noOfLevels={noOfLevels}
        rebates={rebates?.["mt5"]} 
        ibAggrementValues={ibAggrementValues?.["mt5"] || []}
        notAddedRebate={notAddedRebate?.["mt5"] || []}
        ibAggrementValuesLevel={ibAggrementValuesLevel?.["mt5"] || []}
        accountTypeProductsWithType={accountTypeProductsWithType?.["mt5"] || []}
        accountTypeProducts={accountTypeProducts || []}
        allProducts={allProducts || []}
        clientDetails={clientDetails} 
        handleChangeRebateValue={handleChangeRebateValue} 
        handleChangeAggrementValue={handleChangeAggrementValue} 
        handleChangeAccountTypeProductsWithType={handleChangeAccountTypeProductsWithType} 
        handleChangeNotAddedRebates={handleChangeNotAddedRebates} 
        handleChangeProduct={handleChangeProduct} 
        agentType={agentType}
        selectedTier={selectedTier}
        selectedClientId={selectedClientId}
        toggle={toggleModal}
        type={"mt5"}
        t={t}

        // aggrementValues={aggrementValues}
        allRebateRules={allRebateRules}
        // productsValues={productsValues}
        // clientAggrementValues={clientAggrementValues}
        // clientProductsValues={clientProductsValues}
        // setClientProductsValues={setClientProductsValues}
      />,
    },
    {
      tabId: "2",
      navLinkName: "MT4",
      component: <MTRebate
        noOfLevels={noOfLevels}
        rebates={rebates?.["mt4"]} 
        ibAggrementValues={ibAggrementValues?.["mt4"] || []}
        notAddedRebate={notAddedRebate?.["mt4"] || []}
        ibAggrementValuesLevel={ibAggrementValuesLevel?.["mt4"] || []}
        accountTypeProductsWithType={accountTypeProductsWithType?.["mt4"] || []}
        accountTypeProducts={accountTypeProducts || []}
        clientDetails={clientDetails}
        allProducts={allProducts || []}
        handleChangeRebateValue={handleChangeRebateValue} 
        handleChangeAggrementValue={handleChangeAggrementValue} 
        handleChangeAccountTypeProductsWithType={handleChangeAccountTypeProductsWithType} 
        handleChangeNotAddedRebates={handleChangeNotAddedRebates} 
        handleChangeProduct={handleChangeProduct}
        t={t}
        type={"mt4"}
        toggle={toggleModal}
        agentType={agentType}
        selectedTier={selectedTier}
        selectedClientId={selectedClientId}

        // aggrementValues={aggrementValues}
        allRebateRules={allRebateRules}
        // productsValues={productsValues}
        // clientAggrementValues={clientAggrementValues}
        // clientProductsValues={clientProductsValues}
        // setClientProductsValues={setClientProductsValues}
      />,
    },
  ];

  const tabs = loadTabs(profileMetaInfo).filter((tab) => !tab.hidden);
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab(tabs[0].tabId);
    }
    if (tab) {
      setActiveTab(tab);
    }
  }, [profileMetaInfo]);
  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab(tabs[0].tabId);
    }
    if (tab) {
      setActiveTab(tab);
    }
  }, [profileMetaInfo]);

  
  return (
    <React.Fragment >
      {/* <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t("Edit Structure")}
      </button> */}
      <Modal isOpen={linkClientModal} toggle={toggleModal} centered={true} size="lg">
        <ModalHeader toggle={toggleModal} tag="h4">
          {t("Edit Structure")}
        </ModalHeader>
        <ModalBody >
          <Row className="mb-3">
            
            <AvForm
              className='p-4'
              // onValidSubmit={handleUpdateStructure}
            >
              <Row>
                <Col className="mb-2" md="6">
                  <Label>{t("Agent Type")}</Label>
                  <div>
                    <CustomSelect
                      isRequired
                      placeholder={t("Select Agent Type")}
                      onChange={(e) => {
                        setSelectedTier(null);
                        setSelectedClientId(null);
                        setAgentType(e);
                      }}
                      isSearchable={false}
                      // defaultValue={agentType}
                      value={agentType}
                      options={agentTypes?.map((agr) => ({
                        label: agr.label,
                        value: agr.value,
                      }))}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mb-2" md="6">
                  <Label>{t("Tier")}</Label>
                  <div>
                    <CustomSelect
                      isRequired
                      placeholder={t("Select Tier")}
                      onChange={(e) => {
                        setSelectedClientId(null);
                        setSelectedTier(e);
                        setToggleHideClient(true);
                        setTimeout(() => {
                          setToggleHideClient(false);
                        }, 0);
                      }}
                      value={selectedTier}
                      isSearchable={true}
                      options={tiers?.filter((tier) => (agentType?.value === "IB" ? tier?.isMIB : !tier?.isMIB) )?.map((agr) => ({
                        label: agr.label,
                        value: agr.value,
                      }))}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                {
                  !toggleHideClient && 
                  <>
                    {(selectedTier != 0 && agentType?.value !== "IB") ? (
                      <Col md="6">
                        <SearchableClientIbSelectTransfer
                          name="selectedClientId"
                          label="IBs"
                          level={selectedTier?.value}
                          type="IB"
                          isRequired
                          value={selectedClientId}
                          allAggrementsCustomers={allAggrementsCustomers}
                          // disallowed={clientId}
                          onChange={(e) => setSelectedClientId(e?.value)}
                          currentClientId={customerId}
                        />
                      </Col>
                    ) : (
                      <Col>
                        <SearchableAgentDropdown
                          isRequired={true}
                          // clientData={clientDetails || null}
                          title="Agent"
                          value={selectedClientId}
                          onChange={(e) => setSelectedClientId(e?.value)}
                        />
                      </Col>
                    )}
                  </>
                }
              </Row>
            </AvForm>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Nav tabs>
                      {tabs.map((tabItem) => (
                        platforms?.includes(tabItem?.navLinkName?.toLocaleLowerCase()) && (
                          <>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={{
                                  active: activeTab === tabItem.tabId,
                                }}
                                onClick={() => {
                                  tabToggle(tabItem.tabId);
                                }}
                              >
                                {tabItem.navLinkName}
                              </NavLink>
                            </NavItem>
                          </>
                        )
                      ))}
                    </Nav>
                    <TabContent activeTab={activeTab} className="p-3 text-muted">
                      {tabs.map((tabItem) => (
                        platforms?.includes(tabItem?.navLinkName?.toLocaleLowerCase()) && (
                          <>
                            <TabPane tabId={tabItem.tabId}>
                              <Row>
                                <Col sm="12">{tabItem.component}</Col>
                              </Row>
                            </TabPane>
                          </>
                        )
                      ))}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Button type="submit" color="primary" className="">
                  {"Edit"}
                </Button>
              </Col>
            </Row> */}
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment >
  );
}

export default withTranslation()(ShowRebate);