import PromoLinkDropDown from "components/Common/PromoLinkDropDown";
import PromoLinkDropDownOpenLink from "components/Common/PromoLinkDropDownOpenLink";
import AgreementDetails from "pages/ClientDetail/Partnership/AgreementDetails";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
} from "reactstrap";
import { ibRequestApprove } from "store/requests/actions";

export default function ViewPredefinedAgreements({
  show, toggle, parentId, requestId
}) {
  // const [currentParentLevel, setCurrentParentLevel] = useState(null);
  // const [agrementsFiltered, setAgrementsFiltered] = useState([]);
  // const [agreementSelected, setAgreementSelected] = useState(0);
  // const [minClient, setMinClient] = useState(0);
  const dispatch = useDispatch();
  const [promoLinkData, setPromoLinkData] = useState({});

  // const [open, setOpen] = useState(0);
  // const toggleTabs = (index) => {
  //   if (open === index) {
  //     setOpen();
  //   } else {
  //     setAgreementSelected(index);
  //     setOpen(index);
  //   }
  // };


  // const { agreements } = useSelector(state => state.predefinedIbAgrements);
  // const { agreements: originalAgreements } = useSelector((state) => state.ibAgreements);
  // const { accountTypes = [] } = useSelector((state) => state?.tradingAccountReducer);
  const { loading, clearingCounter } = useSelector(state=> state.requestReducer);

  // useEffect(()=>{
  //   if (clientsDetails)
  //     if (clientsDetails.parentId && clientsDetails.parentId.level) {
  //       setCurrentParentLevel(clientsDetails.parentId.level);
  //       const agrement = agreements.docs.filter(ag => ag.levels === clientsDetails.parentId.level + 1);
  //       if (agrement.length > 0)
  //         adjustAgrementCustomerIds(agrement?.[agreementSelected], originalAgreements?.[0]);
  //       setAgrementsFiltered(agrement);
  //     } else {
  //       setCurrentParentLevel(1);
  //       const agrement = agreements.docs.filter(ag => ag.levels === 1);
  //       setAgrementsFiltered(agrement);
  //       if (agrement.length > 0)
  //         adjustAgrementCustomerIds(agrement?.[0], []);
  //     }
  // }, [clientsDetails, agreements, agreementSelected]);

  // function adjustAgrementCustomerIds(predefined, original) {
  //   predefined?.members?.sort((a, b) => a.level - b.level);
  //   original?.members?.sort((a, b) => a.level - b.level);
  //   const predefinedMembers = predefined?.members;
  //   const originalMembers = original?.members;
  //   if (original && original.length === 0){
  //     predefinedMembers[0].customerId = {
  //       "fx": clientsDetails.fx,
  //       "_id": clientsDetails._id,
  //       "firstName": clientsDetails.firstName,
  //       "lastName": clientsDetails.lastName
  //     };
  //   }
  //   for (let i = 0; i < predefinedMembers.length; i++) {
  //     if (i < predefinedMembers.length - 1) {
  //       predefinedMembers[i].customerId = {
  //         "fx": clientsDetails.parentId.fx,
  //         "_id": clientsDetails.parentId._id,
  //         "firstName": clientsDetails.parentId.firstName,
  //         "lastName": clientsDetails.parentId.lastName
  //       };
  //     }
  //     if (i === predefinedMembers.length - 1){
  //       predefinedMembers[i].customerId = {
  //         "fx": clientsDetails.fx,
  //         "_id": clientsDetails._id,
  //         "firstName": clientsDetails.firstName,
  //         "lastName": clientsDetails.lastName
  //       };
  //     } else {
  //       if (originalMembers && originalMembers.length > 0)
  //         predefinedMembers[i].customerId = originalMembers[i].customerId;
  //     }
  //   }
  // }

  const approveRequest = () => {
    dispatch(ibRequestApprove({
      requestId: requestId,
      promotionLink: promoLinkData.promotionLinks,
      rebateRef: promoLinkData.rebateRef,
    }));
  };
  return (
    <Modal isOpen={show} toggle={toggle} centered={true} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        Choose Agreement
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <div className="mb-3">
          {/* <PromoLinkDropDown
            setPromoLinkData={setPromoLinkData}
          /> */}
          
          <PromoLinkDropDownOpenLink
            setPromoLinkData={setPromoLinkData}
            // filteredPromo={ibPromoLink}
            selectedClientDetails={{}}
            isOpenLink={false}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <Button className="mx-2" onClick={()=>{
            toggle();
          }}>Back</Button>
          <Button className="mx-2" onClick={() => { approveRequest() }} disabled={loading}>Approve</Button>
        </div>
        {/* {agrementsFiltered && agrementsFiltered?.length > 0 ? <>
          {agrementsFiltered.map((agreement, index)=>{
            return <Accordion open={open} toggle={toggleTabs} key={index}>
              <AccordionItem>
                <AccordionHeader targetId={index}>{agreement.title}</AccordionHeader>
                <AccordionBody accordionId={index}>
                  {agreementSelected === index && <AgreementDetails agreement={agreement} accountTypes={accountTypes} key={index}/>}
                </AccordionBody>
              </AccordionItem>
            </Accordion>;
          })}
          <label>
            Minimum Clients : 
            <input
              type="number"
              pattern="[0-9]*"
              value={minClient}
              onChange={e => ((e.target.validity.valid && e.target.value > 0 ) ? setMinClient(e.target.value) : 0)}
            />
          </label>
          <div className="d-flex align-items-center justify-content-center mt-3">
            <Button className="mx-2" onClick={()=>{
              toggle();
            }}>Back</Button>
            <Button className="mx-2" onClick={() => { approveRequest() }} disabled={loading}>Approve</Button>
          </div></>
          : <>No Agreement with this level</>} */}
      </ModalBody>
    </Modal>
  );
}