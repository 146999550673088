// // EditServerModal.js
// import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import {
//   Modal, Button, ModalHeader, ModalBody, Label 
// } from "reactstrap";
// import { AvForm, AvField } from "availity-reactstrap-validation";
// import { useTranslation } from "react-i18next";
// import Select from "react-select";
// import { editServerStart, fetchServerStart } from "store/actions";

// function EditServerModal({ showEdit, toggle, serverId, initialData }) {
//   const [formData, setFormData] = useState(initialData || {});
//   const dispatch = useDispatch();
//   const { t } = useTranslation();

//   useEffect(() => {
//     // Update the formData whenever initialData changes
//     setFormData(initialData);
//   }, [initialData]);

//   const handleEditSubmit = (event, values) => {
//     delete formData.createdAt; // Remove createdAt field
//     delete formData._id; // Remove _id field
//     delete formData.__v; // Remove _v field
//     delete formData.createdBy; // Remove createdBy field
//     delete formData.createdAt; // Remove updatedBy field
//     delete formData.updatedAt; // Remove updatedAt field
//     delete formData.recordId; // Remove recordId field
//     console.log("Edit Server Form Data", formData);
//     dispatch(editServerStart({
//       ...formData, 
//       id: serverId
//     }));
//     dispatch(fetchServerStart({
//       page: 1,
//       limit: 10 
//     })); // Adjust pagination as needed
//     toggle(); // Close modal after submission
//   };

//   const handleInputChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value 
//     });
//   };

//   const handleSelectChange = (value, field) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       [field]: value 
//     }));
//   };

//   return (
//     <Modal isOpen={showEdit} toggle={toggle} centered>
//       <ModalHeader toggle={toggle}>{t("Edit Server")}</ModalHeader>
//       <ModalBody>
//         <AvForm onValidSubmit={handleEditSubmit}>
//           <div className="mb-3">
//             <AvField
//               name="name"
//               label={t("Name")}
//               placeholder={t("Enter Server Name")}
//               type="text"
//               value={formData?.name || ""}
//               onChange={handleInputChange}
//               validate={{ required: { value: true } }}
//             />
//           </div>
//           <div className="mb-3">
//             <AvField
//               name="address"
//               label={t("IP Address")}
//               placeholder={t("Enter IP Address")}
//               type="text"
//               value={formData?.address || ""}
//               onChange={handleInputChange}
//               validate={{ required: { value: true } }}
//             />
//           </div>
//           <div className="mb-3">
//             <AvField
//               name="remarks"
//               label={t("Remarks")}
//               placeholder={t("Enter Remarks")}
//               type="text"
//               value={formData?.remarks || ""}
//               onChange={handleInputChange}
//               validate={{ required: { value: true } }}
//             />
//           </div>
//           <div className="mb-3">
//             <Label>{t("Server Type")}</Label>
//             <Select
//               isClearable={true}
//               options={[
//                 {
//                   label: "Live",
//                   value: "LIVE",
//                 },
//                 {
//                   label: "Demo",
//                   value: "DEMO",
//                 },
//               ]}
//               value={
//                 formData?.type
//                   ? {
//                     label: formData?.type,
//                     value: formData?.type,
//                   }
//                   : null
//               }
//               onChange={(option) => handleSelectChange(option?.value, "type")}
//               placeholder={t("Choose A Type")}
//             />
//           </div>
//           <div className="mb-3">
//             <Label>{t("Server Platform")}</Label>
//             <Select
//               isClearable={true}
//               options={[
//                 {
//                   label: "MT4",
//                   value: "MT4",
//                 },
//                 {
//                   label: "MT5",
//                   value: "MT5",
//                 },
//               ]}
//               value={
//                 formData?.platform
//                   ? {
//                     label: formData?.platform,
//                     value: formData?.platform,
//                   }
//                   : null
//               }
//               onChange={(option) =>
//                 handleSelectChange(option?.value, "platform")
//               }
//               placeholder={t("Choose A Platform")}
//             />
//           </div>
//           <div className="text-center">
//             <Button type="submit" color="primary">
//               {t("Save Changes")}
//             </Button>
//             <Button
//               type="button"
//               color="secondary"
//               onClick={toggle}
//               className="ms-2"
//             >
//               {t("Cancel")}
//             </Button>
//           </div>
//         </AvForm>
//       </ModalBody>
//     </Modal>
//   );
// }

// export default EditServerModal;


import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  Col,
  Row,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { editServerStart, fetchServerStart } from "store/actions";

function EditServerModal({ showEdit, toggle, serverId, initialData, servers }) {
  const [formData, setFormData] = useState(initialData || {});
  const [filteredServers, setFilteredServers] = useState([]);
  const [serverError, setServerError] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    // Update the formData whenever initialData changes
    setFormData(initialData);
  }, [initialData]);

  useEffect(() => {
    // Filter servers based on the formData.type and formData.platform
    setFilteredServers(
      servers?.docs?.filter(
        (server) =>
          server?.type === formData?.type &&
          server?.platform === formData?.platform
      )
    );
  }, [formData?.type, formData?.platform, servers]);

  const handleEditSubmit = (event, values) => {
    if (!formData.serverId) {
      setServerError(true);
      return;
    }
    setServerError(false);
    delete formData.createdAt; // Remove createdAt field
    delete formData._id; // Remove _id field
    delete formData.__v; // Remove _v field
    delete formData.createdBy; // Remove createdBy field
    delete formData.updatedAt; // Remove updatedAt field
    delete formData.recordId; // Remove recordId field
    console.log("Edit Server Form Data", formData);
    dispatch(
      editServerStart({
        ...formData,
        id: serverId,
      })
    );
    dispatch(
      fetchServerStart({
        page: 1,
        limit: 10,
      })
    ); // Adjust pagination as needed
    toggle(); // Close modal after submission
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (value, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <Modal isOpen={showEdit} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{t("Edit Server")}</ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleEditSubmit}>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="name"
                  label={t("Name")}
                  placeholder={t("Enter Server Name")}
                  type="text"
                  value={formData?.name || ""}
                  onChange={handleInputChange}
                  validate={{ required: { value: true } }}
                  errorMessage={t("Server Name is required")}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label>{t("Server Platform")}</Label>
                <Select
                  isClearable={true}
                  options={[
                    {
                      label: "MT4",
                      value: "MT4",
                    },
                    {
                      label: "MT5",
                      value: "MT5",
                    },
                  ]}
                  value={
                    formData?.platform
                      ? {
                        label: formData.platform,
                        value: formData.platform,
                      }
                      : null
                  }
                  onChange={(option) =>
                    handleSelectChange(option ? option.value : "", "platform")
                  }
                  placeholder={t("Choose A Platform")}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label>{t("Platform Agent")}</Label>
                <Select
                  isClearable={true}
                  options={[
                    {
                      label: "Manager",
                      value: "Manager",
                    },
                    {
                      label: "Web",
                      value: "Web",
                    },
                  ]}
                  value={
                    formData?.agent
                      ? {
                        label: formData.agent,
                        value: formData.agent,
                      }
                      : null
                  }
                  onChange={(option) =>
                    handleSelectChange(option ? option.value : "", "agent")
                  }
                  placeholder={t("Choose an Agent")}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="address"
                  label={t("IP Address")}
                  placeholder={t("Enter IP Address")}
                  type="text"
                  value={formData?.address || ""}
                  onChange={handleInputChange}
                  validate={{ required: { value: true } }}
                  errorMessage={t("IP Address is required")}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="port"
                  label={t("Port")}
                  placeholder={t("Enter Port")}
                  type="number"
                  value={formData?.port || ""}
                  onChange={handleInputChange}
                  validate={{ required: { value: true } }}
                  errorMessage={t("Port is required")}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="remarks"
                  label={t("Remarks")}
                  placeholder={t("Enter Remarks")}
                  type="text"
                  value={formData?.remarks || ""}
                  onChange={handleInputChange}
                  validate={{ required: { value: true } }}
                  errorMessage={t("Remarks is required")}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label>{t("Server Type")}</Label>
                <Select
                  isClearable={true}
                  options={[
                    {
                      label: "Live",
                      value: "LIVE",
                    },
                    {
                      label: "Demo",
                      value: "DEMO",
                    },
                  ]}
                  value={
                    formData?.type
                      ? {
                        label: formData.type,
                        value: formData.type,
                      }
                      : null
                  }
                  onChange={(option) =>
                    handleSelectChange(option ? option.value : "", "type")
                  }
                  placeholder={t("Choose A Type")}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="adminAccount"
                  label={t("Admin Account")}
                  placeholder={t("Enter Admin Account")}
                  type="number"
                  value={formData?.adminAccount || ""}
                  onChange={handleInputChange}
                  validate={{ required: { value: true } }}
                  errorMessage={t("Admin Account is required")}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="password"
                  label={t("Password")}
                  placeholder={t("Enter Password")}
                  type="password"
                  value={formData?.password || ""}
                  onChange={handleInputChange}
                  validate={{ required: { value: true } }}
                  errorMessage={t("Password is required")}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="apiPassword"
                  label={t("API Password")}
                  placeholder={t("Enter API Password")}
                  type="password"
                  value={formData?.apiPassword || ""}
                  onChange={handleInputChange}
                  validate={{ required: { value: true } }}
                  errorMessage={t("API Password is required")}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label>{t("Link Status")}</Label>
                <Select
                  isClearable={true}
                  options={[
                    {
                      label: "Enabled",
                      value: true,
                    },
                    {
                      label: "Disabled",
                      value: false,
                    },
                  ]}
                  value={
                    formData?.linkStatus !== undefined
                      ? {
                        label: formData.linkStatus ? "Enabled" : "Disabled",
                        value: formData.linkStatus,
                      }
                      : null
                  }
                  onChange={(option) =>
                    handleSelectChange(
                      option ? option.value : false,
                      "linkStatus"
                    )
                  }
                  placeholder={t("Choose Link Status")}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label>{t("Order Permission")}</Label>
                <Select
                  isClearable={true}
                  options={[
                    {
                      label: "Enabled",
                      value: true,
                    },
                    {
                      label: "Disabled",
                      value: false,
                    },
                  ]}
                  value={
                    formData?.orderPermission !== undefined
                      ? {
                        label: formData.orderPermission
                          ? "Enabled"
                          : "Disabled",
                        value: formData.orderPermission,
                      }
                      : null
                  }
                  onChange={(option) =>
                    handleSelectChange(
                      option ? option.value : false,
                      "orderPermission"
                    )
                  }
                  placeholder={t("Choose Order Permission")}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label>{t("Server")}</Label>
                <Select
                  isClearable={true}
                  options={filteredServers?.map((server) => ({
                    label: server.name,
                    value: server._id,
                  }))}
                  value={
                    formData?.serverId
                      ? {
                        label: filteredServers?.find(
                          (server) => server._id === formData.serverId
                        )?.name,
                        value: formData.serverId,
                      }
                      : null
                  }
                  onChange={(option) =>
                    handleSelectChange(option ? option.value : "", "serverId")
                  }
                  placeholder={t("Choose a Server")}
                />
                {serverError && (
                  <div className="text-danger mt-2">
                    {t("Server selection is required")}
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <div className="text-center mt-3">
            <Button type="submit" color="primary">
              {t("Save Changes")}
            </Button>
            <Button
              type="button"
              color="secondary"
              onClick={toggle}
              className="ms-2"
            >
              {t("Cancel")}
            </Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}

export default EditServerModal;