/* eslint-disable no-undef */
import CustomSelect from "components/Common/CustomSelect";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  addOpenPromotionLinkStart,
} from "store/requests/actions";
import * as clientApi from "apis/client";
import { fetchPromoStart } from "store/actions";
import { fetchRebateRules } from "store/rebateSettings/rebateRules/actions";
import PromoLinkDropDownOpenLink from "components/Common/PromoLinkDropDownOpenLink";
import { AvField, AvForm } from "availity-reactstrap-validation";

function OpenLinkModal({
  show,
  toggle,
  requestsPermissions,
}) {

  const dispatch = useDispatch();
  const [promoLinkData, setPromoLinkData] = useState({});
  const { userData } = useSelector((state) => ({
    userData: state.Profile.userData,
  }));

  const { loading } = useSelector(
    (state) => state.requestReducer
  );
  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedClientDetails, setSelectedClientDetails] = useState();
  const [ibPromoLink, setIbPromoLink] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [remarks, setRemarks] = useState("");

  const handleChange = (e) => {
    setSelectedClientId(e.value?._id);
    setSelectedClientDetails(e.value);
    setIbPromoLink(e.value?.promoLink);
  };

  const handleRemarksChange = (e) => {
    const remarks = e.target.value;
    setRemarks(remarks);
  };
  useEffect(() => {
    setIbPromoLink([]);
    setSelectedClientId([]);
    setSelectedClientDetails([]);
    let updatedData = {};
    if (requestsPermissions?.getAssigned) {
      updatedData = {
        salesRef: userData?._id,
      };
    }
    if (requestsPermissions?.get) {
      updatedData = {};
    }
    if (requestsPermissions?.getAssigned || requestsPermissions?.get) {
      const payload = {
        page: 1,
        limit: 200000,
        isIb: true,
        ...updatedData,
      };
      clientApi.getClients({ payload }).then((data) => {
        setSelectOptions(
          data?.result?.docs.map((client) => ({
            value: client,
            label: `${client.firstName} ${client.lastName}`,
          }))
        );
        console.log("clients", selectOptions);
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      fetchPromoStart({
        page: 1,
        limit: 1000000,
      })
    );
    dispatch(fetchRebateRules({
      page: 1,
      limit: 100000000,
    }));
  }, []);

  const approveRequest = () => {
    console.log({
      promotionLinks: promoLinkData.promotionLinks,
      clientId: selectedClientId,
      rebateRef: promoLinkData.rebateRef,
    });
    dispatch(
      addOpenPromotionLinkStart({
        promotionLink: promoLinkData.promotionLinks,
        clientId: selectedClientId,
        rebateRef: promoLinkData.rebateRef,
        remark: remarks
      })
    );
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered={true} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        Choose Promotion Link
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <div className="mb-3">
          <Row className="align-items-center">
            <Col md="2">
              <label
                htmlFor="choices-single-default"
                className="form-label font-size-14 mb-3"
              >
                IB
              </label>
              <span style={{ color: "red" }}> * </span>
            </Col>
            <Col md="4">
              <div
                style={{
                  minWidth: "200px",
                  marginBottom: "20px",
                }}
              >
                <CustomSelect
                  classNamePrefix="select2-selection"
                  placeholder="Select IB"
                  options={selectOptions}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="12">
              <FormGroup>
                <Label for="remarks">Remarks</Label>
                <Input
                  id="remarks"
                  //type="textarea"
                  placeholder="Enter remarks"
                  onChange={handleRemarksChange}
                  value={remarks}
                />
              </FormGroup>
            </Col>
          </Row>
          {/** we need to add input field for remarks and send it with thre */}
          <div
            style={{
              border: 0,
              height: "1px",
              backgroundColor: "#D3D3D3",
              margin: "20px 0",
            }}
          ></div>
          {
            selectedClientId && 
              <PromoLinkDropDownOpenLink
                setPromoLinkData={setPromoLinkData}
                filteredPromo={ibPromoLink}
                selectedClientDetails={selectedClientDetails}
              />
          }
        </div>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <Button
            className="mx-2 bg-success"
            onClick={() => {
              approveRequest();
            }}
            disabled={
              loading || !selectedClientId
            }
          >
            Submit
          </Button>
          <Button
            className="mx-2"
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}
export default withTranslation()(OpenLinkModal);
