import React from "react";
import {
  useDispatch, connect
} from "react-redux";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import { editRegion } from "store/regions/actions";


function RegionEdit(props) {
  const { open, region = {}, onClose } = props;
  const dispatch = useDispatch();
  const regionsTitles = props.regions?.map((region) => (region.title?.toLowerCase()));
  const toggleEditModal = () => {
    onClose();

  };
  const handleEditRegion = (e, values) => {
    dispatch(editRegion({
      id: region?._id,
      values
    }));
    toggleEditModal();
  };
  const validateRegionTitle = (value, ctx, input, cb) => {
    if (regionsTitles?.includes(value?.toLowerCase()) || regionsTitles?.includes(value)){
      cb("This Region Already Exists");
    } else {
      cb(true);
    } 
  };


  return (
    <React.Fragment >
      {/* <Link to="#" className="btn btn-light" onClick={onClose}><i className="bx bx-plus me-1"></i> Add New</Link> */}
      <Modal isOpen={open} toggle={toggleEditModal} centered={true} size={"lg"}>
        <ModalHeader toggle={toggleEditModal} tag="h4">
          Edit Region
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleEditRegion(e, v);
            }}
          >
            <div className="mb-3">
              <AvField
                name="title"
                label="Region Title"
                placeholder="Enter Region Title"
                type="text"
                value={region.title}
                errorMessage="Enter Region Title"
                validate={
                  { 
                    required: { value: true },
                    customer: validateRegionTitle 
                  }
                }
              />
            </div>

            <div className='text-center pt-3 p-2'>
              <Button disabled={props.addLoading} type="submit" color="primary" className="">
                Update
              </Button>
            </div>
          </AvForm>
          {props.addError && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.addErrorDetails}
          </UncontrolledAlert>}
          {props.addSuccess && <UncontrolledAlert color="success">
            <i className="mdi mdi-check-all me-2"></i>
            Region Added successfully !!!
          </UncontrolledAlert>}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  addLoading: state.regionReducer.addLoading,
  editResult: state.regionReducer.editResult,
  editError: state.regionReducer.editError,
  editClearingCounter: state.regionReducer.editClearingCounter,
  rolesPermissions: state.Profile.rolesPermissions
});
export default connect(mapStateToProps, null)(RegionEdit);
