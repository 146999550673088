/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  useDispatch,
  connect,
  useSelector
} from "react-redux";
import moment from "moment";
// eslint-disable-next-line object-curly-newline
import { Button, Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { fetchReportStart } from "store/reports/actions";
import CustomPagination from "components/Common/CustomPagination";

import Loader from "components/Common/Loader";
import SendReportEmail from "./SendReportEmail";
import AvFieldSelect from "components/Common/AvFieldSelect";
import DownloadFileType from "./DownloadFileType";
import CountryDropDown from "components/Common/CountryDropDown";
import ClientDropDown from "components/Common/Filters/ClientDropDown";
import SearchableComponent from "pages/Transactions/Forex/internalTransfer/SearchableComponents";
import { getClients } from "apis/client";
import SearchableAllAgentsDropdown from "components/Common/SearchableAllAgentsDropdown";
import usePermissions from "routes/permissions";
import { fetchUsers } from "store/users/actions";
import { fetchTeams } from "store/teams/actions";
import { getServers } from "apis/mtServers";
import CryptoJS from "crypto-js";
import { encryptData } from "common/utils/encryptData";
import { fetchRegions } from "store/regions/actions";

// const secretKey = "+e-4E96VM?~|+)sR|`-J-8;0y9(Vf";
const secretKey = process.env.REACT_APP_ENCRYPT_DATA_SECRET_KEY;

// const encryptData = (data) => {
//   const cipherText = CryptoJS.AES.encrypt(data, secretKey).toString();
//   return cipherText;
// };

function UsersAddModal(props) {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.reportsReducer);
 
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedValues, setSelectedValues] = useState({});
  // const [mtServer, setMtServer] = useState("");
  const [reportType, setReportType] = useState("");
  const [comparisonOperator, setComparisonOperator] = useState("greater");
  const [selectedSearchCriteria, setSelectedSearchCriteria] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [showAgent, setShowAgent] = useState(true);
  const regions = useSelector((state) => state.regionReducer.docs);
  const handleShowStatus = (type) => {
    if (type === "deposit-status" || type === "deposit-status-fx" || type === "withdrawals-status-fx" || type === "withdrawals-status") {
      setShowStatus(true);
    } else {
      setShowStatus(false);
    }
    if (type === "sales-commision" || type === "sales-withdrawals" || type === "trading") {
      setShowAgent(false);
    } else {
      setShowAgent(true);
    }
  };

  const operatorOptions = [
    {
      value: "equal",
      label: "=" 
    },
    {
      value: "greater",
      label: ">" 
    },
    {
      value: "less",
      label: "<" 
    },
    {
      value: "greaterEqual",
      label: ">=" 
    },
    {
      value: "lessEqual",
      label: "<=" 
    },
  ];
  
  const { 
    reportsPermissions,
  } = usePermissions();


  const submitReportSearch = (e, values) => {
    props.setReportColumns(values.reportType);
    setSelectedValues(values);
    //setSelectedValues("Submit Values: ", values);
    // let mtserver = values?.mtserver;
    
    // mtserver = encryptData(values?.mtserver) || "";
    dispatch(fetchReportStart({
      ...values,
      agent: values.agent,
      country: values.country || "",
      customerId: values.customerId || "",
      parentId: values.parentId || "",
    }));
    props.setPayload({
      ...values,
      type: reportType,
    });
  };

  const paginationLoad = (page) => {
    // let mtserver = selectedValues?.mtserver;
    // mtserver = encryptData(selectedValues?.mtserver) || "";
    setSelectedValues({
      ...selectedValues,
    });
    console.log("selectedValues: ", selectedValues);
    dispatch(
      fetchReportStart({
        ...selectedValues,
        page,
        limit: sizePerPage,
      })
    );
  };

  useEffect(() => {
    console.log("selectedValues now: ", selectedValues);
    if (selectedValues && selectedValues.reportType) {
      setSelectedValues({
        ...selectedValues,
      });
      dispatch(
        fetchReportStart({
          ...selectedValues,
          page: 1,
          limit: sizePerPage,
        })
      );
    }
    dispatch(fetchUsers({
      page:1,
    }));
    dispatch(fetchTeams({
      page: 1,
    }));
  }, [sizePerPage]);
  const loadRegions = (page, limit) => {
    dispatch(fetchRegions({
      page,
      limit,
    }));
  };
  useEffect(()=>{  
    loadRegions(1, 2000);
  }, []);
  const defaultValues = {
    reportType: "deposits",
    dateFrom: moment().subtract(1, "months").format("YYYY-MM-DD"),
    dateTo: moment().add(1, "day").format("YYYY-MM-DD"),
  };

  return (
    <React.Fragment>
      <div className=" me-2 mb-2">
        <div className="position-relative">
          <AvForm
            className="p-1"
            onValidSubmit={(e, v) => {
              v.comparisonOperator = comparisonOperator;
              console.log("v: ", v);
              submitReportSearch(e, v);
            }}
            model={defaultValues}
          >
            <Row>
              <Col xs={12} md={4} lg={2}>
                <label>Report Type</label>
                <AvFieldSelect
                  type="select"
                  name="reportType"
                  required
                  onChange={(e) => {
                    setReportType(e);
                    handleShowStatus(e);
                  }}
                  options={[
                    {
                      value: "deposits",
                      label: props.t("Deposits By Login"),
                    },

                    {
                      value: "deposit-status-fx",
                      label: props.t("Forex Deposits Status"),
                    },
                    {
                      value: "deposit-status",
                      label: props.t("Wallet Deposits Status"),
                    },

                    {
                      value: "client-deposits",
                      label: props.t("Deposits By Client"),
                    },
                    {
                      value: "withdrawals",
                      label: props.t("Withdrawals By Login"),
                    },
                    {
                      value: "withdrawals-status",
                      label: props.t("Wallet Withdrawals Status"),
                    },
                    {
                      value: "withdrawals-status-fx",
                      label: props.t("Forex Withdrawals Status"),
                    },

                    {
                      value: "client-withdrawals",
                      label: props.t("Withdrawals By Client"),
                    },
                    {
                      value: "daily-net-deposit",
                      label: props.t("Daily Net Deposit"),
                    },
                    {
                      value: "new-client-deposits",
                      label: props.t("New Client Deposits"),
                    },
                    {
                      value: "commission",
                      label: props.t("Commission"),
                    },
                    {
                      value: "credit-in",
                      label: props.t("Credit In"),
                    },
                    {
                      value: "credit-out",
                      label: props.t("Credit Out"),
                    },
                    {
                      value: "unfunded-accounts",
                      label: props.t("Unfunded Accounts"),
                    },
                    {
                      value: "summary",
                      label: props.t("Deals Summary"),
                    },
                    {
                      value: "trading",
                      label: props.t("Trading Details"),
                    },
                    {
                      value: "lead-converted",
                      label: props.t("Lead Converted"),
                    },
                    {
                      value: "lead-call-status",
                      label: props.t("Lead Call Status"),
                    },
                    {
                      value: "last-login",
                      label: props.t("Last Login"),
                    },
                    // {
                    //   value: "ib-summary",
                    //   label: props.t("IB wise Summary"),
                    // },
                    {
                      value: "blocked-customers",
                      label: props.t("Blocked Customers"),
                    },
                    {
                      value: "entity-type-vu",
                      label: props.t("VU Customers"),
                    },
                    {
                      value: "entity-type-mu",
                      label: props.t("MU Customers"),
                    },
                    {
                      value: "swap-customers",
                      label: props.t("Swap Customers"),
                    },
                    {
                      value: "swap-free-customers",
                      label: props.t("Swap Free Customers"),
                    },
                    {
                      value: "sales-commision",
                      label: props.t("Sales Commision"),
                    },
                    {
                      value: "revoked-commissions",
                      label: props.t("Revoked Commissions"),
                    },
                    
                  ]}
                  errorMessage={props.t("please select report Type")}
                  validate={{ required: { value: true } }}
                >
                  {/* <option value="">{props.t("Select Report")}</option>
                  <option value="deposits">{props.t("Deposits By Login")}</option>
                  <option value="client-deposits">{props.t("Deposits By Client")}</option>
                  <option value="withdrawals">{props.t("Withdrawals By Login")}</option>
                  <option value="client-withdrawals">{props.t("Withdrawals By Client")}</option>
                  <option value="commission">{props.t("Commission")}</option>
                  <option value="credit-in">{props.t("Credit In")}</option>
                  <option value="credit-out">{props.t("Credit Out")}</option>
                  <option value="unfunded-accounts">{props.t("Unfunded Accounts")}</option>
                  <option value="summary">{props.t("Summary")}</option>
                  <option value="lead-converted">{props.t("Lead Converted")}</option>
                  <option value="lead-call-status">{props.t("Lead Call Status")}</option>
                  <option value="last-login">{props.t("Last Login")}</option> */}
                </AvFieldSelect>
              </Col>
              <Col xs={12} md={4} lg={2}>
                <AvField
                  name="dateFrom"
                  label={props.t("Date From")}
                  type="date"
                  errorMessage={props.t("Select date From")}
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col xs={12} md={4} lg={2}>
                <AvField
                  name="dateTo"
                  label={props.t("Date To")}
                  type="date"
                  errorMessage={props.t("Select date To")}
                  validate={{ required: { value: true } }}
                />
              </Col>
              { reportType !== "trading" && <Col xs={12} md={4} lg={2}>
                <AvFieldSelect
                  type="select"
                  name="region"
                  label={props.t("Region")}
                  options={regions?.map((region) => ({
                    label: region?.title,
                    value: region?._id,
                  }))}    
                  errorMessage={props.t("Select Region")}
                ></AvFieldSelect>
              </Col>}
              {reportType === "trading" && (
                <Col xs={12} md={6} lg={6}>
                  <div className="mb-3">
                    <SearchableComponent
                      isRequired={true}
                      name="mtserver"
                      label={props.t("MT Server")}
                      onChange={(e) => {
                        
                        setSelectedValues({
                          ...selectedValues,
                          mtserver: e?.value,
                        });
                      }}
                      getData={async (payload = {}) => {
                        const response = await getServers({
                          payload: {
                            ...payload
                          }
                        });
                        return Array.isArray(response?.docs) ? response.docs : [];
                      }}
                      mapper={(doc) => (
                        {
                          label: `${doc?.name}`,
                          value: doc?._id
                        }
                      )}
                    />
                  </div>
                </Col>
              )}
              {reportType === "trading" && (
                <Col xs={12} md={6} lg={6}>
                  <div className="mb-3">
                    <SearchableComponent
                      isRequired
                      name="customerId"
                      label={props.t("Client")}
                      value={{
                        label: "All Clients",
                        value: "" 
                      }}
                      onChange={(e) => {
                        setSelectedValues({
                          ...selectedValues,
                          parentId: e?.value ?? "",
                        });
                      }}
                      getData={async (payload = {}) => getClients({
                        payload: {
                          ...payload
                        }
                      })?.then((res) => ([{
                        firstName: "All",
                        lastName: "Clients",
                        _id: "" 
                      }, ...res?.result?.docs] || []))}
                      mapper={(doc) => (
                        {
                          label: `${doc.firstName} ${doc.lastName}`,
                          value: `${doc._id}`
                        }
                      ) || []}
                    />
                  </div>
                </Col>
              )}
              {reportType === "trading" && (
                <>
                  <Col xs={12} md={4} lg={2}>
                    <AvFieldSelect
                      type="select"
                      name="tradingType"
                      label={props.t("Trading Type")}
                      defaultValue="Position" // Ensure this matches one of the values in the options array
                      value="Position" // Set the value prop to "Position" to ensure it is selected by default
                      options={[
                        {
                          value: "Position",
                          label: props.t("Position"),
                        },
                        {
                          value: "PendingOrder",
                          label: props.t("Pending Order"),
                        },
                        {
                          value: "TradingHistory",
                          label: props.t("Trading History"),
                        },
                      ]}
                      errorMessage={props.t("Select Trading Type")}
                    />
                  </Col>

                  <Col xs={12} md={4} lg={2}>
                    <AvFieldSelect
                      type="select"
                      name="searchCriteria"
                      label={props.t("Search By")}
                      options={[
                        {
                          value: "orderNumber",
                          label: props.t("Order Number")
                        },
                        {
                          value: "accountLogin",
                          label: props.t("Account Number") 
                        },
                        {
                          value: "symbol",
                          label: props.t("Symbol") 
                        }
                      ]}
                      onChange={(e) => {
                        if (e === "") {
                          setSelectedSearchCriteria(null);
                          setSelectedValues({
                            ...selectedValues,
                            searchCriteria: null
                          });
                        }
                        setSelectedSearchCriteria(e);
                        setSelectedValues({
                          ...selectedValues,
                          searchCriteria: e
                        });
                      }}
                      errorMessage={props.t("Select Search Criteria")}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={3}>
                    <AvField
                      name="searchValue"
                      label={props.t("Search Value")}
                      type="text"
                      placeholder={props.t("Enter search value")}
                      disabled={!selectedSearchCriteria}
                    />
                  </Col>
                  {/* <Col xs={12} md={4} lg={3}>
                    <div className="d-flex align-items-center gap-2">
                      <AvFieldSelect
                        type="select"
                        name="tradingComparison"
                        label={props.t("Trading Value")}
                        options={[
                          {
                            value: "greater",
                            label: ">" 
                          },
                          {
                            value: "less",
                            label: "<" 
                          }
                        ]}
                      />
                      <AvField
                        name="tradingValue"
                        label="&nbsp;"
                        type="number"
                        placeholder={props.t("Enter trading value")}
                      />
                    </div>
                  </Col> */}
                  {/* <Col xs={12} md={4} lg={3}>
                    <div className="position-relative">
                      <label>{props.t("Enter trading")}</label>
                      <div className="d-flex align-items-center trading-value-container" style={{ position: "relative" }}>
                        <div 
                          className="comparison-operator" 
                          style={{ 
                            position: "absolute",
                            left: "8px",
                            top: "18px",
                            transform: "translateY(-50%)",
                            zIndex: 2,
                            cursor: "pointer",
                            backgroundColor: "#fff",
                            padding: "0 2px",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            height: "100%"
                          }}
                          onClick={() => setComparisonOperator(comparisonOperator === "greater" ? "less" : "greater")}
                        >
                          {comparisonOperator === "greater" ? ">" : "<"}
                        </div>
                        <AvField
                          name="tradingValue"
                          type="number"
                          style={{ paddingLeft: "25px" }}
                          className="form-control"
                          placeholder={props.t("Enter trading value")}
                        />
                      </div>
                    </div>
                  </Col> */}
                  <Col xs={12} md={4} lg={3}>
                    <div style={{
                      display: "flex",
                      flexDirection: "column" 
                    }}>
                      <label style={{ marginBottom: "0.5rem" }}>{props.t("Enter trading")}</label>
                      <div style={{
                        display: "flex",
                        gap:"10px"
                      }}>
                        <div style={{ width:"80px" }}>
                          <AvFieldSelect
                            type="select"
                            name="comparisonOperator"
                            value={comparisonOperator}
                            options={operatorOptions}
                            onChange={(e) => setComparisonOperator(e)}
                            style={{
                              padding: "0.375rem 0.75rem",
                              border: "1px solid #ced4da",
                              borderRadius: "0.25rem",
                              height: "calc(1.5em + 0.75rem + 2px)",
                              boxSizing: "border-box",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                        <AvField
                          name="tradingValue"
                          type="number"
                          validate={{
                            min: {
                              value: 0, 
                              errorMessage: "Value must be at least 0" 
                            },
                          }}
                        
                          style={{
                            padding: "0.48rem 0.75rem",
                            border: "1px solid #ced4da",
                            borderRadius: "0.25rem",
                            boxSizing: "border-box",
                          }}
                          placeholder={props.t("Enter trading value")}
                        />
                      </div>
                    </div>
                  </Col>
                </>
              )}
              {reportType === "sales-commision" && (
                <>
                  <Col xs={12} md={4} lg={2}>
                    <AvFieldSelect
                      type="select"
                      name="user"
                      label={props.t("User")}
                      options={props?.users?.map((obj) => ({
                        label: `${obj?.firstName} ${obj?.lastName}`,
                        value: obj?._id,
                      }))}    
                      errorMessage={props.t("Select User")}
                    ></AvFieldSelect>
                  </Col>
                  <Col xs={12} md={4} lg={2}>
                    <AvFieldSelect
                      type="select"
                      name="team"
                      label={props.t("Manager")}
                      options={props?.teams?.map((obj) => ({
                        label: `${obj?.title} - ( ${obj?.managerId?.firstName} ${obj?.managerId?.lastName} )`,
                        value: obj?._id,
                      }))}    
                      errorMessage={props.t("Select Manager")}
                    ></AvFieldSelect>
                  </Col>
                </>
              )}

              {showAgent && (
                <Col xs={12} md={4} lg={2}>
                  <div className="mb-3">
                    <SearchableAllAgentsDropdown
                      isRequired={false}
                      clientData={props?.salesAgent || null}
                      isMulti={true}
                      isDisabled={reportsPermissions.getAssigned && !reportsPermissions.get}
                      value={reportsPermissions.getAssigned && !reportsPermissions.get ? [
                        { 
                          value: props?.userData?._id, 
                          label: `${props?.userData?.firstName} ${props?.userData?.lastName}` 
                        }
                      ] : []}
                      onChange={(e) => {
                        setSelectedValues({
                          ...selectedValues,
                          agent: e.map((item) => item?.value),
                        });
                      }}
                    />
                  </div>
                </Col>
              )}
              {showStatus ? <Col xs={12} md={4} lg={2}>
                <AvFieldSelect
                  name="status"
                  label={props.t("Status")}
                  type="select"
                  errorMessage={props.t("Select Status")}
                  // validate={{ required: { value: true } }}
                  options={[
                    {
                      label: props.t("All"),
                      value: "",
                    },
                    {
                      label: props.t("Approved"),
                      value: "APPROVED",
                    },
                    {
                      label: props.t("Rejected"),
                      value: "REJECTED",
                    },
                    {
                      label: props.t("Pending"),
                      value: "PENDING",
                    },
                  ]}
                ></AvFieldSelect>
              </Col> : null}
              {reportType === "commission" &&
                  <Col xs={12} md={4} lg={2}>
                    <AvFieldSelect
                      type="select"
                      name="platform"
                      label={props.t("Platform")}
                      options={[{
                        label: "MT5", 
                        value: "MT5"
                      },
                      {
                        label: "MT4", 
                        value: "MT4"
                      }]}
                      value={"MT5"}
                      onChange={(e) => {
                        setSelectedValues({
                          ...selectedValues,
                          platform: e
                        });
                      }}
                      errorMessage={props.t("Select Type")}
                    ></AvFieldSelect>
                  </Col>
              }
              {/* {reportType === "trading" && (
                <Col xs={12} md={4} lg={2}>
                  <AvFieldSelect
                    type="select"
                    name="type"
                    label={props.t("Platform")}
                    options={[{
                      label: "MT5", 
                      value: "MT5"
                    },
                    {
                      label: "MT4", 
                      value: "MT4"
                    }]}
                    value={"MT5"}
                    onChange={(e) => {
                      setSelectedValues({
                        ...selectedValues,
                        type: e
                      });
                    }}
                    errorMessage={props.t("Select Type")}
                  ></AvFieldSelect>
                </Col>
              )} */}
              {
                ["daily-net-deposit", "new-client-deposits", "summary"].includes(reportType) && <Col xs={12} md={6} lg={4}>
                  <CountryDropDown
                    defaultValue={selectedValues.country}
                    countryChangeHandler={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        country: value.value,
                      });
                    }} />
                </Col>
              }
            </Row>
            {["summary"].includes(reportType) && <Row>
              <Col xs={12} md={6} lg={6}>
                <ClientDropDown
                  defaultValue={selectedValues.customerId}
                  clientChangeHandler={(value) => {
                    setSelectedValues({
                      ...selectedValues,
                      customerId: value?.value,
                    });
                  }}
                />
              </Col>
              <Col xs={12} md={4} lg={2}>
                <AvFieldSelect
                  name="platform"
                  label={props.t("Platform")}
                  type="select"
                  errorMessage={props.t("Select Platform")}
                  validate={{ required: { value: true } }}
                  options={[
                    {
                      label: props.t("MT5"),
                      value: "MT5",
                    },
                    {
                      label: props.t("MT4"),
                      value: "MT4",
                    },
                  ]}
                ></AvFieldSelect>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <div className="mb-3">
                  <SearchableComponent
                    isRequired
                    name="parentId"
                    label={props.t("IB")}
                    onChange={(e) => {
                      setSelectedValues({
                        ...selectedValues,
                        parentId: e?.value ?? "",
                      });
                    }}
                    getData={async (payload) => getClients({
                      payload: {
                        fxType: "IB",
                        ...payload
                      }
                    })?.then((res) => (res?.result?.docs || []))}
                    mapper={(doc) => (
                      {
                        label: `${doc.firstName} ${doc.lastName}`,
                        value: `${doc._id}`
                      }
                    ) || []}
                  />
                </div>
              </Col>
            </Row>}
            <Row className="text-center d-flex justify-content-center align-items-center my-3" style={{
              maxWidth: "100%",
            }}>
              <div style={{
                maxWidth: 400,
              }}>
                {props.addLoading ? (
                  <Loader />
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    className="w-100 py-2"
                    disabled={props.loading || !reportType || (reportType === "trading" && !selectedValues.mtserver)}
                  >
                    {props.t("Search")}
                  </Button>
                )}
              </div>
            </Row>
            <div className="d-flex justify-content-end gap-2">
              <DownloadFileType selectedValues={selectedValues} />
              <SendReportEmail reportValues={selectedValues} />
            </div>
          </AvForm>
        </div>
      </div>
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          {props.children}
          <CustomPagination
            {...props.reportsReducer}
            setSizePerPage={setSizePerPage}
            sizePerPage={sizePerPage}
            onChange={paginationLoad}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.reportsReducer.loading || false,
  salesAgent: state.usersReducer.salesAgent || [],
  reportsReducer: state.reportsReducer || {},
  clientDetails: state.clientReducer.clientDetails,
  userData: state.Profile.userData || {},
  users:state.usersReducer.docs || [],
  teams: state.teamsReducer.docs || [],
  exchanges: state.dictionaryReducer.exchanges  || [],
  dictionary:state.dictionaryReducer.dictionary || [],
});
export default connect(mapStateToProps, null)(withTranslation()(UsersAddModal));
