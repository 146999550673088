import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Card, CardBody, CardHeader, CardTitle,
} from "reactstrap";
import Accordion from "react-bootstrap/Accordion";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import { useSelector } from "react-redux";

const getAccountTypeName = (accountTypeId, accountTypes) => {
  return accountTypes?.find((type) => type._id === accountTypeId)?.title;
};

const getAccountType = (accountTypeId, accountTypes) => {
  return accountTypes?.find((type) => type._id === accountTypeId);
};

const AgreementDetails = ({ agreement, accountTypes, t }) => {
  const { _id, recordId, stages } = useSelector((state) => state.clientReducer?.clientDetails);
  const [currentLevel, setCurrentLevel] = useState("");
  useEffect(() => {
    console.log("agreement", agreement);
    agreement?.members?.forEach((member) => {
      if (String(member.customerId?._id) === String(_id)) {
        console.log("member.level", member.level);
        setCurrentLevel(member.level);
      }
    });
  }, [_id, agreement]);
  return (
    <Card>
      <CardHeader className="py-3">
        <CardTitle className="color-primary">{t("Agreement Details")}</CardTitle>
      </CardHeader>
      <CardBody>
        {agreement ?
          !agreement?.isMaster &&
          <Table className="table table-hover table-borderless">
            <Thead>
              <Tr>
                <Th className="py-1">{t("Account Type")}</Th>
                <Th className="py-1">{t("Total Rebate")}</Th>
                <Th className="py-1">{t("Total Commission")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {agreement?.totals?.map((total, idx) =>
                <Tr key={idx}>
                  <Td className="py-1">{getAccountTypeName(total.accountTypeId, accountTypes)}</Td>
                  <Td className="py-1">{total.rebate || 0}</Td>
                  <Td className="py-1">{total.commission || 0}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          : <p>No Agreements for this account.</p>}
        <Accordion>
          {agreement?.members?.map((member, index) =>
            member?.level <= currentLevel &&
            <Accordion.Item eventKey={index} key={member._id}>
              <Accordion.Header>{member?.customerId?.firstName} {member?.customerId?.lastName} </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  {member?.values.map((account, index) =>
                    getAccountType(account?.accountTypeId, accountTypes)?.type === "INVESTOR" ? <>
                      <Accordion.Item eventKey={index} key={account._id}>
                        <Accordion.Header>{getAccountTypeName(account.accountTypeId, accountTypes)}</Accordion.Header>
                        <Accordion.Body>
                          <Table className="table table-hover table-borderless text-center text-capitalize">
                            <Thead>
                              <Tr>
                                <Th className="py-1">{t("PAMM Profit Share")}</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr >
                                <Td className="py-1">
                                  {account?.pamProfitShare ?? 0} %
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </> : <Accordion.Item eventKey={index} key={account._id}>
                      <Accordion.Header>{getAccountTypeName(account.accountTypeId, accountTypes)}</Accordion.Header>
                      <Accordion.Body>
                        <Table className="table table-hover table-borderless text-center text-capitalize">
                          <Thead>
                            <Tr>
                              <Th className="py-1">{t("Product")}</Th>
                              <Th className="py-1">{t("Rebate")}</Th>
                              {/* {
                                member?.level < currentLevel &&
                                  <Th className="py-1">{t("Structre Rebate")}</Th>
                              } */}
                              <Th className="py-1">{t("Commission")}</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {/* {account.products && Object.entries(account.products).map(([key, value]) =>
                              <Tr key={key}>
                                <Td className="py-1">{key}</Td>
                                <Td className="py-1">{value?.rebate || 0}</Td>
                                <Td className="py-1">{value?.commission || 0}</Td>
                              </Tr>
                            )} */}
                            {account?.rebates && Object.entries(account?.rebates).map(([key, value]) =>
                              <Tr key={key}>
                                <Td className="py-1">{value?.rebateId?.name}</Td>
                                {/* {
                                  member?.level < currentLevel && 
                                    <Td className="py-1">{((value?.value || 0) - (value?.childRebate || 0) ).toFixed(2)}</Td>
                                } */}
                                <Td className="py-1">
                                  {
                                    member?.level == currentLevel ?
                                      value?.value || 0
                                      :
                                      ((value?.value || 0) - (value?.childRebate || 0) ).toFixed(2)
                                  }

                                </Td>
                                <Td className="py-1">{value?.commission || 0}</Td>
                              </Tr>
                            )}
                          </Tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          )}
        </Accordion>
      </CardBody>
    </Card>
  );
};

export default withTranslation()(AgreementDetails);
