import {
  FETCH_IB_REQUESTS_START,
  FETCH_IB_REQUESTS_SUCCESS,
  FETCH_IB_REQUESTS_ERROR,
  IB_REQUEST_APPROVE_START,
  IB_REQUEST_REJECT_START,
  IB_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
  IB_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
  FETCH_LEVERAGE_REQUESTS_SUCCESS,
  FETCH_LEVERAGE_REQUESTS_ERROR,
  LEVERAGE_REQUEST_APPROVE_START,
  LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
  LEVERAGE_REQUEST_REJECT_START,
  LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
  FETCH_LEVERAGE_REQUESTS_START,
  FETCH_ACCOUNT_REQUESTS_START,
  FETCH_ACCOUNT_REQUESTS_SUCCESS,
  FETCH_ACCOUNT_REQUESTS_ERROR,
  CLEAN_UP_REQUESTS,
  ACCOUNT_REQUEST_APPROVE_START,
  ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
  ACCOUNT_REQUEST_REJECT_START,
  ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
  IB_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
  IB_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
  LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
  LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
  ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
  ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
  FETCH_INVESTOR_ACCOUNT_REQUESTS_START,
  FETCH_INVESTOR_ACCOUNT_REQUESTS_SUCCESS,
  FETCH_INVESTOR_ACCOUNT_REQUESTS_ERROR,
  INVESTOR_ACCOUNT_REQUEST_APPROVE_START,
  INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
  INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
  INVESTOR_ACCOUNT_REQUEST_REJECT_START,
  INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
  INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
  FETCH_MASTER_ACCOUNT_REQUESTS_START,
  FETCH_MASTER_ACCOUNT_REQUESTS_SUCCESS,
  FETCH_MASTER_ACCOUNT_REQUESTS_ERROR,
  MASTER_ACCOUNT_REQUEST_APPROVE_START,
  MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
  MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
  MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
  MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
  MASTER_ACCOUNT_REQUEST_REJECT_START,
  FETCH_SWAP_REQUESTS_START,
  FETCH_SWAP_REQUESTS_SUCCESS,
  FETCH_SWAP_REQUESTS_ERROR,
  SWAP_REQUEST_REJECT_START,
  SWAP_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
  SWAP_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
  SWAP_REQUEST_APPROVE_START,
  SWAP_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
  SWAP_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
  ADD_OPEN_PROMOTION_LINK_START,
  ADD_OPEN_PROMOTION_LINK_SUCCESS,
  ADD_OPEN_PROMOTION_LINK_ERROR,
  FETCH_OPEN_LINK_REQUESTS_START,
  FETCH_OPEN_LINK_REQUESTS_SUCCESS,
  FETCH_OPEN_LINK_REQUESTS_ERROR,
} from "./actionTypes";

export const fetchIbs = (params = {}) => {
  return {
    type: FETCH_IB_REQUESTS_START,
    payload: params,
  };
};

export const fetchIbStartsSuccess = (data) => {
  return {
    type: FETCH_IB_REQUESTS_SUCCESS,
    payload: data,
  };
};

export const fetchIbStartsError = (error) => {
  return {
    type: FETCH_IB_REQUESTS_ERROR,
    payload: { error },
  };
};

export const fetchOpenLinks = (params = {}) => {
  return {
    type: FETCH_OPEN_LINK_REQUESTS_START,
    payload: params,
  };
};

export const fetchOpenLinksStartsSuccess = (data) => {
  return {
    type: FETCH_OPEN_LINK_REQUESTS_SUCCESS,
    payload: data,
  };
};

export const fetchOpenLinksStartsError = (error) => {
  return {
    type: FETCH_OPEN_LINK_REQUESTS_ERROR,
    payload: { error },
  };
};
export const fetchSwaps = (params = {}) => {
  return {
    type: FETCH_SWAP_REQUESTS_START,
    payload: params,
  };
};

export const fetchSwapsStartsSuccess = (data) => {
  return {
    type: FETCH_SWAP_REQUESTS_SUCCESS,
    payload: data,
  };
};

export const fetchSwapsStartsError = (error) => {
  return {
    type: FETCH_SWAP_REQUESTS_ERROR,
    payload: { error },
  };
};

export const addOpenPromotionLinkStart = (data) => {
  return {
    type: ADD_OPEN_PROMOTION_LINK_START,
    payload: data,
  };
};

export const addOpenPromotionLinkSuccess = (data) => {
  return {
    type: ADD_OPEN_PROMOTION_LINK_SUCCESS,
    payload: data,
  };
};

export const addOpenPromotionLinkError = (error) => {
  return {
    type: ADD_OPEN_PROMOTION_LINK_ERROR,
    payload: error,
  };
};

export const ibRequestApprove = (data) => {
  return {
    type: IB_REQUEST_APPROVE_START,
    payload: data,
  };
};

export const ibRequestToApproveStateChange = (data) => {
  return {
    type: IB_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
    payload: data,
  };
};

export const ibRequestApproveError = (error) => {
  return {
    type: IB_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
    payload: error,
  };
};

export const ibRequestReject = (data) => {
  return {
    type: IB_REQUEST_REJECT_START,
    payload: data,
  };
};

export const ibRequestToRejectStateChange = (data) => {
  return {
    type: IB_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
    payload: data,
  };
};

export const ibRequestRejectError = (error) => {
  return {
    type: IB_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
    payload: error,
  };
};

export const swapRequestApprove = (data) => {
  return {
    type: SWAP_REQUEST_APPROVE_START,
    payload: data,
  };
};

export const swapRequestToApproveStateChange = (data) => {
  return {
    type: SWAP_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
    payload: data,
  };
};

export const swapRequestApproveError = (error) => {
  return {
    type: SWAP_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
    payload: error,
  };
};

export const swapRequestReject = (data) => {
  return {
    type: SWAP_REQUEST_REJECT_START,
    payload: data,
  };
};


export const swapRequestToRejectStateChange = (data) => {
  return {
    type: SWAP_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
    payload: data,
  };
};

export const swapRequestRejectError = (error) => {
  return {
    type: SWAP_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
    payload: error,
  };
};
// leverage

export const fetchLeverages = (params = {}) => {
  return {
    type: FETCH_LEVERAGE_REQUESTS_START,
    payload: params,
  };
};

export const fetchLeverageStartsSuccess = (data) => {
  return {
    type: FETCH_LEVERAGE_REQUESTS_SUCCESS,
    payload: data,
  };
};

export const fetchLeverageStartsError = (error) => {
  return {
    type: FETCH_LEVERAGE_REQUESTS_ERROR,
    payload: { error },
  };
};

export const leverageRequestApprove = (data) => {
  return {
    type: LEVERAGE_REQUEST_APPROVE_START,
    payload: data,
  };
};

export const leverageRequestToApproveStateChange = (data) => {
  return {
    type: LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
    payload: data,
  };
};

export const leverageRequestApproveError = (error) => {
  return {
    type: LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
    payload: error,
  };
};

export const leverageRequestReject = (data) => {
  return {
    type: LEVERAGE_REQUEST_REJECT_START,
    payload: data,
  };
};

export const leverageRequestToRejectStateChange = (data) => {
  return {
    type: LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
    payload: data,
  };
};

export const leverageRequestRejectError = (error) => {
  return {
    type: LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
    payload: { error },
  };
};

// Account Requests
export const fetchAccountRequests = (params = {}) => {
  return {
    type: FETCH_ACCOUNT_REQUESTS_START,
    payload: params,
  };
};

export const fetchAccountRequestsSuccess = (data) => {
  return {
    type: FETCH_ACCOUNT_REQUESTS_SUCCESS,
    payload: data,
  };
};

export const fetchAccountRequestsFail = (error) => {
  return {
    type: FETCH_ACCOUNT_REQUESTS_ERROR,
    payload: { error },
  };
};

export const cleanUp = () => {
  return {
    type: CLEAN_UP_REQUESTS,
  };
};

export const accountRequestApprove = (data) => {
  return {
    type: ACCOUNT_REQUEST_APPROVE_START,
    payload: data,
  };
};

export const accountRequestToApproveStateChange = (data) => {
  return {
    type: ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
    payload: data,
  };
};

export const accountRequestApproveError = (error) => {
  return {
    type: ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
    payload: { error },
  };
};

export const accountRequestReject = (data) => {
  return {
    type: ACCOUNT_REQUEST_REJECT_START,
    payload: data,
  };
};

export const accountRequestToRejectStateChange = (data) => {
  return {
    type: ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
    payload: data,
  };
};

export const accountRequestRejectError = (error) => {
  return {
    type: ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
    payload: { error },
  };
};

// Master Account Requests
export const fetchMasterAccountRequests = (params = {}) => {
  return {
    type: FETCH_MASTER_ACCOUNT_REQUESTS_START,
    payload: params,
  };
};

export const fetchMasterAccountRequestsSuccess = (data) => {
  return {
    type: FETCH_MASTER_ACCOUNT_REQUESTS_SUCCESS,
    payload: data,
  };
};

export const fetchMasterAccountRequestsFail = (error) => {
  return {
    type: FETCH_MASTER_ACCOUNT_REQUESTS_ERROR,
    payload: { error },
  };
};

export const masterAccountRequestApprove = (data) => {
  return {
    type: MASTER_ACCOUNT_REQUEST_APPROVE_START,
    payload: data,
  };
};

export const masterAccountRequestToApproveStateChange = (data) => {
  return {
    type: MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
    payload: data,
  };
};

export const masterAccountRequestApproveError = (error) => {
  return {
    type: MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
    payload: { error },
  };
};

export const masterAccountRequestReject = (data) => {
  return {
    type: MASTER_ACCOUNT_REQUEST_REJECT_START,
    payload: data,
  };
};

export const masterAccountRequestToRejectStateChange = (data) => {
  return {
    type: MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
    payload: data,
  };
};

export const masterAccountRequestRejectError = (error) => {
  return {
    type: MASTER_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
    payload: { error },
  };
};

// Investor Account Requests
export const fetchInvestorAccountRequests = (params = {}) => {
  return {
    type: FETCH_INVESTOR_ACCOUNT_REQUESTS_START,
    payload: params,
  };
};

export const fetchInvestorAccountRequestsSuccess = (data) => {
  return {
    type: FETCH_INVESTOR_ACCOUNT_REQUESTS_SUCCESS,
    payload: data,
  };
};

export const fetchInvestorAccountRequestsFail = (error) => {
  return {
    type: FETCH_INVESTOR_ACCOUNT_REQUESTS_ERROR,
    payload: { error },
  };
};

export const investorAccountRequestApprove = (data) => {
  return {
    type: INVESTOR_ACCOUNT_REQUEST_APPROVE_START,
    payload: data,
  };
};

export const investorAccountRequestToApproveStateChange = (data) => {
  return {
    type: INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS,
    payload: data,
  };
};

export const investorAccountRequestApproveError = (error) => {
  return {
    type: INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR,
    payload: { error },
  };
};

export const investorAccountRequestReject = (data) => {
  return {
    type: INVESTOR_ACCOUNT_REQUEST_REJECT_START,
    payload: data,
  };
};

export const investorAccountRequestToRejectStateChange = (data) => {
  return {
    type: INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS,
    payload: data,
  };
};

export const investorAccountRequestRejectError = (error) => {
  return {
    type: INVESTOR_ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR,
    payload: { error },
  };
};
