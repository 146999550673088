
export const FETCH_REGIONS_START = "FETCH_REGIONS_START";
export const FETCH_REGIONS_SUCCESS = "FETCH_REGIONS_SUCCESS";
export const FETCH_REGIONS_ERROR = "FETCH_REGIONS_ERROR";

export const ADD_REGIONS_START = "ADD_REGIONS_START";
export const ADD_REGIONS_SUCCESS = "ADD_REGIONS_SUCCESS";
export const ADD_REGIONS_ERROR = "ADD_REGIONS_ERROR";
export const ADD_REGION_CLEAR = "ADD_REGION_CLEAR";


export const EDIT_REGIONS_START = "EDIT_REGIONS_START";
export const EDIT_REGIONS_DONE = "EDIT_REGIONS_DONE";
export const EDIT_REGION_CLEAR = "EDIT_REGION_CLEAR";

export const DELETE_REGIONS_START = "DELETE_REGIONS_START";
export const DELETE_REGIONS_DONE =  "DELETE_REGIONS_DONE";

export const CHANGE_STATUS_REGIONS_START = "CHANGE_STATUS_REGIONS_START";
export const CHANGE_STATUS_REGIONS_END = "CHANGE_STATUS_REGIONS_END";
